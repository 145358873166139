<template>
  <spar-checkout-steps :active-steps="['0', '1', '2']" />
  <section class="checkout-content">
    <div class="checkout-payment__address-block">
      <!-- TODO should also be editable as in step 1 - Info text also not correct -->
      <spar-address
        v-if="cartContext?.cart?.paymentAddress"
        :address="cartContext?.cart.paymentAddress"
        :heading="$t('checkout.step.payment.address.label')"
        :info-text="isDeliveryAddress ? $t('checkout.step.payment.address.is_delivery') : ''"
        tosca-prefix="checkout-payment"
      ></spar-address>
    </div>

    <spar-heading
      class="heading--left"
      level="2"
      headline-style="2"
      :title="$t('checkout.step.payment.mode.label')"
    />

    <div
      v-if="paymentModes"
      class="checkout-payment__payment-modes"
      data-tosca="checkout-payment-methods"
    >
      <div
        v-for="paymentMode in paymentModes.paymentModes"
        :key="paymentMode.code"
        class="checkout-payment__payment-mode-wrapper"
        :class="{
          'checkout-payment__payment-mode-wrapper--selected':
            selectedRadioButton === paymentMode.code,
        }"
        :data-tosca="`checkout-payment-mode-wrapper-${paymentMode.code}`"
      >
        <label class="checkout-payment__payment-mode">
          <spar-radio
            v-model="selectedRadioButton"
            :val="paymentMode.code"
            :tosca-prefix="`checkout-payment-mode-${paymentMode.code}`"
            @click="handleSelectPaymentMode(paymentMode.code)"
          >
            <span class="checkout-payment__payment-mode-label"> {{ paymentMode.name }} </span>
            <img
              v-if="paymentMode.icon?.url"
              :src="paymentMode.icon.url"
              :alt="paymentMode.name"
              class="checkout-payment__payment-mode-img"
              aria-hidden="true"
            />
            <!-- TODO: Code only visible until only valid payment modes for country received from backend-->
            <p>{{ paymentMode.description }} ({{ paymentMode.code }})</p>

            <p
              v-if="
                selectedRadioButton === paymentMode.code &&
                paymentModeStatus === PaymentStatus.error
              "
              class="checkout-payment__payment-mode-error"
            >
              {{ $t("checkout.step.payment.error.unavailable") }}
            </p>

            <div
              v-else-if="
                selectedRadioButton === paymentMode.code &&
                paymentModeStatus !== PaymentStatus.error
              "
            >
              <spar-paypal
                v-if="
                  selectedPaymentProvider === PaymentProvider.payPalAt ||
                  selectedPaymentProvider === PaymentProvider.payPalHu ||
                  selectedPaymentProvider === PaymentProvider.payPalSi
                "
                :is-saved="isPayPalSave"
                :label="savedPayPalAccount?.accountHolderName"
                @toggle-saved="togglePayPalSaved"
              />
            </div>
          </spar-radio>
          <span
            v-if="confirmedPayment?.paymentMode === paymentMode.code"
            class="checkout-payment__payment-mode-info-selected"
            >{{ $t("checkout.step.payment.selected") }}</span
          >
        </label>

        <!-- Additional Content for Payment Mode -->
        <div
          v-if="
            paymentModeStatus !== PaymentStatus.error &&
            selectedRadioButton === paymentMode.code &&
            selectedPaymentProvider === PaymentProvider.payOne
          "
          class="checkout-payment__payment-mode-settings"
        >
          <spar-payone
            :payment-code="paymentMode.code"
            :saved-cards="payOneSavedCards"
            :added-cards="payOneAddedCards"
            :status="paymentModeStatus"
            :is-payment-mode-confirmed="isPaymentModeConfirmed"
            :is-error="isPayOneTokenizerError"
            :confirmed-payment-id="confirmedPayment?.id"
            @save-card="createPaymentCard"
            @select-card="selectPayment"
            @show-widget="showWidget"
          />
        </div>
      </div>
    </div>

    <div v-else>
      {{ $t("global.error.unknown") }}
      <!-- TODO: No Concept? if something could not be loaded, should redirect to step before -->
    </div>
    <div class="checkout-payment__coupon">
      <div class="checkout-payment__coupon-action">
        <div class="checkout-payment__coupon-loader">
          <spar-loader v-if="cartContext?.setCouponLoading"></spar-loader>
        </div>
        <form novalidate @submit.prevent="checkCouponCode">
          <spar-input
            v-model="coupon"
            :label="$t('checkout.step.payment.coupon.title')"
            :status-message="cartContext?.couponStatus"
            class="checkout-payment__coupon-input"
            tosca-prefix="checkout-payment-voucher"
          ></spar-input>
          <spar-button
            class="checkout-payment__coupon-save"
            tosca-prefix="checkout-payment-voucher-send"
            @click="cartContext?.setCouponCode(coupon.trim())"
            >{{ $t("global.send") }}</spar-button
          >
        </form>
      </div>
      <div v-if="cartContext?.cart?.appliedVouchers" class="checkout-payment__coupon-list">
        <div class="checkout-payment__coupons-title">{{ $t("checkout.step.payment.coupons") }}</div>
        <div
          v-for="(appliedCoupon, index) in cartContext?.cart?.appliedVouchers"
          :key="index"
          class="checkout-payment__coupon-title"
          data-tosca="checkout-payment-added-voucher"
        >
          - {{ appliedCoupon.voucherCode }}
        </div>
      </div>
    </div>
  </section>

  <spar-checkout-proceed>
    <div class="checkout-payment__proceed">
      <spar-checkout-overview
        :headline-title="$t('checkout.step.payment.overview.title')"
        :product-discounts="cartContext?.cart?.productDiscounts"
        :order-discounts="cartContext?.cart?.orderDiscounts"
        :applied-vouchers="cartContext?.cart?.appliedVouchers"
        :delivery-cost="cartContext?.cart?.deliveryCost"
        :total-price-with-tax="cartContext?.cart?.totalPriceWithTax"
      />
      <div class="checkout-proceed__payment-btn">
        <spar-button
          role="link"
          type="button"
          class="checkout-proceed__btn"
          data-tosca="checkout-payment-goahead"
          icon="arrow-right"
          full-width
          :disabled="!isValid"
          @click="checkPaymentStep"
        >
          {{ $t("checkout.step.payment.summary.btn") }}
        </spar-button>
      </div>
    </div>
  </spar-checkout-proceed>

  <div class="checkout-proceed__btn-mobile-wrapper">
    <spar-button
      role="link"
      type="button"
      class="checkout-proceed__btn"
      data-tosca="checkout-payment-goahead-mobile"
      icon="arrow-right"
      full-width
      :disabled="!isValid"
      @click="checkPaymentStep"
      >{{ $t("checkout.step.payment.summary.btn") }}</spar-button
    >
  </div>
</template>

<script lang="ts" setup>
import type { CartStepErrorsAndWarnings } from "~/components/feature/SparCart/SparCart.types";
import SparCheckoutOverview from "~/components/feature/SparCheckout/SparCheckoutOverview/SparCheckoutOverview.vue";
import SparCheckoutProceed from "~/components/feature/SparCheckout/SparCheckoutProceed/SparCheckoutProceed.vue";
import SparCheckoutSteps from "~/components/feature/SparCheckout/SparCheckoutSteps/SparCheckoutSteps.vue";
import SparAddress from "~/components/shared/SparAddress/SparAddress.vue";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import SparLoader from "~/components/shared/SparLoader/SparLoader.vue";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import SparRadio from "~/components/shared/SparRadio/SparRadio.vue";
import useI18n from "~/composables/i18n/useI18n";
import usePaymentModes from "~/composables/payment/usePaymentModes";
import { useCheckoutStore } from "~/stores/checkout.store";
import { CheckoutStep } from "~/types/checkout.types";
import type { Address } from "~/types/occ.types";
import { PaymentProvider, PaymentStatus } from "./SparCheckoutPayment.types";
import SparPaypal from "./SparPaymentProvider/SparPayPal.vue";
import SparPayone from "./SparPaymentProvider/SparPayone.vue";

const { cartContext } = storeToRefs(useCheckoutStore());
const { checkForNextStep } = useCheckoutStore();

const {
  createPaymentCard,
  getPaymentModes,
  isPaymentModeConfirmed,
  isPayOneTokenizerError,
  isPayPalSave,
  paymentModes,
  paymentModeStatus,
  payOneSavedCards,
  payOneAddedCards,
  savedPayPalAccount,
  selectedPaymentProvider,
  selectPayment,
  selectPaymentMode,
  showWidget,
  togglePayPalSaved,
} = usePaymentModes();
const { pushNotification } = useNotification();
const { $t } = useI18n();

const selectedRadioButton = ref("");
const coupon = ref("");

await getPaymentModes();

const isDeliveryAddress = computed(() => {
  // TODO check if delivery and payment addresses are equal - Only makes sense for logged in user
  return false;
});

async function setDummyAddress() {
  if (cartContext.value?.cart) {
    const billingAddress: Address = {
      title: "Herr",
      titleCode: "mr",
      firstName: "Gustl",
      lastName: "Grindstone",
      companyName: "no company",
      line1: "Address line 1",
      line2: "Line2",
      town: "Salzburg",
      district: "district",
      postalCode: "5020",
      phone: "0043123456789",
      email: "",
      country: {
        isocode: "AT",
        name: "Österreich",
      },
      shippingAddress: false,
      defaultAddress: false,
      visibleInAddressBook: true,
      billingAddress: true,
    };
    await cartContext.value?.setBillingAddress(billingAddress);
  }
}

const checkCouponCode = () => {
  cartContext.value?.setCouponCode(coupon.value.trim());
};

const checkPaymentStep = () => {
  if (!checkForNextStep("checkoutPayment")) {
    pushNotification(
      $t("checkout.step.payment.error.next_step"),
      NotificationType.Error,
      NotificationTimeout.Medium,
      "checkout-payment",
    );
    return;
  }

  if (cartContext.value) {
    navigateTo({ name: "checkoutSummary", params: { baseSite: cartContext.value.bs } });
  }
};

const cartStepErrorsAndWarnings = computed((): CartStepErrorsAndWarnings => {
  if (!cartContext.value) return { errors: {}, warnings: {} };
  return cartContext.value.getCartStepErrorsAndWarnings(CheckoutStep.Payment);
});

const isValid = computed(() => {
  return Object.keys(cartStepErrorsAndWarnings.value?.errors).length === 0;
});

const confirmedPayment = computed(() => cartContext.value?.cart?.paymentInfo);

const handleSelectPaymentMode = (paymentProvider: string) => {
  selectPaymentMode(paymentProvider);
};

onMounted(async () => {
  // Pre-Select payment mode if already linked to cart
  if (confirmedPayment.value?.paymentMode) {
    selectedRadioButton.value = confirmedPayment.value.paymentMode;
    selectPaymentMode(confirmedPayment.value.paymentMode);
  }

  if (cartContext.value?.cart && !cartContext.value.cart.paymentAddress) {
    await setDummyAddress();
  }
});

/**
 * TODO
 * If the user is signed in (no guest checkout)
 *
 * - Get default billing address from user
 * - User can change the billing address, the new address must be attached to the cart (TODO Should this also be added to the user?)
 */

/**
 * If the user is anonymous (guest checkout)
 *
 * - Take delivery as billing address by default
 * - Guest can change the billing address, the new address must be attached to the cart
 */
</script>

<style lang="scss">
@use "./SparCheckoutPayment.scss";
</style>
