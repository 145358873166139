<template>
  <div>
    <fieldset class="contact-form__fieldset">
      <legend v-if="section.headline" class="contact-form__fieldset-legend">
        {{ section.headline }}
      </legend>
      <spar-flexible-form-field
        v-for="(field, index) in section.fields"
        :key="index"
        :field="field"
        :tosca-prefix="toscaPrefix"
        @interface="handleChildInterface"
      />
    </fieldset>
  </div>
</template>

<script lang="ts" setup>
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import type { FieldCollection } from "../SparFlexibleForm.types";
import SparFlexibleFormField from "../SparFlexibleFormField/SparFlexibleFormField.vue";

defineProps({
  section: {
    type: Object as PropType<FieldCollection>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["interface"]);

const handleChildInterface = (childInterface: InputInterface) => {
  emit("interface", childInterface);
};
</script>
