<template>
  <spar-card
    :is-selectable="isSelectable"
    :is-selected="isSelected"
    :aria-label="
      paymentDetails.expired
        ? $t('my_account.personal_data.payment_details.expired')
        : $t('my_account.personal_data.payment_details.valid')
    "
    :class="paymentDetails.expired ? 'payment-details-card--expired' : ''"
    :status-text="showSelectedInfo ? $t('checkout.step.payment.selected') : ''"
  >
    <template #title>
      <div class="payment-details-card__title-wrapper">
        <img
          class="payment-details-card__logo"
          :src="paymentDetails.logoUrl"
          :alt="paymentDetails.label"
        />
        <div data-tosca="payment-details-cc-type">{{ paymentDetails.label }}</div>
      </div>
      <div class="payment-details-card__number" data-tosca="payment-details-cardnr">
        {{ paymentDetails.cardNumber }}
      </div>
    </template>
    <!-- TODO: Show Confirmation (Modal) -->
    <template #icon
      ><spar-button
        v-if="isDeletable"
        :variant="ButtonVariant.custom"
        icon="trash"
        icon-only
        :aria-label="$t('my_account.personal_data.payment_details.delete')"
        tosca-prefix="payment-details-delete"
        @click="handleDeletePaymentDetails"
    /></template>

    <template #content>
      <div
        class="payment-details-card__account-holder-name"
        data-tosca="payment-details-card-holder-name"
      >
        {{ paymentDetails.accountHolderName }}
      </div>
      <div data-tosca="payment-details-card-expire-date">
        <span class="payment-details-card__expiry-label">{{
          $t("my_account.personal_data.payment_details.expiry")
        }}</span>
        {{ expiryDate }}
      </div>
      <slot></slot>
    </template>
  </spar-card>
</template>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparCard from "~/components/shared/SparCard/SparCard.vue";
import useI18n from "~/composables/i18n/useI18n";
import usePaymentDetails from "~/composables/payment/usePaymentDetails";
import type { PaymentDetails, SparPaymentDetailsCardProps } from "./SparPaymentDetailsCard.types";

const props: SparPaymentDetailsCardProps = defineProps({
  paymentDetails: {
    type: Object as PropType<PaymentDetails>,
    required: true,
  },
  isDeletable: {
    type: Boolean,
    default: true,
  },
  isSelectable: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  showSelectedInfo: {
    type: Boolean,
    default: false,
  },
});

const { deletePaymentDetails } = usePaymentDetails();
const { $t } = useI18n(); // Explicit Import for Storybook

const handleDeletePaymentDetails = () => {
  if (!props.paymentDetails.id) return;
  deletePaymentDetails(props.paymentDetails.id);
};

const expiryDate = computed(() => {
  return props.paymentDetails.expiryMonth + "/" + props.paymentDetails.expiryYear;
});
</script>

<style lang="scss">
@use "./SparPaymentDetailsCard.scss";
</style>
