// Default routes (to be modified by plugin!)
// DO NOT USE THESE PATHS IN THE APP! Always use getPath() instead!!!
export const Route = {
  brands: "/brands",
  brandsDetail: "/b/:brand",
  brandsDetailLocalProducer: "/l/:brand",
  cart: "/cart/:baseSite",
  category: "/c/:categoryId/:addionalSlugs*",
  checkAuth: "/check-auth",
  checkIn: "/check-in",
  checkoutConfirmation: "/checkout/:baseSite/confirmation",
  checkoutDelivery: "/checkout/:baseSite/delivery",
  checkoutLogin: "/checkout/:baseSite/login",
  checkoutPayment: "/checkout/:baseSite/payment",
  checkoutSummary: "/checkout/:baseSite/summary",
  forgottenPassword: "/forgotten-password",
  leaflets: "/leaflets/:region*",
  leafletsDetails: "/leaflets/:region/:leafletId",
  locationDetails: "/locations/:locationId",
  locationList: "/locations",
  login: "/login",
  logout: "/logout",
  myAccount: "/my-account",
  myAccountChangePassword: "/my-account/change-password",
  myAccountPersonalData: "/my-account/personal-data",
  myAccountOrders: "/my-account/orders",
  myAccountFavorites: "/my-account/favorites",
  myAccountNewsletter: "/my-account/newsletter",
  myAccountLoyalityPoints: "/my-account/loyality-points",
  paymentPayOne: "/payment/payone",
  paymentPayPal: "/payment/paypal",
  productDetail: "/p/:productId/:addionalSlugs*",
  registration: "/register",
  registrationConfirmation: "/registration-confirmation",
  reLogin: "/re-login",
  resetPassword: "/reset-password",
  search: "/search",
  components: "/components",
  mailForm: "/mail-form",
} as const;
export type Route = (typeof Route)[keyof typeof Route];

export type RouteName = keyof typeof Route;
