import type { GlobalLink } from "@shop-storefront/utils";
import { IconPosition } from "~/types/icon.types";

export const LinkVariant = {
  link: "link",
  linkIcon: "link-icon",
  secondary: "secondary",
  secondaryIcon: "secondary-icon",
} as const;
export type LinkVariant = (typeof LinkVariant)[keyof typeof LinkVariant];
export type LinkTarget = "none" | "_blank";

export type InternalLink = NonNullable<GlobalLink["internalLink"]>[number];

export interface SparLinkProps extends GlobalLink {
  link?: string;
  icon?: string;
  iconOnly?: boolean;
  iconPosition?: IconPosition;
  variant?: LinkVariant;
  toscaPrefix?: string;
}
