import type { ExternalFetch } from "~/plugins/fetch";
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";
import { getMiddlwareUrl } from "~/utils/ui";

export default class FactFinderIntegration {
  private readonly externalFetch: ExternalFetch;

  constructor(externalFetch: ExternalFetch) {
    this.externalFetch = externalFetch;
  }

  public async search<T>(options: Record<string, unknown>) {
    return this.externalFetch<T>(
      getMiddlwareUrl(useRuntimeConfig()) + servicesEndpoints.factfinder.search,
      options,
    );
  }
}
