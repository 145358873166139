<template>
  <NuxtLayout name="default" class="error-page">
    <div class="error-page">
      <div v-if="error" class="container">
        <div class="text-4xl">{{ error.statusMessage }}</div>
        <div v-if="error?.data?.errorType" class="text-4xl">{{ error.data.errorType }}</div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { RoutingError } from "~/composables/error-page/useErrorPage.types";

const errorRaw = useError() as Ref<RoutingError>;

// Nuxt oddity: value.data is converted to string, needs re-parsing
const error = computed(() => {
  const res = { ...errorRaw.value };
  if (res && typeof res.data === "string") {
    res.data = JSON.parse(res.data);
  }
  return res;
});
</script>

<style lang="scss">
.error-page {
  display: inline;
}
</style>
