<template>
  <div
    v-if="image?.asset || src"
    class="adaptive-image"
    :data-tosca="getToscaPrefix('adaptive-div', toscaPrefix)"
  >
    <img
      class="adaptive-image__img"
      :loading="loading"
      :src="image?.asset ?? src"
      :class="classes"
      :alt="image?.altText || alt"
      :data-tosca="getToscaPrefix('img', toscaPrefix)"
      :title="image?.title || title || ''"
    />

    <div v-if="image?.copyright" class="adaptive-image__copyright" :title="image.copyright">©</div>
  </div>
</template>

<script lang="ts" setup>
import { getToscaPrefix } from "~/utils/ui";
import type { SparDamAsset } from "../SparMediaBlock/SparMediaBlock.types";
import type { ImageLoadingType, SparAdaptiveImageProps } from "./SparAdaptiveImage.types";

const props: SparAdaptiveImageProps = defineProps({
  image: {
    type: Object as PropType<SparDamAsset>,
    default: undefined,
  },
  src: {
    type: String,
    default: "",
  },
  // Currently never used
  /* srcsets: {
    type: Array as PropType<SparAdaptiveImageSrcSet[]>,
    default: () => [],
    validator: (value: SparAdaptiveImageSrcSet[]) =>
      value.length === 0 ||
      value.every((item) => item.resolution && item.src) ||
      value.every((item) => item.src && item.width),
  }, */
  alt: {
    type: String,
    default: "",
  },
  // Currently never used
  /* width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  }, */
  objectFit: {
    type: String,
    default: "cover",
  },
  placeholder: {
    type: String,
    default:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==",
  },
  loading: {
    type: String as PropType<ImageLoadingType>,
    default: "lazy",
  },
  nuxtImgConfig: {
    type: Object,
    default: () => ({}),
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    default: "",
  },
});

// Currently never used
/*
const sortedSrcsets = computed(() => {
  const arr = [...props.srcsets];

  arr.sort((setA, setB) =>
    setA.width && setB.width ? setA.width - setB.width : setA.resolution - setB.resolution,
  );
  return arr;
});

const srcset = computed(() => {
  if (sortedSrcsets.value.length === 0) return null;
  return sortedSrcsets.value.reduce(
    (str, set) => `${prefix(str)}${set.src} ${srcsetDescriptor(set)}`,
    "",
  );
});

const sizes = computed(() => {
  const hasBreakpoints = sortedSrcsets.value.every((set) => set.breakpoint && set.width);
  if (!hasBreakpoints) return null;
  return sortedSrcsets.value.reduce(
    (str, set) => `${prefix(str)}${formatBreakpoint(set.breakpoint)}${formatDimension(set.width)}`,
    "",
  );
});
*/

const classes = computed(() => [`adaptive-image__img--${props.objectFit}`]);

/*
const attributes = computed(() => {
  return {
    sizes,
    srcset,
  };
});
*/

// TODO -> write lazy loading for images!!

/*
const styles = computed(() => {
  if (!props.width && !srcset) {
    // Missing required prop width
  }

  if (!props.height && !srcset) {
    // Missing required prop height
  }

  const sizeHandler = (size: string) => {
    return size === null ? null : `${size}px`;
  };

  return {
    "--_image-width": sizeHandler(props.width),
    "--_image-height": sizeHandler(props.height),
  };
});

const formatResolution = (resolution: number) => {
  return `${resolution}x`;
};

const formatDimension = (size: string) => {
  if (size === null) return;
  if (
    ["%"].includes(`${size}`.slice(-1)) ||
    ["rem"].includes(`${size}`.slice(-3)) ||
    ["em", "px", "vw", "vh"].includes(`${size}`.slice(-2)) ||
    !parseInt(size, 10)
  ) {
    return size;
  } else {
    return `${size}px`;
  }
};

const formatBreakpoint = (breakpoint: string) => {
  return breakpoint ? `(max-width: ${breakpoint}px) ` : "";
};

const prefix = (str: string) => {
  return str ? `${str}, ` : "";
};

const srcsetDescriptor = (srcset: SparAdaptiveImageSrcSet) => {
  return srcset.width ? `${srcset.width || ""}w` : formatResolution(srcset.resolution);
};
*/
</script>

<style lang="scss">
@use "./SparAdaptiveImage.scss";
</style>
