/*
This is a temporary workaround until latest Nuxt i18n supports loading
async translations again. $t is provided from this plugin and works as
a simple key/value-store.
As soon as Nuxt i18n included this feature, this plugin is obsolete.
https://github.com/nuxt-modules/i18n/issues/1829
https://github.com/nuxt-modules/i18n/issues/1448
https://github.com/nuxt-modules/i18n/pull/1576
*/
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";
import { getMiddlwareUrl } from "~/utils/ui";

interface FetchTranslationsResult {
  data: Ref<Record<string, string>>;
  error: Ref<unknown>;
}

// Load messages from i18n integration
const getTranslations = async () => {
  try {
    const { data, error } = (await useFetch(
      getMiddlwareUrl(useRuntimeConfig()) + servicesEndpoints.i18n.getTranslations,
      {
        method: "post",
      },
    )) as FetchTranslationsResult;
    if (error.value) throw new Error();
    return data.value;
  } catch (e) {
    return {};
  }
};

export default defineNuxtPlugin(async () => {
  const messages = await getTranslations();

  return {
    provide: {
      t: (key: string, values: { [key: string]: string } = {}): string => {
        const message = messages?.[key];
        if (values && typeof message === "string") {
          return Object.entries(values).reduce((msg, [key, value]) => {
            const search = `{${key}}`;
            return msg.replace(new RegExp(search, "g"), value);
          }, message);
        }
        return message || key;
      },
    },
  };
});
