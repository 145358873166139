<template>
  <div class="checkout-payment-provider-payone">
    <div>
      <dl v-if="allCards.length" class="payment-details__list">
        <div v-for="card in allCards" :key="card.id" class="payment-details__list-item">
          <spar-payment-details-card
            :payment-details="card"
            :is-deletable="false"
            :is-selectable="selectedCard !== card.id"
            :is-selected="confirmedPaymentId === card.id || selectedCard === card.id"
            :show-selected-info="confirmedPaymentId === card.id"
            :data-tosca="`payment-details-saved-card-${card.id}`"
            @click="selectCard(card.id)"
          >
            <template #default>
              <!-- CVC Widget -->
              <div v-show="showCvcWidget && selectedCard === card.id">
                <div
                  :id="`payment-content-${card.tokenId}`"
                  class="checkout-payment-provider-payone__widget"
                ></div>
                <spar-button
                  :label="$t('checkout.step.payment.payone.check_card.btn')"
                  tosca-prefix="checkout-payment-payone-savecard"
                  :loading="isLoading"
                  @click.stop="saveCard"
                />
              </div>
            </template>
          </spar-payment-details-card>
        </div>
      </dl>

      <!-- tosca-prefix="checkout-payment-card-selector" -->

      <!-- <p v-if="allCards.length">
        <spar-button
          :disabled="selectedCard === undefined || selectedCardBuffer === selectedCard"
          :label="$t('checkout.step.payment.payone.select_card')"
          tosca-prefix="checkout-payment-payone-selectcard"
          @click="selectCard"
        />
      </p> 

    --></div>

    <!-- New Card Widget -->
    <div v-show="showWidget && selectedCard === undefined">
      <spar-card>
        <template #title>{{ $t("checkout.step.payment.payone.add_card.label") }}</template>
        <template #icon
          ><spar-button
            :variant="ButtonVariant.custom"
            icon="close"
            icon-only
            :aria-label="$t('my_account.personal_data.payment_details.delete')"
            tosca-prefix="payment-details-delete"
            @click="hideAddNewCard()"
        /></template>
        <template #content>
          <div
            :id="`payment-content-${paymentCode}`"
            class="checkout-payment-provider-payone__widget"
          ></div>

          <div
            class="checkout-payment__payment-mode-check"
            :class="{
              'checkout-payment__payment-mode-check--valid': isPaymentModeConfirmed,
              'checkout-payment__payment-mode-check--invalid': isPaymentModeConfirmed === false,
            }"
          >
            <spar-button
              :label="$t('checkout.step.payment.payone.check_card.btn')"
              tosca-prefix="checkout-payment-payone-savecard"
              :loading="isLoading"
              @click="saveCard"
            />
          </div>
        </template>
      </spar-card>
    </div>

    <p v-if="!showAddNew">
      <spar-button
        :variant="ButtonVariant.secondary"
        :loading="isLoading"
        icon="plus"
        :icon-position="IconPosition.left"
        class="checkout-payment-provider-payone__add-new"
        :label="$t('checkout.step.payment.payone.add_card.btn')"
        tosca-prefix="checkout-payment-payone-add-newcard"
        @click="addNewCard"
      />
    </p>
  </div>
</template>

<script setup lang="ts">
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparCard from "~/components/shared/SparCard/SparCard.vue";
import SparPaymentDetailsCard from "~/components/shared/SparPaymentDetailsCard/SparPaymentDetailsCard.vue";
import useI18n from "~/composables/i18n/useI18n";
import { IconPosition } from "~/types/icon.types";
import { type PaymentProviderPayment, PaymentStatus } from "../SparCheckoutPayment.types";

const props = defineProps({
  paymentCode: {
    type: String,
    required: true,
  },
  savedCards: {
    type: Array as PropType<PaymentProviderPayment[]>,
    required: true,
  },
  addedCards: {
    type: Array as PropType<PaymentProviderPayment[]>,
    default: () => [],
  },
  status: {
    type: String as PropType<PaymentStatus | undefined>,
    default: undefined,
  },
  confirmedPaymentId: {
    type: String,
    default: "",
  },
  isPaymentModeConfirmed: {
    type: Boolean,
    default: undefined,
  },
  isError: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["saveCard", "selectCard", "showWidget"]);
const { $t } = useI18n(); // Explicit Import for Storybook

const showAddNew = ref(false);
const showConfirmOld = ref(false);
const selectedCard = ref<string>();

const allCards = computed(() => {
  const addedCards = props.addedCards.map((card) => {
    return { ...card, addedCard: true };
  });
  const savedCards = props.savedCards
    .map((card) => {
      return { ...card, savedCard: true };
    })
    // A saved card is duplicated as an added card when re-using it; only show the added card then
    .filter((card) => !Boolean(addedCards.find((addedCard) => addedCard.tokenId === card.tokenId)));

  return [...addedCards, ...savedCards];
});

const addNewCard = () => {
  showAddNew.value = true;
  selectedCard.value = undefined;
  showConfirmOld.value = false;
  emit("showWidget");
};

const hideAddNewCard = () => {
  showAddNew.value = false;
  selectedCard.value = undefined;
};

const selectCard = (id: string) => {
  if (id === selectedCard.value) return;
  hideAddNewCard();

  selectedCard.value = id;
  const card = currentCard.value;
  if (!card) return;

  if (card.savedCard) {
    showConfirmOld.value = true;
  }
  emit("selectCard", card);
};

const currentCard = computed(() => allCards.value.find((card) => card.id === selectedCard.value));

const saveCard = () => {
  const eventName = "saveCard";
  selectedCard.value === undefined ? emit(eventName) : emit(eventName, currentCard.value);
};

const showWidget = computed(() => {
  return (
    (props.status === PaymentStatus.ready || props.status === PaymentStatus.busy) &&
    showAddNew.value
  );
});

const showCvcWidget = computed(() => {
  return (
    (props.status === PaymentStatus.ready || props.status === PaymentStatus.busy) &&
    showConfirmOld.value
  );
});

const isLoading = computed(
  () => props.status === PaymentStatus.loading || props.status === PaymentStatus.busy,
);

watch(
  () => props.isPaymentModeConfirmed,
  (value) => {
    if (value) {
      showConfirmOld.value = false;
      showAddNew.value = false;
    }
  },
);
</script>

<style lang="scss">
@use "./SparPayone.scss";
@use "../../../SparMyAccount/SparPaymentDetails/SparPaymentDetails.scss";
</style>
