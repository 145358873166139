<template>
  <div class="checkout-proceed">
    <div class="checkout-proceed__content-wrapper">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss">
@use "./SparCheckoutProceed.scss";
</style>
