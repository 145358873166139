<template>
  <div class="teaser">
    <component :is="hasLink ? SparLink : 'div'" v-bind="hasLink ? link : {}" class="teaser__card">
      <div class="teaser__image-container">
        <spar-adaptive-image v-if="image" class="teaser__image" :image="image" />
      </div>
      <div class="teaser__content-container">
        <div v-if="heading || text" class="teaser__text-container">
          <spar-heading v-if="heading" class="teaser__heading heading--left" v-bind="heading" />
          <div v-if="text" class="teaser__text">
            {{ text }}
          </div>
        </div>

        <div v-if="link?.label" class="teaser__link btn btn--primary btn--icon btn--icon-right">
          {{ link.label }}
          <spar-icon-sprite class="link__icon" symbol="arrow-right" />
        </div>
      </div>
    </component>
  </div>
</template>

<script lang="ts" setup>
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import type { SparHeadingProps } from "~/components/shared/SparHeading/SparHeading.types";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import type { SparLinkProps } from "~/components/shared/SparLink/SparLink.types";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import type {
  SparTeaserProps,
  SparTeaserWidth,
} from "~/components/shared/SparTeaser/SparTeaser.types";
import type { SparDamAsset } from "../SparMediaBlock/SparMediaBlock.types";

const props: SparTeaserProps = defineProps({
  heading: {
    type: Object as PropType<SparHeadingProps>,
    default: undefined,
  },
  image: {
    type: Object as PropType<SparDamAsset>,
    default: undefined,
  },
  link: {
    type: Object as PropType<SparLinkProps>,
    default: undefined,
  },
  teaserWidth: {
    type: Object as PropType<SparTeaserWidth>,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
});

const hasLink = computed(() => {
  return props.link?.label;
});
</script>

<style lang="scss">
@use "./SparTeaser.scss";
</style>
