import FactFinderIntegration from "~/utils/factfinder/integration/factfinder.integration";
import type { ExternalFetch } from "../fetch";

export default defineNuxtPlugin(async ({ $externalFetch }) => {
  return {
    provide: {
      factFinder: new FactFinderIntegration($externalFetch as ExternalFetch),
    },
  };
});
