<template>
  <div class="search-result-page container">
    <template v-if="content?.[0]?.topSections">
      <render-sections :content="content[0].topSections" />
    </template>

    <spar-search />

    <template v-if="content?.[0]?.bottomSections">
      <render-sections :content="content[0].bottomSections" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { SearchResultsPage } from "@shop-storefront/utils";
import queryString from "query-string";
import SparSearch from "~/components/feature/SparSearch/SparSearch.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import { useSearchStore } from "~/stores/search.store";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { searchResultsPageReferences } from "~/utils/contentstack/constants/references.constants";
import { scrollToId } from "~/utils/ui";

const route = useRoute();
const { $contentstack } = useNuxtApp();
const searchStore = useSearchStore();
const { searchTerm } = storeToRefs(useSearchStore());
const { getSlug } = useRoutes();

const { data: content } = await useAsyncData("searchResultsPage", async () => {
  return await $contentstack.getEntries<SearchResultsPage>({
    type: ContentType.searchResultsPage,
    customQuery: {
      [ContentstackPageParams.slug]: getSlug("search"),
    },
    includeReference: searchResultsPageReferences as string[],
  });
});

const loadResults = async () => {
  await searchStore.setQuery(
    // parseUrl automatically skips the FragmentIdentifier (#)
    queryString.parseUrl(route.fullPath, {
      parseNumbers: true,
      arrayFormat: "comma",
    }).query,
  );

  // Scroll to section after results are rendered if hash is provided
  nextTick(() => {
    if (route.hash) {
      scrollToId(route.hash.substring(1));
    }
  });
};

// Start search when navigating to search page via flyout OR deep link
onMounted(() => {
  if (typeof route.query.search === "string") {
    searchTerm.value = route.query.search;
  }

  // Deep link fix - delay start of search
  nextTick(async () => {
    await loadResults();
  });
});

// TODO: This avoids unexpected behaviour with old search results in the store
// It works, but needs a global approach in the future
onUnmounted(() => searchStore.resetResults("products"));

// Start new search when the search page is already open (watch url)
watch(
  () => route.fullPath,
  (fullPath) => {
    if (!fullPath) return;
    loadResults();
  },
);
</script>
