import type { NavigationElement } from "@shop-storefront/utils";
import type {
  Navigation,
  NavigationNode,
} from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import type { ContentstackPlugin } from "~/plugins/contentstack/contentstack.types";
import type { IndexPage } from "~/plugins/indexes/indexes.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import {
  categoryIndexReferences,
  navigationIndexReferences,
} from "~/utils/contentstack/constants/references.constants";
import { resolveBreadcrumbIndexes } from "~/utils/contentstack/resolvers/category/category.resolvers";
import {
  resolveNavigationIndexes,
  getActiveMenu,
} from "~/utils/contentstack/resolvers/navigation/navigation.resolvers";

async function getNavigationIndex(
  contentstack: ContentstackPlugin,
  activeMenuItem: string,
): Promise<Navigation> {
  const index = await contentstack.getEntries<NavigationElement[]>({
    type: ContentType.navigationElement,
    includeCount: true,
    includeReference: navigationIndexReferences as string[],
  });

  const nodes = resolveNavigationIndexes(index[0], null, 0, activeMenuItem);
  const activeNode = getActiveMenu() || nodes[0];

  return {
    nodes,
    activeNode,
    activeMenuLevel: activeNode.level,
    activeMobileMenu: "",
    levelPath: activeNode.levelPath ? activeNode.levelPath : [],
    flyoutOpenable: false,
  };
}

async function getBreadcrumbIndex(contentstack: ContentstackPlugin) {
  const index = await contentstack.getEntries<IndexPage[]>({
    type: ContentType.categoryPage,
    includeCount: true,
    includeReference: categoryIndexReferences as string[],
  });

  return resolveBreadcrumbIndexes(index[0]);
}

export default defineNuxtPlugin(async ({ $contentstack }) => {
  const { path } = useRoute();

  // TODO set timeout here to get all nodes each 10 minutes f.e.
  const navigation = await getNavigationIndex($contentstack as ContentstackPlugin, path);
  const breadcrumbs = await getBreadcrumbIndex($contentstack as ContentstackPlugin);

  const getBreadcrumbs = (categoryId: string): NavigationNode[] => {
    if (!breadcrumbs[categoryId]) {
      Log.warn(LogArea.navigation, `Breadcrumb for category-id ${categoryId} not found`);
    }

    return breadcrumbs[categoryId];
  };

  // TODO: Not tested, no data available, needs refactoring
  const getSubNavigation = (categoryId: string): NavigationNode | undefined => {
    const result = JSON.parse(JSON.stringify(navigation), (_, nestedValue) => {
      if (nestedValue && nestedValue["categoryId"] === categoryId) {
        return nestedValue;
      }
    }) as NavigationNode;

    if (result === undefined) {
      Log.error(LogArea.navigation, `Sub Navigation for category-id ${categoryId} not found`);
    }

    return result;
  };

  return {
    provide: {
      indexes: {
        navigation,
        getBreadcrumbs,
        getSubNavigation,
      },
    },
  };
});
