import type { TextImageVideoSection } from "@shop-storefront/utils";

export type { SparDamAsset } from "@shop-storefront/utils";

export const SparMediaBlockVariant = {
  text: "text",
  image: "image",
  video: "video",
} as const;
export type SparMediaBlockVariant =
  (typeof SparMediaBlockVariant)[keyof typeof SparMediaBlockVariant];

export type SparMediaBlockProps = NonNullable<TextImageVideoSection["leftSide"]>;
