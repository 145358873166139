<template>
  <div class="address__overlay">
    <h2 v-if="heading">{{ heading }}</h2>
    <p v-if="description">{{ description }}</p>
    <form class="address__form" novalidate @submit.prevent="onSubmit">
      <div class="w-50">
        <spar-select
          v-model="formData.titleCode"
          required
          class="address__input-salutation"
          :label="$t('form.input.salutation.label')"
          :options="titleOptions"
          :tosca-prefix="getToscaPrefix('salutation', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-50">
        <spar-select
          v-model="formData.secondTitleCode"
          class="address__input-title"
          :label="$t('form.input.title.label')"
          :options="secondTitleCodeOptions"
          :enable-hint-option="true"
          :tosca-prefix="getToscaPrefix('title', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-100">
        <spar-input
          v-model="formData.firstName"
          class="address__input-first-name"
          :type="SparInputType.text"
          :label="$t('form.input.firstname')"
          required
          :tosca-prefix="getToscaPrefix('firstname', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-100">
        <spar-input
          v-model="formData.lastName"
          class="address__input-last-name"
          :type="SparInputType.text"
          :label="$t('form.input.lastname')"
          required
          :tosca-prefix="getToscaPrefix('lastname', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-60">
        <spar-select
          v-if="countryOptions"
          v-model="formData.country.isocode"
          class="address__input-country"
          :options="countryOptions"
          :label="$t('form.input.country')"
          required
          :tosca-prefix="getToscaPrefix('country', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-40">
        <spar-input
          v-model="formData.postalCode"
          class="address__input-zip"
          :type="SparInputType.text"
          :label="$t('form.input.postalcode')"
          required
          :tosca-prefix="getToscaPrefix('zip', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-100">
        <spar-input
          v-model="formData.town"
          class="address__input-city"
          :type="SparInputType.text"
          :label="$t('form.input.town')"
          required
          :tosca-prefix="getToscaPrefix('city', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-60">
        <spar-input
          v-model="formData.line1"
          class="address__input-street"
          :type="SparInputType.text"
          :label="$t('form.input.street')"
          required
          :tosca-prefix="getToscaPrefix('street', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-40">
        <spar-input
          v-model="formData.line2"
          class="address__input-number"
          :type="SparInputType.text"
          :label="$t('form.input.house_number')"
          required
          :tosca-prefix="getToscaPrefix('housenr', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-33">
        <spar-input
          v-model="formData.stair"
          class="address__input-stair"
          :type="SparInputType.text"
          :label="$t('form.input.stair')"
          :tosca-prefix="getToscaPrefix('stair', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-33">
        <spar-input
          v-model="formData.floor"
          class="address__input-floor"
          :type="SparInputType.text"
          :label="$t('form.input.floor')"
          :tosca-prefix="getToscaPrefix('floor', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-33">
        <spar-input
          v-model="formData.door"
          class="address__input-door"
          :type="SparInputType.text"
          :label="$t('form.input.door')"
          :tosca-prefix="getToscaPrefix('door', toscaPrefix)"
          @interface="getChildInterface"
        />
      </div>

      <div class="w-100">
        <spar-input
          v-model="formData.phone"
          class="address__input-phone"
          :type="SparInputType.text"
          :label="$t('form.input.phone')"
          :tosca-prefix="getToscaPrefix('phone', toscaPrefix)"
          required
          @interface="getChildInterface"
        />
      </div>

      <!-- https://collaboration.spar.at/jira/browse/HYBS-15275 -->
      <!-- <div class="w-100">
        <spar-input
          v-model="formData.email"
          class="address__input-email"
          :type="SparInputType.email"
          :label="$t('form.input.email')"
          required
          @interface="getChildInterface"
        />
      </div> -->

      <spar-button
        type="submit"
        class="address__submit-button"
        tosca-prefix="checkout-delivery-send"
      >
        {{ submitLabel || $t("address.form.submit") }}
      </spar-button>
    </form>
  </div>
</template>

<script setup lang="ts">
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { SparInputType } from "~/components/shared/SparInput/SparInput.types";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import type { SparSelectOptions } from "~/components/shared/SparSelect/SparSelect.types";
import SparSelect from "~/components/shared/SparSelect/SparSelect.vue";
import useAddress from "~/composables/form/useAddress";
import useForm from "~/composables/form/useForm";
import useI18n from "~/composables/i18n/useI18n";
import { useCountryStore } from "~/stores/country.store";
import type { CountryType } from "~/types/country.types";
import type { Address } from "~/types/occ.types";
import { getToscaPrefix } from "~/utils/ui";

const { $t } = useI18n();
const { getChildInterface, validateAll } = useForm();
const { getCountryOptions, getTitleOptions, getSecondTitleOptions } = useAddress();
const { getCountries, countries } = useCountryStore();

const props = defineProps({
  address: {
    type: Object as PropType<Address>,
    default: undefined,
  },
  heading: {
    type: String,
    default: undefined,
  },
  description: {
    type: String,
    default: undefined,
  },
  submitLabel: {
    type: String,
    default: undefined,
  },
  countryType: {
    type: String as PropType<CountryType>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

interface SparAddressFormEmits {
  (e: "submit", value: Address): void;
}
const emit = defineEmits<SparAddressFormEmits>();

const formData = reactive({
  country: {
    isocode: "",
  },
  door: undefined,
  email: undefined,
  firstName: "",
  floor: undefined,
  lastName: "",
  line1: "",
  line2: undefined,
  phone: undefined,
  postalCode: "",
  secondTitleCode: undefined,
  stair: undefined,
  titleCode: "",
  town: "",
});

function onSubmit(): void {
  if (validateAll()) {
    emit("submit", formData);
  }
}

onMounted(async () => {
  if (props.address) Object.assign(formData, props.address);
  try {
    await getCountries([props.countryType]);
  } catch (e: unknown) {
    Log.error(LogArea.search, "Could not fetch countries");
  }
});

const countryOptions = computed<SparSelectOptions>(() => {
  const countryList = countries[props.countryType]?.countries;
  if (!countryList) return [];

  return getCountryOptions(countryList);
});

const titleOptions = getTitleOptions();
const secondTitleCodeOptions = getSecondTitleOptions();
</script>

<style lang="scss">
@use "./SparAddressForm.scss";
</style>
