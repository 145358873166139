import type { SearchTopicType } from "~/components/feature/SparSearch/SparSearch.types";
import type { ProductSchemaV1SparProductTypeEnum } from "~/types/mdsa.types";
import type { Asset } from "~/utils/mdsa/integration/mdsa.types";

export const FactFinderCampaignFlavour = {
  redirect: "REDIRECT",
} as const;
export type FactFinderCampaignFlavour =
  (typeof FactFinderCampaignFlavour)[keyof typeof FactFinderCampaignFlavour];

export const FactFinderFilterStyle = {
  multiselect: "MULTISELECT",
  slider: "SLIDER",
  default: "DEFAULT",
} as const;
export type FactFinderFilterStyle =
  (typeof FactFinderFilterStyle)[keyof typeof FactFinderFilterStyle];

export interface FactFinderConfig {
  apiUrl: string;
  userName: string;
  password: string;
}

export interface SortedItem {
  order: string;
  name: string;
  description: string;
  searchParams: {
    query: string;
    sortItems: [
      {
        order: string;
        name: string;
      },
    ];
    hitsPerPage: number;
  };
  selected: boolean;
}

export interface GeoInformation {
  market: string;
  geoValues: {
    bestand: string;
  };
}

export interface FactFinderTopicHit {
  id: string;
  masterValues: {
    description: string;
    h1: string;
    h2: string;
    id: string;
    title: string;
    slug: string;
    image: string;
  };
  position: number;
  score: number;
  variantValues: [];
  foundWords: [];
}

type PriceKey = `${string}.salesPrice.price`;
export interface FactFinderProductHit {
  foundWords: [];
  id: string;
  masterValues: {
    [key in PriceKey]: number;
  } & {
    EAN: string;
    availability: string;
    availabilityType_P: string;
    availabilityType_T: string;
    availabilityTypes: string;
    badgeAltText: string;
    badgeDamUrl: string;
    brand_feName: string;
    brand_value: string;
    buyable: string;
    colour_feName: string;
    colour_value: string;
    condition: string;
    country: string;
    creationTime: string;
    currency: string;
    currencySymbol: string;
    currentlyReservable: string;
    deliveryModes: string;
    deliveryTime: string;
    ecrCategoryIdLevel1: string;
    ecrCategoryIdLevel2: string;
    ecrCategoryIdLevel3: string;
    ecrCategoryIdLevel4: string;
    ecrCategoryIdLevel5: string;
    ecrCategoryIdLevel6: string;
    ecrCategoryIdLevel7: string;
    ecrCategoryIds: string;
    ecrCategoryNameLevel1: string;
    ecrCategoryNameLevel2: string;
    ecrCategoryNameLevel3: string;
    ecrCategoryNameLevel4: string;
    ecrCategoryNameLevel5: string;
    ecrCategoryNameLevel6: string;
    ecrCategoryNameLevel7: string;
    energyClass_feName: string;
    energyClass_value: string;
    energyConsumption_amount: string;
    energyConsumption_feName: string;
    energyConsumption_unit: string;
    futureValidFrom: string;
    geoInformation: GeoInformation[];
    insteadPriceString: string;
    mainGtin: string;
    minOrderQuantityP: string;
    minOrderQuantityT: string;
    maxOrderQuantityP: string;
    maxOrderQuantityT: string;
    name1: string;
    name2: string;
    name3: string;
    name4: string;
    noise_amount: string;
    noise_feName: string;
    noise_unit: string;
    onSale: string;
    productDescription: string;
    productId: string;
    productImage_altText: string;
    productImage_assetUrl: string;
    promoText: string;
    tOnline_promoText: string;
    recommendedRetailPriceString: string;
    referencePriceString: string;
    tOnline_referencePriceString: string;
    tOnline_insteadPriceString: string;
    tOnline_salesPriceString: string;
    tOnline_savingsString: string;
    tOnSale: string;
    salesPrice: number;
    salesPriceAmount: number;
    salesPriceString: string;
    salesPriceUnit: string;
    savingsString: string;
    searchable: string;
    shopUrl: string;
    shopCategoryIdLevel1: string;
    shopCategoryIdLevel2: string;
    shopCategoryIdLevel3: string;
    shopCategoryIdLevel4: string;
    shopCategoryIdLevel5: string;
    shopCategoryIds: string;
    shopCategoryNameLevel1: string;
    shopCategoryNameLevel2: string;
    shopCategoryNameLevel3: string;
    shopCategoryNameLevel4: string;
    shopCategoryNameLevel5: string;
    sparProductType: ProductSchemaV1SparProductTypeEnum;
    spinningSpeed_amount: string;
    spinningSpeed_unit: string;
    taxRate: string;
    taxText: string;
    washingCapacity_amount: string;
    washingCapacity_feName: string;
    washingCapacity_unit: string;
  };
  position: number;
  score: number;
  variantValues: [];
}

export type FactFinderApiResponse = FactFinderTopicHit & FactFinderProductHit;
export type FactFinderHit = FactFinderTopicHit | FactFinderProductHit;
export interface FactFinderCampaign {
  id: string;
  target: FactFinderCampaignTarget;
  name: string;
  category: string;
  flavour: FactFinderCampaignFlavour;
  excludeProductsNotInMarkets: boolean;
  excludeProductsNotInRange: boolean;
  feedbackTexts: [];
  ad: boolean;
  activeQuestions: [];
  advisorTree: [];
  hits: [];
}

export interface FactFinderCampaignTarget {
  destination: string;
  name: string;
}

export interface FactFinderFacet {
  text: string;
  totalHits?: number;
  selected?: string;
  searchParams?: {
    [key: string]: string | number | boolean;
  };
  absoluteMinValue?: number;
  absoluteMaxValue?: number;
  selectedMinValue?: number;
  selectedMaxValue?: number;
}

export interface FactFinderFacets {
  name: string;
  elements: FactFinderFacet[];
  selectedElements?: FactFinderFacet[];
  detailedLinks?: number;
  unit?: string;
  type?: string;
  showPreviewImages?: boolean;
  filterStyle: string;
  selectionType?: string;
  associatedFieldName: string;
}

export interface FactFinder {
  searchParams: {
    [key: string]: string | number | boolean;
  };
  searchControlParams: {
    useGeo: boolean;
    //useAtlasAi?: boolean; // Added this field as it appears in your JSON response
  };
  breadCrumbTrail: [
    {
      type: string;
      searchParams: {
        [key: string]: string | number | boolean;
      };
      text: string;
      value: string;
    },
  ];
  totalHits: number;
  articleNumberSearch: boolean;
  //followSearch?: string;
  scoreFirstHit: number;
  scoreLastHit: number;
  hits: FactFinderHit[];
  filters: [];
  answers: [];
  facets: FactFinderFacets[];
  paging: {
    currentPage: number;
    pageCount: number;
    hitsPerPage: number;
    defaultHitsPerPage: number;
  };
  tookTotal: number;
  tookWorldmatch: number;
  tookLoop54: number;
  //tookAtlasAi?: number;
  //tookGPTSynonyms?: number;
  timedOut: boolean;
  splitDocuments: boolean;
  campaigns: FactFinderCampaign[];
  fieldRoles: {
    brand: string;
    deeplink: string;
    description: string;
    ean: string;
    imageUrl: string;
    price: string;
    productName: string;
    productNumber: string;
  };
  resolvedProducts?: FactFinderProductResolved[] | null;
  resolvedTopics?: SearchTopicType[] | null;
  singleWordResults: [];
  sortItems: SortedItem[];
  /*   geo?: {
    // Added this field as it appears in your JSON response
    markets: any[]; // Replace 'any' with the actual type if known
    selectedMarkets: any[]; // Replace 'any' with the actual type if known
    showDistance: boolean;
    showMarkets: boolean;
  }; */
}

export interface FactFinderProductResolved {
  productId: string;
  name1?: string;
  name2?: string;
  name3?: string;
  name4?: string;
  shopUrl: string;
  tileSalesPrice: string;
  tilePicture: Asset;
}
