import type { RuntimeConfig } from "nuxt/schema";

// Do not use this function for SSR generated ids! Use useId() instead!
export function getUniqueId(prefix: string) {
  return prefix + "-" + Math.floor(Math.random() * 9999999);
}

/**
 * Smooth scroll to given HTML Element
 */
export function scrollToElement(element: HTMLElement) {
  window.scroll({
    behavior: "smooth",
    left: 0,
    top: element.getBoundingClientRect().top + window.scrollY,
  });
}

/**
 * Smooth scroll to given ID
 * Retrieves the corresponding HTML Element and calls scrollToElement
 */
export function scrollToId(id: string) {
  const elem = document.getElementById(id);
  if (!elem) return;
  scrollToElement(elem);
}

export function scrollTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

export function isStorybook() {
  return Boolean(import.meta.env.STORYBOOK);
}

export function isClient() {
  if (typeof process === "undefined") return true;
  return process.client || isStorybook();
}

export function getToscaPrefix(
  suffix: string,
  prefix: undefined | string,
  id: undefined | number = undefined,
) {
  if (!prefix) return undefined;

  let toscaLabel = prefix + (id ? "-" + id : "") + "-" + suffix;

  // Replace spaces with underscores
  toscaLabel = toscaLabel.replace(/ /g, "_");

  return toscaLabel;
}

// https://stackoverflow.com/a/822464/6549013
export function stripHtml(input: string) {
  return input.replace(/<[^>]*>?/gm, "");
}

// "Lorem [Ipsum] 123+456" -> lorem_ipsum_123456
export function getSanitizedIdString(str: string) {
  return str
    .replace(/[^a-zA-Z0-9-_ ]/g, "")
    .replaceAll(" ", "_")
    .toLowerCase();
}

// Return the alphabet as an array
export function getAlphabet(uppercase = false) {
  const start = uppercase ? 65 : 97;

  const letters = [];
  for (let i = start; i <= start + 25; i++) {
    letters.push(String.fromCharCode(i));
  }
  return letters;
}

export function getMiddlwareUrl(runtimeConfig: RuntimeConfig) {
  if (isClient()) {
    // Interspar URL for Client Side Rendering
    return runtimeConfig.public.middlewareUrl;
  }

  // Storefrontcloud URL for SSR
  return runtimeConfig.public.middlewareUrlSsr;
}

// 1718222100 -> 2024-06-12
export function formatTimeStampToDate(timestamp: number) {
  const date = new Date(timestamp * 1000);
  return date.toISOString().split("T")[0];
}

export function formatPrice(price: number) {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(price);
}

export function numberFormat(value: string) {
  return value.replace(".", ",");
}
