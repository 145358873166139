<template>
  <div class="plp-facets-slideout__facet">
    <h3>
      <spar-button
        :id="`${id}_heading`"
        class="plp-facets-slideout__facet-heading"
        :variant="ButtonVariant.custom"
        :aria-expanded="isOpen"
        :aria-controls="id"
        @click="isOpen = !isOpen"
        ><span>{{ facet.name }}</span
        ><spar-icon-sprite
          class="plp-facets-slideout__facet-icon"
          :symbol="isOpen ? 'minus' : 'plus'"
      /></spar-button>
    </h3>
    <div
      v-if="isOpen"
      :id="id"
      role="region"
      :aria-labelledby="`${id}_heading`"
      class="plp-facets-slideout__facet-content"
    >
      <div v-if="facet.filterStyle === FactFinderFilterStyle.multiselect">
        <spar-plp-multi-select
          :options="[...facet.selectedElements, ...facet.elements]"
          :name="facet.name"
          :filter-name="facet.associatedFieldName"
        ></spar-plp-multi-select>
      </div>

      <div v-if="facet.filterStyle === FactFinderFilterStyle.slider">
        <spar-plp-range-slider
          :options="[...facet.elements, ...facet.selectedElements][0]"
          :filter-name="facet.associatedFieldName"
          :unit="facet.unit"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import {
  type FactFinderFacets,
  FactFinderFilterStyle,
} from "~/utils/factfinder/integration/factfinder.types";
import SparPlpMultiSelect from "../SparPlpMultiSelect/SparPlpMultiSelect.vue";
import SparPlpRangeSlider from "../SparPlpRangeSlider/SparPlpRangeSlider.vue";

const props = defineProps({
  facet: {
    type: Object as PropType<FactFinderFacets>,
    required: true,
  },
});

const isOpen = ref(false);

const id = computed(() => props.facet.associatedFieldName);
</script>
