<template>
  <spar-heading
    class="personal-data__heading"
    level="2"
    headline-style="2"
    :title="$t('my_account.personal_data.title')"
  />

  <spar-loader v-if="isLoading" class="personal-data__loader" />

  <div v-else-if="hasLoadingError" aria-live="polite">
    {{ $t("my_account.personal_data.error.loading") }}
  </div>

  <div v-else-if="userData">
    <spar-heading class="personal-data__heading" level="3" headline-style="3" :title="userName" />

    <dl class="personal-data__fields">
      <dt>
        {{ $t("my_account.personal_data.customer_number") }}
      </dt>

      <dd data-tosca="personaldata-customernr">{{ userData.crmCustomerId || "-" }}</dd>

      <dt>{{ $t("my_account.personal_data.birthdate") }}</dt>
      <dd>
        <span
          v-if="!isEditing || (isEditing && userBirthDate)"
          data-tosca="personaldata-birthdate"
          >{{ userBirthDate || "-" }}</span
        >
        <spar-input
          v-else
          v-model="birthDate"
          :type="SparInputType.date"
          :label="$t('my_account.personal_data.birthdate')"
          :min-date="birthDateMinValue"
          :max-date="getTodayForMinMax()"
          tosca-prefix="personaldata-birthdate"
          @interface="getChildInterface"
        />
      </dd>

      <dt>{{ $t("my_account.personal_data.phone_number") }}</dt>
      <dd>
        <span v-if="!isEditing" data-tosca="personaldata-phonenr">{{
          userData.phoneNumber || "-"
        }}</span>
        <spar-input
          v-else
          v-model="phoneNumber"
          :label="$t('my_account.personal_data.phone_number')"
          :maxlength="30"
          tosca-prefix="personaldata-phonenr"
          @interface="getChildInterface"
        />
      </dd>
    </dl>

    <spar-button
      :disabled="isSaving"
      :aria-label="$t('my_account.personal_data.phone_number.edit')"
      :tosca-prefix="`personaldata-${ctaText.toLowerCase().replace(/\s/g, '')}`"
      @click="onClick"
      >{{ ctaText }}</spar-button
    >

    <div v-if="hasSavingError" aria-live="polite" data-tosca="personaldata-error-saving">
      {{ $t("my_account.personal_data.error.saving") }}
    </div>
    <div v-if="hasDataError" aria-live="polite" data-tosca="personaldata-error-invalid">
      {{ $t("my_account.personal_data.error.invalid") }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import { SparInputType } from "~/components/shared/SparInput/SparInput.types";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import SparLoader from "~/components/shared/SparLoader/SparLoader.vue";
import { getTodayForMinMax } from "~/composables/inputs/useInputValue";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import { useUserStore } from "~/stores/user.store";

const { $t } = useI18n();
const isLoading = ref(true);
const hasDataError = ref(false);
const children = ref([]) as Ref<InputInterface[]>;

const {
  hasLoadingError,
  userData,
  hasSavingError,
  birthDateMinValue,
  birthDate,
  phoneNumber,
  isSaving,
  isEditing,
} = storeToRefs(useUserStore());
const { getUserData, setUserData } = useUserStore();

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};

const userName = computed(() => {
  if (!userData.value) return "";

  const res: string[] = [];
  res.push(userData.value?.title || "");
  res.push(userData.value?.firstName || "");
  res.push(userData.value?.lastName || "");
  return res.filter((str) => str).join(" ");
});

const userBirthDate = computed(() => {
  if (!userData.value || !userData.value.birthDate) return "";

  const chunks = userData.value.birthDate.split("-");
  const reversed = chunks.reverse();
  return reversed.join(".");
});

const ctaText = computed(() => {
  if (isEditing.value) {
    return $t("my_account.personal_data.action.save");
  }
  return $t("my_account.personal_data.action.edit");
});

const onClick = () => {
  if (isEditing.value) {
    const res = children.value.map((child) => child.validate());
    const isValid = res.every((isValid) => isValid);

    if (isValid) {
      hasDataError.value = false;
      setUserData();
    } else {
      hasDataError.value = true;
    }
  } else {
    phoneNumber.value = userData.value?.phoneNumber || "";
    isEditing.value = true;
  }
};

onMounted(async () => {
  await getUserData();
  isLoading.value = false;
});
</script>

<style lang="scss">
@use "./SparPersonalData.scss";
</style>
