import {
  getCountriesParamsTypeEnum,
  type CountryList,
  type GetCountriesParamsTypeEnum,
} from "./occ.types";

export const CountryType = getCountriesParamsTypeEnum;
export type CountryType = GetCountriesParamsTypeEnum;

export type Countries = Record<CountryType, CountryList | undefined>;
