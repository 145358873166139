<template>
  <div
    class="checkbox"
    :class="{
      'checkbox--active': isChecked,
      'checkbox--disabled': disabled,
      'checkbox--required': required,
      'checkbox--invalid': !isValid,
    }"
  >
    <input
      :id="uniqueId"
      type="checkbox"
      :name="name"
      :value="val"
      :checked="isChecked"
      :disabled="disabled"
      :required="required"
      :data-tosca="getToscaPrefix('checkbox', toscaPrefix)"
      class="checkbox__input"
      :aria-describedby="legend ? `${uniqueId}-legend` : ariaDescribedby"
      @click="changeHandler"
    />
    <label
      class="checkbox__container"
      :for="uniqueId"
      :data-tosca="getToscaPrefix('checkbox-container', toscaPrefix)"
    >
      <span class="checkbox__checkmark" :data-tosca="getToscaPrefix('checkmark', toscaPrefix)">
        <spar-icon-sprite
          class="checkbox__icon"
          :class="{ 'checkbox__icon--active': isChecked }"
          symbol="check"
          :data-tosca="getToscaPrefix('checkbox-icon', toscaPrefix)"
        />
      </span>
      <span class="checkbox__label" :data-tosca="getToscaPrefix('checkbox-label', toscaPrefix)">
        <slot name="label-link" :text="label"></slot>
        <template v-if="!$slots['label-link']">
          {{ label }}
        </template>
      </span>
    </label>

    <div :id="`${uniqueId}-legend`" class="checkbox__legend-wrapper">
      <span v-if="legend" class="checkbox__legend">
        {{ legend }}
      </span>

      <span
        v-show="!isValid"
        class="checkbox__error-message"
        aria-live="polite"
        :data-tosca="getToscaPrefix('error-msg', toscaPrefix)"
      >
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import { useInputValue } from "~/composables/inputs/useInputValue";
import { getToscaPrefix } from "~/utils/ui";
import type { SparCheckboxProps } from "./SparCheckbox.types";

const props: SparCheckboxProps = defineProps({
  name: {
    type: String,
    default: null,
  },
  val: {
    type: String,
    required: true,
  },
  modelValue: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    required: true,
  },
  legend: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  ariaDescribedby: {
    type: String,
    default: undefined,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["update:modelValue", "interface", "onUnmount"]);

const { internalValue, errorMessage, isValid, uniqueId, validate, emitValue } = useInputValue(
  props,
  emit,
);

const isChecked = computed(() => {
  return props.val === props.modelValue;
});

const changeHandler = () => {
  if (internalValue.value === props.val) {
    internalValue.value = "";
  } else {
    internalValue.value = props.val;
  }

  validate();
  emitValue();
};
</script>

<style lang="scss">
@use "./SparCheckbox.scss";
</style>
