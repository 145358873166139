import type { InputInterface } from "~/composables/inputs/useInputValues.types";

export default function () {
  const children = ref([]) as Ref<InputInterface[]>;

  const resetAll = () => {
    children.value.forEach((child) => {
      child.reset();
    });
  };

  const validateAll = () => {
    const validations = children.value.map((child) => child.validate());
    return validations.every((isValid) => isValid);
  };

  const getChildInterface = (childInterface: InputInterface) => {
    children.value.push(childInterface);
  };

  return {
    getChildInterface,
    resetAll,
    validateAll,
  };
}
