import type {
  FlexibleForm,
  FlexibleFormFileUpload,
  FlexibleFormInputField,
  FlexibleFormSelection,
  FlexibleFormTextarea,
  StaticProps,
} from "@shop-storefront/utils";

type SparFlexibleFormPropsRaw = Omit<FlexibleForm, "component" | "title">;

export type FieldsType = SparFlexibleFormPropsRaw["fields"][number][];

export interface SparFlexibleFormProps extends SparFlexibleFormPropsRaw {
  fields: FieldsType;
}

export type Confirmation = SparFlexibleFormPropsRaw["confirmation"];
export type EmailConfiguration = SparFlexibleFormPropsRaw["emailConfiguration"];
export type FormConfiguration = SparFlexibleFormPropsRaw["formConfiguration"];

export const InputType = {
  Date: "Date",
  Email: "Email",
  Text: "Text",
} as const;
export type InputType = (typeof InputType)[keyof typeof InputType];

export const SelectionType = {
  Checkbox: "Checkbox",
  Dropdown: "Dropdown",
  Radio: "Radio",
} as const;
export type SelectionType = (typeof SelectionType)[keyof typeof SelectionType];

export const FieldContentType = {
  flexible_form_field_collection: "flexible_form_field_collection",
  flexible_form_selection: "flexible_form_selection",
  flexible_form_input_field: "flexible_form_input_field",
  flexible_form_file_upload: "flexible_form_file_upload",
  flexible_form_textarea: "flexible_form_textarea",
} as const;
export type FieldContentType = (typeof FieldContentType)[keyof typeof FieldContentType];

export type InputFieldsType = FieldFile | FieldInput | FieldSelection | FieldTextarea;

export interface FieldCollection extends StaticProps {
  headline?: string;
  fields: FieldCollectionField[];
}

export interface FieldCollectionField {
  field: Array<InputFieldsType>;
  width: null | string;
  metadata?: {
    uid: string;
  };
}

export type FieldFile = FlexibleFormFileUpload;
export type FieldInput = FlexibleFormInputField;
export type FieldSelection = FlexibleFormSelection;
export type FieldTextarea = FlexibleFormTextarea;
