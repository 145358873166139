export const RegistrationFields = {
  Email: "email",
  Password: "password",
  Password2: "password2",
  PrivacyConsent: "privacyConsent",
} as const;
export type RegistrationFields = (typeof RegistrationFields)[keyof typeof RegistrationFields];

export const DataGroups = {
  BasicData: "basicData",
  ConsentData: "consentData",
} as const;
export type DataGroups = (typeof DataGroups)[keyof typeof DataGroups];

interface FormFieldSettings {
  required: boolean;
  visible: boolean;
}

export type RegistrationDataGroupsConfig = {
  [key in DataGroups]: FormFieldSettings;
};

export type RegistrationFieldsConfig = {
  [key in RegistrationFields]: FormFieldSettings;
};
