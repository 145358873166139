<template>
  <div
    class="spar-select-autocomplete"
    :data-tosca="getToscaPrefix('select-autocomplete', toscaPrefix)"
  >
    <div class="spar-select-autocomplete__select">
      <div class="spar-select-autocomplete__input-btn">
        <spar-input
          ref="inputRef"
          v-model="searchTerm"
          role="combobox"
          class="spar-select-autocomplete__input"
          type="text"
          :label="label"
          aria-autocomplete="both"
          aria-controls="spar-select-autocomplete__list"
          :aria-expanded="showList.toString()"
          @input="searchTerm = $event.target.value"
          @blur="showList = false"
          @focus="showList = true"
          @click="showList = !showList"
          @keyup="keyUpHandler"
        />
        <spar-button
          icon="arrow-bottom"
          icon-only
          class="spar-select-autocomplete__btn"
          :class="[{ 'spar-select-autocomplete__btn--rotate': showList }]"
          :variant="ButtonVariant.custom"
          :aria-expanded="showList.toString()"
          tabindex="-1"
          @keyup="keyUpHandler"
          @click="showList = !showList"
        />
      </div>

      <ul
        v-show="showList"
        id="spar-select-autocomplete__list"
        class="spar-select-autocomplete__list"
        :aria-label="label"
        role="listbox"
      >
        <li
          v-for="(option, index) in matchingOptions"
          :key="index"
          :ref="
            (el) => {
              updateTemplateRefs(el as HTMLElement, index);
            }
          "
          class="spar-select-autocomplete__list-item"
          role="option"
          tabindex="-1"
          :aria-selected="ariaSelected(index)"
          @keyup="keyUpHandler"
          @click="handleClick(index)"
        >
          {{ option.value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from "@vueuse/core";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import { getToscaPrefix } from "~/utils/ui";
import type {
  SparSelectAutocompleteOption,
  SparSelectAutocompleteProps,
} from "./SparSelectAutocomplete.types";

const props: SparSelectAutocompleteProps = defineProps({
  label: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  options: {
    type: Array as PropType<SparSelectAutocompleteOption[]>,
    required: true,
  },
  modelValue: {
    type: String,
    default: "",
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const showList = ref(false);
const searchTerm = ref(props.modelValue);
const inputRef = ref<InstanceType<typeof SparInput>>();
const matchingOptions: Ref<SparSelectAutocompleteOption[]> = ref(props.options);
const templateRefs = ref<HTMLElement[]>([]);
const currentFocus = ref<number>();
const emit = defineEmits(["update:modelValue"]);

// This method ensured the correct order of the list elements. See https://vuejs.org/guide/essentials/template-refs.html#refs-inside-v-for
const updateTemplateRefs = (el: HTMLElement, idx: number) => {
  templateRefs.value[idx] = el;
};

onMounted(() => {
  // @ts-expect-error TS2590: Expression produces a union type that is too complex to represent
  onClickOutside(inputRef, () => {
    showList.value = false;
  });
});

const filterOptions = () => {
  if (searchTerm.value) {
    showList.value = true;
    matchingOptions.value = props.options.filter(
      (option) =>
        searchTerm.value && option.value.toLowerCase().includes(searchTerm.value.toLowerCase()),
    );
  } else {
    matchingOptions.value = props.options;
  }
  currentFocus.value = undefined;
};

const selectPrev = () => {
  const id =
    typeof currentFocus.value === "undefined"
      ? templateRefs.value.length - 1
      : currentFocus.value - 1;

  currentFocus.value = id >= 0 ? id : templateRefs.value.length - 1;
  searchTerm.value = matchingOptions.value[currentFocus.value].value;
  templateRefs.value[currentFocus.value]?.focus();
};

const selectNext = () => {
  const id = currentFocus.value === undefined ? 0 : currentFocus.value + 1;

  currentFocus.value = id < templateRefs.value.length ? id : 0;
  templateRefs.value[currentFocus.value]?.focus();
  searchTerm.value = matchingOptions.value[currentFocus.value].value;
};

const handleClick = (idx: number) => {
  searchTerm.value = matchingOptions.value[idx].value;
  showList.value = false;

  // Set focus back on input on selection
  if (inputRef.value) inputRef.value.focusInput();
  emit("update:modelValue", matchingOptions.value[idx].value);
};

const keyUpHandler = (e: KeyboardEvent) => {
  e.preventDefault();
  e.stopPropagation();

  switch (e.code) {
    case "ArrowUp":
      showList.value = true;
      selectPrev();
      break;

    case "ArrowDown":
      showList.value = true;

      if (currentFocus.value === -1) {
        currentFocus.value = undefined;
      } else {
        selectNext();
      }
      break;

    case "Tab":
      const id = templateRefs.value.findIndex((el) => el === e.target);
      if (id) currentFocus.value = id;
      showList.value = true;
      break;

    case "Enter":
      if (typeof currentFocus.value !== "undefined") {
        handleClick(currentFocus.value);
      } else {
        filterOptions();
      }
      break;

    case "Escape":
      searchTerm.value = "";
      if (showList.value === true) showList.value = false;

    default:
      filterOptions();
  }
};

const ariaSelected = (index: number) => {
  return currentFocus.value === index ? "true" : "false";
};
</script>

<style lang="scss">
@use "./SparSelectAutocomplete.scss";
</style>
