export const SparAlertType = {
  error: "error",
  info: "info",
  success: "success",
} as const;
export type SparAlertType = (typeof SparAlertType)[keyof typeof SparAlertType];

export interface SparAlertProps {
  type: SparAlertType;
  isClosable?: boolean;
  toscaPrefix?: string;
}
