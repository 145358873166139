export interface GigyaResponse {
  errorCode: number;
  errorDetailsCode?: number;
  errorMessage: string;
  id_token?: string;
  statusCode?: number;
  profile?: unknown;
  regToken?: string;
  status?: string;
  statusMessage?: string;
  isAvailable?: boolean;
  validationErrors?: GigyaValidationError[];
}

interface GigyaValidationError {
  errorCode: GigyaRegistrationResponseCode;
  message: string;
  fieldName: string;
}

export interface GigyaWindow {
  accounts: GigyaAccounts;
}

type GigyaCallback = (eventObj: GigyaResponse) => void;

type AddEventHandlersParams = {
  onLogin: GigyaCallback;
  onError: GigyaCallback;
};

type ResetPasswordParams = {
  loginID?: string;
  newPassword?: string;
  passwordResetToken?: string;
  callback: GigyaCallback;
};

type LoginParams = {
  loginID: string;
  password: string;
  ignoreInterruptions: boolean;
  callback: GigyaCallback;
  include: string;
  sessionExpiration?: number;
};

type LogoutParams = {
  callback: GigyaCallback;
};

type GetAccountInfoParams = {
  include?: string;
  callback: GigyaCallback;
};

type ResendVerificationCodeParams = {
  regToken: string;
  email?: string;
  callback: GigyaCallback;
};

type GigyaSession = {
  verify: (params: GigyaSessionVerifyParams) => void;
};

type GigyaSessionVerifyParams = {
  callback: GigyaCallback;
};

type InitRegistrationParams = {
  callback: GigyaCallback;
};

type RegisterParams = {
  email: string;
  password: string;
  regToken: string;
  finalizeRegistration?: boolean;
  ignoreInterruptions: boolean;
  regSource: string;
  data: {
    spar: {
      regOrigin: string;
      regContext: string;
    };
  };
  preferences: {
    terms: {
      websites: {
        isConsentGranted: boolean;
      };
    };
  };
  callback: GigyaCallback;
};

type SetAccountInfoParams = {
  password?: string;
  newPassword?: string;
  callback: GigyaCallback;
};

type FinalizeRegistrationParams = {
  regToken: string;
  callback: GigyaCallback;
};

type IsAvailableLoginIdParams = {
  loginID: string;
  callback: GigyaCallback;
};

interface GigyaAccounts {
  addEventHandlers: (params: AddEventHandlersParams) => void;
  getAccountInfo: (params: GetAccountInfoParams) => void;
  getSchema: (params: unknown) => void;
  initRegistration: (params: InitRegistrationParams) => void;
  login: (params: LoginParams) => void;
  logout: (params: LogoutParams) => void;
  register: (params: RegisterParams) => void;
  resendVerificationCode: (params: ResendVerificationCodeParams) => void;
  resetPassword: (params: ResetPasswordParams) => void;
  session: GigyaSession;
  setAccountInfo: (params: SetAccountInfoParams) => void;
  finalizeRegistration: (params: FinalizeRegistrationParams) => void;
  isAvailableLoginID: (params: IsAvailableLoginIdParams) => void;
}

export const GigyaRegistrationResponseCode = {
  PendingConfirmation: 206002, // Success
  PendingAutologinFinalization: 206005, // Email confirmed -> Auto login
  CaptchaFailed: 400021,
  LoginIdentifierExists: 403043, // email taken
  UniqueIdentifierExists: 400003, // also email taken
  Invalid: 403025, // Invalid token
  PasswordInvalid: 400006, // Password does not meet requirements
} as const;

export type GigyaRegistrationResponseCode =
  (typeof GigyaRegistrationResponseCode)[keyof typeof GigyaRegistrationResponseCode];
