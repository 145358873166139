import type { ExternalFetch } from "~/plugins/fetch";
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";
import { getMiddlwareUrl } from "~/utils/ui";
import type { ProductData, SparMdsaProduct } from "./mdsa.types";

export default class MdsaIntegration {
  private readonly externalFetch: ExternalFetch;
  private readonly runtimeConfig = useRuntimeConfig();

  constructor(externalFetch: ExternalFetch) {
    this.externalFetch = externalFetch;
  }

  public async searchMdsaProduct<T>(options: Record<string, unknown>) {
    return this.externalFetch<T>(
      getMiddlwareUrl(this.runtimeConfig) + servicesEndpoints.mdsa.product,
      options,
    );
  }

  public async getMdsaAdditionalServiceProduct(
    serviceId: string,
  ): Promise<{ products: SparMdsaProduct[] }> {
    // TODO change to $mdsa.searchMdsaProduct in the future
    const { data: products } = await $fetch<ProductData>(
      `https://mockoon-mdsa.apps.ocpt01.spar.local.at/mdsa/v3/AT/p/${serviceId}`,
    );

    return products;
  }
}
