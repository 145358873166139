<template>
  <span :class="iconClass" aria-hidden="true" :data-tosca="getToscaPrefix('svg', toscaPrefix)">
    <!--
      This is a temporary workaround to make SVGs work in Storybook.
      As soon as there is official support for Nuxt 3 in https://storybook.nuxtjs.org/
      we can switch back to a SVG Module
    -->
    <!-- <nuxt-icon :name="symbol" /> -->
    <svg>
      <use :xlink:href="`/icons.svg#${symbol}`" />
    </svg>
  </span>
</template>

<script lang="ts" setup>
import { getToscaPrefix } from "~/utils/ui";
import type { SparIconSpriteProps } from "./SparIconSprite.types";

const props: SparIconSpriteProps = defineProps({
  symbol: {
    type: String,
    required: true,
  },
  className: {
    type: String,
    default: null,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const iconClass = computed(() => {
  const res = ["svg-icon", `svg-icon--${props.symbol}`];
  if (props.className) {
    res.push(props.className);
  }
  return res;
});
</script>
