import type { NavigationElement } from "@shop-storefront/utils";
import type { NavigationNode } from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";

let activeNode: NavigationNode;
function setActiveMenu(navigationNode: NavigationNode) {
  activeNode = navigationNode;
}

export function getActiveMenu() {
  return activeNode;
}

export function resolveNavigationIndex(
  navigationIndex: NavigationElement,
  level = 1,
  levelParents: string[],
): NavigationNode {
  const target = navigationIndex.target || navigationIndex.pageLink?.[0].index?.target;

  const data = navigationIndex.pageLink?.[0];
  if (!data) {
    throw new Error();
  }

  return {
    level,
    categoryId: data.uid,
    label: (navigationIndex.label || data.index?.label) ?? "",
    title: (navigationIndex.label || data.index?.label) ?? "",
    target: target !== "_blank" ? "" : target,
    link: "url" in data && data.url ? data.url : "",
    categoryIconUrl: navigationIndex.icon || data.index?.icon,
    childCategories: [],
    levelPath: [...levelParents, data.uid],
    sections: navigationIndex.sections || [],
  };
}

export function resolveNavigationIndexes(
  navigationIndexes: NavigationElement[],
  parentUid: string | null = null,
  level = 0,
  activeMenuItem: string,
  parents: string[] = [],
): NavigationNode[] {
  ++level;

  const levelNavigationElements = navigationIndexes.filter(
    (navigationElement) =>
      navigationElement?.parentNavigationElement?.length === 0 ||
      navigationElement?.parentNavigationElement?.[0].uid === parentUid,
  );

  const nextLevelNavigationElements = navigationIndexes.filter(
    (navigationElement) => !levelNavigationElements.includes(navigationElement),
  );

  return levelNavigationElements.map((levelNavigationElement) => {
    const navigationTreeElement = resolveNavigationIndex(levelNavigationElement, level, parents);

    if (level < 6) {
      navigationTreeElement.childCategories = resolveNavigationIndexes(
        nextLevelNavigationElements,
        levelNavigationElement.uid,
        level,
        activeMenuItem,
        navigationTreeElement.levelPath,
      );

      if (navigationTreeElement.link === activeMenuItem) {
        setActiveMenu(navigationTreeElement);
      }

      return navigationTreeElement;
    } else {
      Log.warn(
        LogArea.navigation,
        `Navigation level ${levelNavigationElement.title} exceeded level ${level}`,
      );
      return navigationTreeElement;
    }
  });
}
