<template>
  <div
    v-if="uspList && uspList?.length >= 3"
    class="usp__container container"
    :data-cslp="cntsLp?.component?.['data-cslp']"
  >
    <div class="usp" :class="uspClass">
      <div
        v-for="(usp, colIndex) in uspList"
        :key="colIndex"
        class="usp__column"
        :class="`usp__column--${colIndex + 1}`"
      >
        <spar-usp v-bind="usp"></spar-usp>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparUsp from "~/components/shared/SparUsp/SparUsp.vue";
import type { SparUspProps } from "./SparUsp.types";

const props: SparUspProps = defineProps<SparUspProps>();

defineOptions({
  inheritAttrs: false,
});

const uspClass = computed(() => {
  return props.uspList?.length === 3 ? "usp--three" : "usp--four";
});
</script>

<style lang="scss">
@use "./SparUsp.scss";
</style>
