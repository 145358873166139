import { defineStore } from "pinia";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { MdsaError } from "~/utils/error";
import type { SparMdsaProduct } from "~/utils/mdsa/integration/mdsa.types";
import { resolveMdsaProduct } from "~/utils/mdsa/resolver/mdsa-product.resolver";

export const useMdsaStore = defineStore("mdsa", () => {
  const product = ref<SparMdsaProduct>();

  const { $mdsa } = useNuxtApp();

  const loadMdsaProduct = async (productId: string | string[]) => {
    try {
      const { data } = (await $mdsa.searchMdsaProduct({
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: {
          country: "at",
          productId,
        },
        key: `${ContentType.productPage}_product`,
      })) as { data: Ref<SparMdsaProduct> };

      if (data.value) {
        product.value = await resolveMdsaProduct(data.value);
      }
    } catch (error) {
      throw new MdsaError("Product error");
    }
  };

  return {
    loadMdsaProduct,
    product,
  };
});
