<template>
  <div class="product-page">
    <spar-pdp />
  </div>
</template>

<script lang="ts" setup>
import SparPdp from "~/components/feature/SparPdp/SparPdp.vue";
import { isClient } from "~/utils/ui";

const { loadMdsaProduct } = useMdsaStore();
const { loadProductPrice } = usePPCStore();
const { productPrice } = storeToRefs(usePPCStore());
const { loadProductEcomAvailability } = useLocationStore();

const {
  params: { productId },
} = useRoute();

await loadMdsaProduct(productId);

// On client the data loads onMounted
if (!isClient()) {
  await loadProductEcomAvailability(productId);
  await loadProductPrice(productId);
}

// Explicitly load live product price and ecomAvailability data again after hydration
onMounted(async () => {
  nextTick(async () => {
    await loadProductPrice(productId);
    await loadProductEcomAvailability(productId);
  });
});

onUnmounted(() => {
  productPrice.value = undefined;
});
</script>
