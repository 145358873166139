import MdsaIntegration from "~/utils/mdsa/integration/mdsa.integration";
import type { InternalFetch } from "../fetch";

export default defineNuxtPlugin(async ({ $internalFetch }) => {
  return {
    provide: {
      mdsa: new MdsaIntegration($internalFetch as InternalFetch),
    },
  };
});
