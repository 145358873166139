export interface SparSelectProps {
  modelValue?: string | number;
  label: string;
  labelPosition?: LabelPosition;
  legend?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  selectHint?: string;
  showHint?: boolean;
  options: SparSelectOption[];
  enableHintOption?: boolean;
  toscaPrefix?: string;
  ariaLabel?: string;
}

export interface SparSelectOption {
  key: string | number;
  value: string | number;
}

export type SparSelectOptions = SparSelectOption[];

export const LabelPosition = {
  above: "above",
  overlap: "overlap",
} as const;
export type LabelPosition = (typeof LabelPosition)[keyof typeof LabelPosition];
