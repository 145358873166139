<template>
  <div class="category container">
    <spar-breadcrumbs :breadcrumbs="breadcrumbs" tosca-prefix="plp" />
    <spar-plp />
  </div>
</template>

<script lang="ts" setup>
import queryString from "query-string";
import SparBreadcrumbs from "~/components/feature/SparBreadcrumbs/SparBreadcrumbs.vue";
import SparPlp from "~/components/feature/SparPlp/SparPlp.vue";
import { SearchMode } from "~/components/sections/SparHeader/SparSearchField/SparSearchField.types";
import { useSearchStore } from "~/stores/search.store";

const { $indexes } = useNuxtApp();
const { fullPath } = useRoute();
const searchStore = useSearchStore();
const { searchMode } = storeToRefs(useSearchStore());

const {
  params: { categoryId },
} = useRoute();

const breadcrumbs = $indexes.getBreadcrumbs(categoryId as string);

// TODO: Validate query params
// parseUrl automatically skips the FragmentIdentifier (#)
const queryObj = queryString.parseUrl(fullPath, {
  parseNumbers: true,
  arrayFormat: "comma",
}).query;
searchMode.value = SearchMode.products;
await searchStore.setQuery(queryObj);

// TODO: This avoids unexpected behaviour with old search results in the store
// It works, but needs a global approach in the future
onUnmounted(() => searchStore.resetResults("products"));
</script>

<style lang="scss">
@use "./category.scss";
</style>
