import type {
  RegistrationDataGroupsConfig,
  RegistrationFieldsConfig,
} from "~/components/feature/SparRegistration/SparRegistration.types";

export default function () {
  // TODO get country from base site id
  const country = "AT";

  const dataGroupsAT: RegistrationDataGroupsConfig = {
    basicData: { required: true, visible: true },
    consentData: { required: true, visible: true },
  };

  const dataGroupsSI: RegistrationDataGroupsConfig = {
    ...dataGroupsAT,
  };

  const dataGroupsConfig = {
    AT: dataGroupsAT,
    SI: dataGroupsSI,
  };

  const fieldsConfigAT: RegistrationFieldsConfig = {
    email: { required: true, visible: true },
    password: { required: true, visible: true },
    password2: { required: true, visible: true },
    privacyConsent: { required: true, visible: true },
  };

  const fieldsConfigSI: RegistrationFieldsConfig = {
    ...fieldsConfigAT,
  };

  const fieldsConfig = {
    AT: fieldsConfigAT,
    SI: fieldsConfigSI,
  };

  const getCountryDataGroupsConfig = (): RegistrationDataGroupsConfig => {
    return dataGroupsConfig[country] as RegistrationDataGroupsConfig;
  };

  const getFieldConfig = (): RegistrationFieldsConfig => {
    return fieldsConfig[country] as RegistrationFieldsConfig;
  };

  return {
    getCountryDataGroupsConfig,
    getFieldConfig,
  };
}
