import { useBaseSiteStore } from "~/stores/basesite.store";

/**
 * Check if a given string matches a base site (e.g. "national")
 */
export function validateBaseSite(baseSite: string) {
  const baseSiteStore = useBaseSiteStore();
  const baseSites = baseSiteStore.sapBaseSites?.map((baseSite) => baseSite.resolvedUid);
  if (!baseSites) return false;
  return baseSites.includes(baseSite);
}
