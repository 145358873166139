<template>
  <section
    class="text-image-video spar-section container"
    :class="{
      'text-image-video--full-width': fullWidth,
    }"
    :data-cslp="props.cntsLp?.component?.['data-cslp']"
  >
    <div v-if="leftSide?.mediaType" class="text-image-video__row">
      <spar-media-block v-bind="leftSide" :full-width="fullWidth"></spar-media-block>
    </div>

    <div v-if="rightSide?.mediaType" class="text-image-video__row">
      <spar-media-block v-bind="rightSide" :full-width="fullWidth"></spar-media-block>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { SparMediaBlockProps } from "~/components/shared/SparMediaBlock/SparMediaBlock.types";
import SparMediaBlock from "~/components/shared/SparMediaBlock/SparMediaBlock.vue";

const props = defineProps({
  leftSide: {
    type: Object as PropType<SparMediaBlockProps>,
    default: () => undefined,
  },
  rightSide: {
    type: Object as PropType<SparMediaBlockProps>,
    default: () => undefined,
  },
  cntsLp: {
    type: Object,
    default: () => {},
  },
});

const fullWidth = !(props.leftSide?.mediaType && props.rightSide?.mediaType);

defineOptions({
  inheritAttrs: false,
});
</script>

<style lang="scss">
@use "./SparTextImageVideo.scss";
</style>
