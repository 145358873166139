<template>
  <div class="socials-list">
    <div class="socials-list__title">{{ socialsTitle }}</div>
    <ul class="socials-list__items" role="menu">
      <li
        v-for="(social, index) in socialLinks"
        :key="index"
        role="none"
        class="socials-list__item"
        :class="isActive(index) ? 'socials-list__item--active' : ''"
      >
        <component
          :is="social.link?.length === 1 ? 'a' : 'button'"
          v-if="social.link"
          class="socials-list__item-link btn--clear"
          :aria-label="social.ariaLabel"
          :aria-pressed="getPressedState(social.link.length, index)"
          v-bind="getLinkBinding(social.link)"
          @click="hasMultipleLinks(social.link) ? openLinks(social, index) : null"
        >
          <spar-icon-sprite :symbol="social.icon || ''" />
        </component>

        <div
          v-if="social.link && hasMultipleLinks(social.link)"
          class="socials-list__item-list-wrapper"
        >
          <button
            type="button"
            class="socials-list__item-list-close btn--clear"
            @click="closeList()"
          >
            <spar-icon-sprite symbol="close" />
          </button>
          <ul class="socials-list__item-list" role="menu">
            <li v-for="(link, linkIndex) in social.link" :key="linkIndex" role="none">
              <spar-link
                class="socials-list__item-list-link"
                :title="social.label"
                :variant="LinkVariant.linkIcon"
                :aria-label="link.ariaLabel"
                :label="link.label"
                :link="link.externalLink"
                :target="link.target"
              />
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { GlobalLink } from "@shop-storefront/utils";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import { LinkVariant } from "~/components/shared/SparLink/SparLink.types";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import type {
  SparSocialsListProps,
  SparSocials,
  SparSocialLinks,
} from "~/components/shared/SparSocialsList/SparSocialsList.types";

defineProps({
  socialsTitle: {
    type: String,
    required: true,
  },
  socialLinks: {
    type: Array as PropType<SparSocialLinks>,
    default: () => [],
  },
}) as SparSocialsListProps;

const activeItem = ref<number | null>(null);

const openLinks = (item: SparSocials, index: number) => {
  if (item.link?.length === 1) {
    window.open(item.link[0].externalLink);
    return;
  }

  activeItem.value = index;
};

const getLinkBinding = (link: GlobalLink[]) => {
  return link.length === 1 ? { href: link[0].externalLink, target: link[0].target } : {};
};

const hasMultipleLinks = (link: GlobalLink[]) => {
  return link.length > 1;
};

const isActive = (index: number) => {
  return index === activeItem.value;
};

const getPressedState = (count: number, index: number) => {
  if (count <= 1) return null;

  return isActive(index).toString();
};

const closeList = () => {
  activeItem.value = null;
};
</script>

<style lang="scss">
@use "./SparSocialsList.scss";
</style>
