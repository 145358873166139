import { getUniqueId } from "~/utils/ui";
import { type Notification, NotificationTimeout, NotificationType } from "./SparNotification.types";

export { NotificationTimeout, NotificationType };

// Notification Stack
// Multiple notifications can accumulate, but only one is displayed at a time
const notifications: Ref<Notification[]> = ref([]);

export default function useNotification() {
  const dismissNotification = (id: string) => {
    notifications.value = notifications.value.filter((notification) => notification.id !== id);
  };

  const pushNotification = (
    text: string,
    type: NotificationType,
    autoHideTimeout?: number,
    toscaPrefix?: string,
  ) => {
    const id = getUniqueId("notification");
    const newNotification = {
      id,
      text,
      type,
      autoHideTimeout,
      toscaPrefix,
      dismiss: () => dismissNotification(id),
    };

    notifications.value.push(newNotification);
  };

  // Current Notification
  const notification = computed(() => {
    if (notifications.value.length) {
      return notifications.value[0];
    }
    return undefined;
  });

  return {
    pushNotification,
    notifications,
    notification,
  };
}
