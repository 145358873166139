import { useDebounceFn, useTimeoutFn } from "@vueuse/core";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import {
  SparQuantityButtonActionType,
  type SparQuantityButtonProps,
} from "~/components/shared/SparQuantityButton/SparQuantityButton.types";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import useI18n from "~/composables/i18n/useI18n";
import { useCartStore } from "~/stores/cart.store";
import { productSchemaV1SparProductTypeEnum } from "~/types/mdsa.types";
import { SparBaseStoreTypes } from "~/utils/mdsa/integration/mdsa.types";

export function useQuantitySelector(props: SparQuantityButtonProps) {
  const { getCartStores } = useBaseSites();
  const cartStores = getCartStores();
  const quantity = ref(0);
  const quantityUpdatedText = ref("");
  const quantityUpdating = ref(false);
  const { $t } = useI18n();
  const { pushNotification } = useNotification();
  const showSalesUnit = ref(true);

  // set tooltip value to "update" as default
  const buttonAction = ref<SparQuantityButtonActionType>(SparQuantityButtonActionType.update);

  // const isVoucher = props.product.sparProductType === productSchemaV1SparProductTypeEnum.Voucher;

  // currently fixed to national
  const baseStore = SparBaseStoreTypes.national;

  // get cart store
  // and check for product in cart to be able to prefill quantity beer bottles or beer box
  const cartStore: ReturnType<ReturnType<typeof useCartStore>> = cartStores[baseStore]();
  const lookedProduct = computed(() => cartStore.getLookedProduct(props.product.productId));

  // just for initial loading to prevent hydration problem and set values even if
  // rendering was on server side
  const isInitialized = ref(false);
  watch(
    () => lookedProduct.value,
    () => {
      if (!isInitialized.value && cartStore.productLookUp[props.product.productId]) {
        isInitialized.value = true;
        setQuantity();
      }
    },
  );

  function setQuantity() {
    if (props.product.sparProductType === productSchemaV1SparProductTypeEnum.Voucher) {
      if (lookedProduct.value?.deliveryMode === props.shopContext) {
        quantity.value = lookedProduct.value.quantity;
      }
    } else if (lookedProduct.value) {
      if (lookedProduct.value.isBomArticle && lookedProduct.value.cartEntries) {
        // cartpage and servicearticle - service articles are always listed separate in the cart
        // therefore we have to get the cartEntry from the lookedProduct
        if (props.cartEntryUpdate && props.entryNumber !== undefined) {
          quantity.value = lookedProduct.value.cartEntries[props.entryNumber]?.quantity || 0;
        } else {
          quantity.value = lookedProduct.value.bomSumUpQuantity;
        }
      } else {
        quantity.value = lookedProduct.value.quantity;
      }
    }
  }

  function checkMaxMin(newQuantity: number): number {
    const {
      minOrderQuantity,
      maxOrderQuantity,
      isBomArticle,
      cartEntries,
      bomSumUpQuantity,
      quantity,
    } = lookedProduct.value;

    // Helper function to set the tooltip based on the quantity
    const setTooltip = (quantity: number) => {
      if (quantity > maxOrderQuantity) {
        buttonAction.value = SparQuantityButtonActionType.maximum;
      } else if (quantity < minOrderQuantity) {
        buttonAction.value = SparQuantityButtonActionType.minimum;
      }
      setButtonToolTip();
    };

    let updateableAmount = null;

    if (isBomArticle) {
      // for bomArticle we have to check how many entries there are in the cart
      // use the sumUpQuantity to get the difference and update the cart quantity
      // we also have to differentiate if we are on a cartAction-Page or on f.e. plp, pdp etc
      const currentCartQuantity =
        props.cartEntryUpdate && cartEntries && props.entryNumber
          ? cartEntries[props.entryNumber].quantity
          : bomSumUpQuantity;

      const completeQuantityToUpdate = newQuantity - currentCartQuantity + bomSumUpQuantity;

      // Bound the quantity within the permissible min/max values
      const boundedQuantity = Math.max(
        minOrderQuantity,
        Math.min(maxOrderQuantity, completeQuantityToUpdate),
      );

      // Calculate the actually updateable amount depending on min and maxquantities
      updateableAmount =
        completeQuantityToUpdate - (completeQuantityToUpdate - boundedQuantity + bomSumUpQuantity);

      setTooltip(completeQuantityToUpdate);
    } else {
      // Bound the quantity within the permissible min/max values for non-BOM articles
      const boundedQuantity = Math.max(minOrderQuantity, Math.min(maxOrderQuantity, newQuantity));

      updateableAmount = boundedQuantity - quantity;

      setTooltip(newQuantity);
    }

    return updateableAmount;
  }

  const addOrUpdate = useDebounceFn(async (updateQuantity: number, selectedServices = []) => {
    let reduceQuantity = false;
    quantityUpdating.value = true;

    if (lookedProduct.value && Number(updateQuantity)) {
      const { isBomArticle } = lookedProduct.value;

      const checkedQuantity = checkMaxMin(updateQuantity);

      if (checkedQuantity < 0) {
        reduceQuantity = true;
      }

      // if nothing changed do nothing
      if (checkedQuantity === 0) {
        // check for service-article and set products cart-quantity
        let cartEntryQuantity = isBomArticle
          ? lookedProduct.value.bomSumUpQuantity
          : lookedProduct.value.quantity;

        if (props.cartEntryUpdate && props.entryNumber) {
          cartEntryQuantity =
            isBomArticle && lookedProduct.value.cartEntries
              ? lookedProduct.value.cartEntries[props.entryNumber].quantity
              : lookedProduct.value.quantity;
        }

        quantity.value = cartEntryQuantity;
        quantityUpdating.value = false;
        return;
      }

      updateQuantity = checkedQuantity;
    }

    // if product is already in the cart and we remove the complete product
    // reduce the whole quantity of the current cart quantity
    if (lookedProduct.value && Number(updateQuantity) === 0) {
      reduceQuantity = true;
      // reduce the quantity of the cart-product
      updateQuantity = lookedProduct.value.isBomArticle
        ? lookedProduct.value.bomSumUpQuantity
        : lookedProduct.value.quantity;

      if (props.cartEntryUpdate) {
        updateQuantity = 0;
      }

      // set tooltip value to "remove"
      buttonAction.value = SparQuantityButtonActionType.remove;
    }

    if (!lookedProduct.value) {
      // set tooltip value to "add" because there is not entry in the cart
      buttonAction.value = SparQuantityButtonActionType.add;
    }

    // on cartEntryUpdate (f.e. cart-page) we have to update the hybris-cart-entry by number
    // else perform addToCart (plp or pdp)
    if (props.cartEntryUpdate) {
      await updateCartQuantity(updateQuantity);
    } else {
      // on plp, pdp f.e.
      await addOrReduceProductToCart(updateQuantity, reduceQuantity, selectedServices);
    }
    // set quantity again to set the be-given value
    setQuantity();

    quantityUpdating.value = false;
    setButtonToolTip();
  }, 350);

  function setButtonToolTip() {
    const duration = 4000; // Tooltip display duration
    quantityUpdatedText.value = $t(`quantity.button.${buttonAction.value}`);

    useTimeoutFn(() => {
      quantityUpdatedText.value = "";
    }, duration);
  }

  async function updateCartQuantity(updateQuantity: number) {
    if (props.entryNumber === undefined) {
      return;
    }

    const newCartEntryQuantity =
      (lookedProduct.value.isBomArticle && lookedProduct.value.cartEntries
        ? lookedProduct.value.cartEntries[props.entryNumber].quantity
        : lookedProduct.value.quantity) + updateQuantity;

    try {
      if (!updateQuantity) {
        await cartStores[baseStore]().removeFromCart(props.entryNumber);
        await cartStores[baseStore]().validateCart();
        return;
      }
      await cartStores[baseStore]().updateCartItem(newCartEntryQuantity, props.entryNumber);
    } catch (e) {
      pushNotification(
        $t("quantity.button.error.generic"),
        NotificationType.Error,
        NotificationTimeout.Medium,
      );
    }
  }

  /**
   * Add or reduce product quantity in cart on PLP or PDP
   * Data is not given by SAP - updates will be performed by product-code not by entryNumer (as f.e. on cart-page)
   *
   * @param {number}  updateQuantity     new product quantity / difference
   * @param {boolean}  reduce     add or reduce product on plp or pdp
   * @param {string[]}  selectedServices   add selected services to product
   */
  async function addOrReduceProductToCart(
    updateQuantity: number,
    reduce = false,
    selectedServices: string[],
  ) {
    try {
      if (!reduce) {
        await cartStores[baseStore]().addToCart(
          props.product.productId,
          updateQuantity,
          props.shopContext,
          selectedServices,
        );
      } else {
        await cartStores[baseStore]().reduceCartProductQuantity(
          props.product.productId,
          Math.abs(updateQuantity),
          props.shopContext,
        );
      }
    } catch (error) {
      // set tooltip value to "error" and reset value
      quantityUpdating.value = false;
      buttonAction.value = SparQuantityButtonActionType.error;
      setButtonToolTip();
      quantity.value = lookedProduct.value.quantity;
    }
  }

  const valueWithSuffix = computed(() => {
    if (
      (props.product.sparProductType === productSchemaV1SparProductTypeEnum.Approximateweight ||
        props.product.sparProductType === productSchemaV1SparProductTypeEnum.Weighted) &&
      showSalesUnit.value
    ) {
      return `${quantity.value}${props.product.salesUnit}`;
    }

    return `${quantity.value}`;
  });

  const toggleSuffix = (showSuffix: boolean) => {
    showSalesUnit.value = showSuffix;
  };

  return {
    addOrUpdate,
    quantity,
    quantityUpdatedText,
    quantityUpdating,
    setQuantity,
    valueWithSuffix,
    toggleSuffix,
  };
}
