import { type ApplyRule, type Validations, validate } from "@shop-storefront/validation-rules";
import { useCheckoutStore } from "~/stores/checkout.store";
import { handleCommerceError } from "~/utils/error";
import { SparBaseStoreTypes } from "~/utils/mdsa/integration/mdsa.types";

export const useValidation = () => {
  const { sdk } = useVsfSdk();
  const { cartContext } = storeToRefs(useCheckoutStore());

  return async <T extends { [P in keyof T]: T[P] }>(
    obj: T,
    applyRule: ApplyRule<T, Validations>,
  ) => {
    try {
      const { data: validations } = (await sdk.commerce.getValidations(
        cartContext.value?.bs || SparBaseStoreTypes.national,
      )) as { data: Validations };
      return validate(obj, validations, applyRule);
    } catch (error) {
      handleCommerceError(error as Error);
      throw new Error("Error loading the validations");
    }
  };
};
