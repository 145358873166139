<template>
  <div class="checkout-delivery-groups">
    <h3>
      {{
        $t("checkout.step.delivery.groups.label", {
          groupsCount: deliveryOrderGroups.length.toString(),
        })
      }}
    </h3>
    <div
      v-for="(orderGroup, index) in deliveryOrderGroups"
      :key="index"
      class="checkout-delivery-group"
    >
      <div class="checkout-delivery-group__top">
        <div class="checkout-deliver-group__img">
          <spar-adaptive-image
            :src="orderGroup.deliveryMode?.icon?.url"
            :alt="orderGroup.deliveryMode?.name"
          ></spar-adaptive-image>
        </div>
        <div class="checkout-delivery-group__info">
          <div class="checkout-delivery__group-heading">
            {{ orderGroup.deliveryMode?.name }}
            {{ `(${index + 1}/${deliveryOrderGroups.length})` }}:
          </div>
          <div>
            {{ orderGroup.quantity }} {{ $t("checkout.step.delivery.address.article.label") }} |
            {{ orderGroup.deliveryTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import useI18n from "~/composables/i18n/useI18n";
import type { DeliveryOrderEntryGroup } from "~/types/occ.types";

const { $t } = useI18n();

defineProps({
  deliveryOrderGroups: {
    type: Array as PropType<Array<DeliveryOrderEntryGroup>>,
    required: true,
  },
});
</script>

<style lang="scss">
@use "./SparCheckoutDeliveryGroups.scss";
</style>
