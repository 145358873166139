<template>
  <div class="cart-overview__wrapper">
    <div class="cart-overview container">
      <div class="cart-overview__heading">
        <h5 class="cart-overview__title f-size--heading-2 f-style--heading-2">
          {{ $t("cart.title") }} {{ cart ? ` - "${cart.site}"` : "" }}
        </h5>
      </div>

      <div class="cart-overview__price-info">
        <div v-if="cart?.totalDiscounts?.value" class="cart-overview__discount">
          <span data-tosca="cart-discount-overview">{{ $t("cart.saving") }}</span>
          <span data-tosca="cart-discount-amount">{{ cart.totalDiscounts.formattedValue }}</span>
        </div>
        <div
          v-if="cart?.totalPriceWithoutDeliveryCost"
          class="cart-overview__price"
          data-tosca="cart-price"
        >
          <span>{{ $t("cart.overview.total.label") }}</span>
          <span data-tosca="cart-overview-total-price">{{
            cart.totalPriceWithoutDeliveryCost.formattedValue
          }}</span>
        </div>
        <!-- <div class="cart-overview__delivery-info">
          <button
            type="button"
            class="cart-overview__delivery-info-text btn--clear"
            @click="openDeliveryCosts = true"
          >
            {{ deliveryInfo.deliveryCostTitle }}
          </button>-->
        <!-- TODO replace with floating-ui, or similar -->
        <!-- <SparDeliveryCostsOverlay
            v-if="openDeliveryCosts"
            v-model="openDeliveryCosts"
            :delivery-info="deliveryInfo"
          ></SparDeliveryCostsOverlay>
        </div>-->
      </div>
      <div class="cart-overview__checkout">
        <spar-button
          data-tosca="cart-overview-to-checkout"
          class="cart-overview__checkout-link btn btn--primary"
          :disabled="!isValid"
          :variant="ButtonVariant.primary"
          icon="arrow-right"
          icon-position="right"
          role="link"
          @click="emit('goToCheckout')"
          >{{ $t("cart.checkout") }}</spar-button
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
//import SparDeliveryCostsOverlay from "~/components/feature/SparDeliveryCostsOverlay/SparDeliveryCostsOverlay.vue";
import type { SparDeliveryCostsOverlayProps } from "~/components/feature/SparDeliveryCostsOverlay/SparDeliveryCostsOverlay.types";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import type { Cart } from "~/types/occ.types";

const emit = defineEmits(["goToCheckout"]);

defineProps({
  cart: {
    type: Object as PropType<Cart>,
    required: true,
  },
  deliveryInfo: {
    type: Object as PropType<SparDeliveryCostsOverlayProps>,
    required: true,
  },
  isValid: {
    type: Boolean,
    required: true,
  },
});

//const openDeliveryCosts = ref(false);
</script>

<style lang="scss">
@use "./SparCartOverview.scss";
</style>
