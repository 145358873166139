import {
  type FactFinderCampaign,
  type SortedItem,
  type FactFinderFacets,
} from "~/utils/factfinder/integration/factfinder.types";
import type { SparMdsaProduct } from "~/utils/mdsa/integration/mdsa.types";

export const SearchCategory = {
  products: "products",
  recipes: "recipes",
  jobs: "jobs",
  locations: "locations",
  topics: "topics",
} as const;

export type SearchCategory = (typeof SearchCategory)[keyof typeof SearchCategory];

export type SearchTopicType = {
  title: string;
  description: string;
  slug: string;
  id?: string;
  image?: string;
};

export type SearchHits = {
  category: SearchCategory;
  totalHits: number;
  campaigns: FactFinderCampaign[];
  sortItems: SortedItem[];
  facets: FactFinderFacets[];
  isSuggestion: boolean;
  hits: (SparMdsaProduct | SearchTopicType)[];
  loadMore: boolean;
};
