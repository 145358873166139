<template>
  <div class="plp-facets">
    <div ref="facetsRef" class="plp-facets__facets-wrapper">
      <div
        v-for="facet in facets"
        :key="facet.name"
        class="plp-facets__facet"
        :class="{ 'plp-facets__facet--active': facet.name === activeFacet?.name }"
      >
        <spar-button
          class="plp-facets__facet-title"
          :icon="facet.name === activeFacet?.name ? 'arrow-top' : 'arrow-bottom'"
          :variant="ButtonVariant.custom"
          :aria-label="facet.name"
          tosca-prefix="plp-facets-facet-title"
          @click="setActiveFacet(facet)"
        >
          {{ facet.name }}
        </spar-button>

        <div v-if="facet.name === activeFacet?.name" class="plp-facets__facet-content">
          <div
            v-if="facet.filterStyle === FactFinderFilterStyle.multiselect"
            class="plp-facets__facet-elements plp-facets__facet-multiselect"
          >
            <spar-plp-multi-select
              :options="[...facet.selectedElements, ...facet.elements]"
              :name="facet.name"
              :filter-name="facet.associatedFieldName"
            ></spar-plp-multi-select>
          </div>

          <div
            v-if="facet.filterStyle === FactFinderFilterStyle.slider"
            class="plp-facets__facet-elements plp-facets__facet-slider"
          >
            <spar-plp-range-slider
              :options="[...facet.elements, ...facet.selectedElements][0]"
              :filter-name="facet.associatedFieldName"
              :unit="facet.unit"
            />
          </div>

          <spar-button
            class="plp-facets__facet-show-products"
            :variant="ButtonVariant.primary"
            :aria-label="$t('plp.facets.show_products')"
            tosca-prefix="plp-facets-facet-title"
            @click="setActiveFacet(facet)"
          >
            {{ $t("plp.facets.show_products") }}
          </spar-button>
        </div>
      </div>
    </div>

    <spar-button
      v-if="showFacetsToggleButton"
      :variant="ButtonVariant.link"
      icon="placeholder"
      class="plp-facets__show-filter-button"
      @click="showAllFacets()"
      >{{ $t("plp.facets.show_all") }}</spar-button
    >
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from "@vueuse/core";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { useSearchStore } from "~/stores/search.store";
import {
  type FactFinderFacets,
  FactFinderFilterStyle,
} from "~/utils/factfinder/integration/factfinder.types";
import SparPlpMultiSelect from "./SparPlpMultiSelect/SparPlpMultiSelect.vue";
import SparPlpRangeSlider from "./SparPlpRangeSlider/SparPlpRangeSlider.vue";

const { searchResults } = storeToRefs(useSearchStore());
const { $t } = useI18n();

// Initially visible facets
const visibleFacets = 5;

const facets = computed(() => {
  if (!searchResults.value.products?.facets) return [];

  if (isShowAllFacets.value) return searchResults.value.products.facets;
  else return searchResults.value.products.facets.slice(0, visibleFacets);
});

// Show button only if required
const showFacetsToggleButton = computed(() => {
  if (!searchResults.value.products?.facets) return false;
  return searchResults.value.products.facets.length > visibleFacets;
});

const activeFacet = ref<FactFinderFacets>();
const facetsRef = ref(null);
const isShowAllFacets = ref(false);

onClickOutside(facetsRef, () => (activeFacet.value = undefined));

const setActiveFacet = (facet: FactFinderFacets) => {
  if (facet.name === activeFacet.value?.name) {
    activeFacet.value = undefined;
    return;
  }

  activeFacet.value = facet;
};

const showAllFacets = () => {
  isShowAllFacets.value = !isShowAllFacets.value;
};
</script>

<style lang="scss">
@use "./SparPlpFacets.scss";
</style>
