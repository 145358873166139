export const EcomAvailability = {
  buyable: "buyable",
  hidden: "hidden",
  searchable: "searchable",
} as const;
export type EcomAvailability = (typeof EcomAvailability)[keyof typeof EcomAvailability];

export const AvailabilityType = {
  T: "T",
  P: "P",
} as const;
export type AvailabilityType = (typeof AvailabilityType)[keyof typeof AvailabilityType];

export const OutletAvailability = {
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
} as const;
export type OutletAvailability = (typeof OutletAvailability)[keyof typeof OutletAvailability];

export interface EcomAvailabilityTypeItem {
  value: EcomAvailability;
  availabilityType: AvailabilityType;
}

export interface EcomAvailabilitySchema {
  _meta: {
    version: string;
    schema: string;
  };
  productId: string;
  availability: EcomAvailability;
  availabilityTypes: EcomAvailabilityTypeItem[];
}

export interface OutletAvailabilitySchema {
  productId: string;
  storeId: string;
  stock: number;
  availability: OutletAvailability;
}

export interface OutletsAvailabilitySchema {
  [k: string]: OutletAvailabilitySchema;
}
