import { isClient } from "~/utils/ui";
import { ErrorPageType, type RoutingError } from "./useErrorPage.types";

export function useErrorPage() {
  function show404Page(errorType: ErrorPageType = ErrorPageType.content, fullPath: string) {
    const notFoundStates: RoutingError = {
      url: fullPath,
      statusCode: 404,
      statusMessage: "Page Not Found",
      data: { errorType, client: isClient() },
    };

    showError(notFoundStates);
  }

  return {
    show404Page,
  };
}
