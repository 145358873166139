import { useAuthStore } from "~/stores/auth.store";

// Functions are called after a session check was performed
// (check if user is logged in AND if is logged in, if session is valid)
const executeWhenLoggedInChecked: Ref<(() => void)[]> = ref([]);

export default function useAuth() {
  const { isLoggedInChecked } = storeToRefs(useAuthStore());

  const executeWhenIsLoggedInChecked = (func: () => void) => {
    if (isLoggedInChecked.value) {
      func();
    } else {
      executeWhenLoggedInChecked.value.push(func);
    }
  };

  const executeWhenIsLoggedInCheckedHandler = () => {
    if (!executeWhenLoggedInChecked.value.length) return;
    executeWhenLoggedInChecked.value.forEach((func) => func());
  };

  watch(
    () => isLoggedInChecked.value,
    () => executeWhenIsLoggedInCheckedHandler(),
  );

  return { executeWhenIsLoggedInChecked };
}
