<template>
  <div v-if="validationAlerts" class="container">
    <div
      v-for="(error, index) in validationAlerts"
      :key="index"
      class="validation-alerts__error-item"
    >
      <spar-alert
        v-if="!hiddenAlerts.includes(index.toString())"
        :type="SparAlertType.error"
        :tosca-prefix="`cart-alert-${index}`"
        @close="hideAlert(index.toString())"
      >
        <div class="validation-alerts__error-group">
          {{
            $t(`cart.error.${index}`, {
              orderAmount: (error[0] ? error[0].quantity?.toString() : "") || "",
            })
          }}
        </div>
        <ul v-if="error[0].entry?.product" class="validation-alerts__error-list">
          <li
            v-for="(errorType, errorTypeIndex) in error"
            :key="errorTypeIndex"
            class="validation-alerts__error-list-item"
            :data-tosca="`cart-alert-error-list-${errorType.entry?.product?.code}`"
          >
            <spar-link
              :link="backToCartLink || `#product-${errorType.entry?.product?.code}`"
              data-tosca="cart-alert-link-product"
              class="validation-alerts__error-product link"
            >
              <span v-if="errorType.statusCode === 'minOrderQuantityNotExceeded'">
                {{
                  $t("cart.error.minOrderQuantityNotExceeded.product", {
                    product: errorType.entry?.product?.name?.toString() || "",
                    amount: errorType.quantityAdded?.toString() || "",
                    instead: errorType.quantity?.toString() || "",
                  })
                }}
              </span>
              <span v-else>
                {{ errorType.entry?.product?.name?.toString() }}
              </span>
            </spar-link>
          </li>
        </ul>
      </spar-alert>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SparAlertType } from "~/components/shared/SparAlert/SparAlert.types";
import SparAlert from "~/components/shared/SparAlert/SparAlert.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";

const props = defineProps({
  validationErrorsAndWarnings: {
    type: Object,
    required: true,
  },
  backToCartLink: {
    type: String,
    default: "",
  },
});

const hiddenAlerts: Ref<string[]> = ref([]);

const validationAlerts = computed(() => {
  return {
    ...props.validationErrorsAndWarnings.errors,
    ...props.validationErrorsAndWarnings.warnings,
  };
});

const hideAlert = (alert: string) => {
  hiddenAlerts.value.push(alert);
};
</script>

<style lang="scss">
@use "./SparValidationAlerts.scss";
</style>
