export const RobotIndex = {
  all: "all",
  noindex: "noindex",
} as const;
export type RobotIndex = (typeof RobotIndex)[keyof typeof RobotIndex];

export const RobotFollow = {
  follow: "follow",
  nofollow: "nofollow",
} as const;
export type RobotFollow = (typeof RobotFollow)[keyof typeof RobotFollow];

export interface PageSeoMeta {
  hid: string;
  name: string;
  content: string;
}

export interface PageSeoLink {
  rel: string;
  href: string;
}

export interface PageSeoScript {
  src: string;
  type: string;
}

export interface PageSeo {
  title: string;
  meta: PageSeoMeta[];
  link: PageSeoLink[];
  script: PageSeoScript[];
}
