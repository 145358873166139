<template>
  <div class="spar-plp">
    <div id="spar-plp__content" class="spar-plp__content">
      <div class="spar-plp__left">
        <!-- TODO -->
      </div>

      <div v-if="searchResults" class="spar-plp__right">
        <div class="spar-plp__mobile-actions">
          <!-- TODO -->
          <spar-button
            class="spar-plp__mobile-actions-button"
            :variant="ButtonVariant.clear"
            :icon-position="IconPosition.left"
            icon="placeholder"
          >
            LOREM IPSUM
          </spar-button>

          <spar-button
            class="spar-plp__mobile-actions-button"
            :variant="ButtonVariant.clear"
            :icon-position="IconPosition.left"
            icon="placeholder"
            :aria-label="$t('plp.facets.label')"
            tosca-prefix="plp-facets-show-mobile"
            @click="showSlideout = true"
          >
            {{ $t("plp.facets.label") }}
          </spar-button>

          <spar-slideout
            :title="$t('plp.facets.select_facets')"
            :is-visible="showSlideout"
            @close="showSlideout = false"
          >
            <spar-plp-facets-slideout @show-products="showSlideout = false" />
            <template #footer>
              <spar-button @click="showSlideout = false">{{
                $t("plp.facets.show_products")
              }}</spar-button>
            </template>
          </spar-slideout>
        </div>

        <div class="spar-plp__right-controls">
          <spar-plp-facets />
          <spar-plp-facets-selected />

          <div
            v-if="searchResults.products.resolvedProducts?.length"
            class="spar-plp__results-sorting"
          >
            <span aria-live="polite">
              <b>{{ resultRange.start }}-{{ resultRange.end }}</b> {{ $t("plp.results.of") }}
              <b>{{ searchResults.products.totalHits }} {{ $t("plp.results.articles") }}</b></span
            >
            <spar-plp-sorting
              v-if="searchResults.products.sortItems"
              :search-sorting="searchResults.products.sortItems"
            ></spar-plp-sorting>
          </div>
        </div>

        <!-- TODO: Loading Indicator, e.g. while changing page -->
        <div v-if="searchResults.products.resolvedProducts?.length" class="spar-plp__results">
          <spar-product-tile
            v-for="product in searchResults.products.resolvedProducts"
            v-bind="product"
            :key="product.productId"
          ></spar-product-tile>
        </div>
        <div v-else>
          <p>LOADING or ERROR 404<br />(TODO)</p>
        </div>

        <spar-pagination
          v-if="searchResults.products.resolvedProducts?.length"
          :current-page="currentPage"
          :page-count="pageCount"
          @set-page="setPage"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparPagination from "~/components/shared/SparPagination/SparPagination.vue";
import SparProductTile from "~/components/shared/SparProductTile/SparProductTile.vue";
import SparSlideout from "~/components/shared/SparSlideout/SparSlideout.vue";
import { useSearchStore } from "~/stores/search.store";
import { IconPosition } from "~/types/icon.types";
import { scrollToId } from "~/utils/ui";
import SparPlpFacets from "./SparPlpFacets/SparPlpFacets.vue";
import SparPlpFacetsSelected from "./SparPlpFacets/SparPlpFacetsSelected/SparPlpFacetsSelected.vue";
import SparPlpFacetsSlideout from "./SparPlpFacets/SparPlpFacetsSlideout/SparPlpFacetsSlideout.vue";
import SparPlpSorting from "./SparPlpSorting/SparPlpSorting.vue";

const searchStore = useSearchStore();
const { currentPage, searchResults } = storeToRefs(useSearchStore());
const showSlideout = ref(false);

// const { $indexes } = useNuxtApp();
// TODO: Check this function as soon as data is available!
// const subNavigation = $indexes.getSubNavigation(categoryId as string);

const pageCount = computed(() =>
  Math.ceil((searchResults.value.products.totalHits || 0) / searchStore.hitsPerPage),
);

const setPage = (page: number) => {
  if (page < 1 || page > pageCount.value) return;

  currentPage.value = page;
  searchStore.updateFilterUrl({
    page,
  });

  scrollToId("spar-plp__content");
};

const resultRange = computed(() => {
  const start = currentPage.value === 1 ? 1 : (currentPage.value - 1) * searchStore.hitsPerPage + 1;
  const end = start + (searchResults.value.products?.resolvedProducts?.length || 0) - 1;

  return {
    start,
    end,
  };
});
</script>

<style lang="scss">
@use "./SparPlp.scss";
</style>
