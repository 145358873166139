<template>
  <div class="forgotten-password container">
    <div role="alert">
      <p v-if="successMessage" data-tosca="forgotten-password-text">
        {{ successMessage }}
      </p>
      <spar-button
        v-if="successMessage"
        class="btn--secondary"
        tosca-prefix="forgotten-password-back2login"
        @click="goToLogin()"
        >{{ $t("auth.reset_password_form.action.back_to_login") }}</spar-button
      >
    </div>
    <form
      v-if="!successMessage"
      class="spar-form forgotten-password-form"
      novalidate
      @submit.prevent="onSubmit"
    >
      <div class="forgotten-password__section">
        <spar-heading level="2" :title="$t('auth.reset_password.title')" />

        <div class="forgotten-password__subtitle">{{ $t("auth.reset_password.subtitle") }}</div>
        <spar-input
          v-model="username"
          class="forgotten-password__input--email"
          :type="SparInputType.email"
          :label="$t('auth.login_form.fields.email')"
          required
          tosca-prefix="forgotten-password-email"
          @interface="getChildInterface"
        />

        <spar-alert
          v-if="error"
          :type="SparAlertType.error"
          :is-closable="false"
          class="passwordforgotten-form__error"
          data-tosca="forgotten-password-general-error-msg"
        >
          {{ errorMessage }}</spar-alert
        >

        <div v-if="!successMessage" class="forgotten-password__footer">
          <spar-button
            type="submit"
            :disabled="loading || !isGigyaReady"
            tosca-prefix="forgotten-password-sendmail"
            class="btn--primary"
            >{{ $t("auth.forgotten_password.submit") }}</spar-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { GigyaError } from "~/components/feature/SparLogin/SparLogin.types";
import { SparAlertType } from "~/components/shared/SparAlert/SparAlert.types";
import SparAlert from "~/components/shared/SparAlert/SparAlert.vue";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import { SparInputType } from "~/components/shared/SparInput/SparInput.types";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import type { GigyaResponse } from "~/composables/auth/gigya.types";
import useGigya from "~/composables/auth/useGigya";
import useI18n from "~/composables/i18n/useI18n";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";

const router = useRouter();
const { isGigyaReady } = useGigya();
const { $t } = useI18n();

const children: Ref<InputInterface[]> = ref([]);
const username = ref("");
const error = ref(0);
const successMessage = ref("");
const loading = ref(false);

// Mark fields in red color
const setFieldsValidation = (valid = true) => {
  children.value.forEach((child) => child.setForceInvalid(!valid));
};

const onSubmit = async () => {
  cleanupForm();

  const res = children.value.map((c) => c.validate());
  const isValid = res.every((v) => v);
  if (!isValid) return;

  loading.value = true;

  window.gigya.accounts.resetPassword({
    loginID: username.value,
    callback: resetPasswordCallback,
  });
};

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};

// Cleanup states before submit
const cleanupForm = () => {
  successMessage.value = "";
  error.value = 0;
  setFieldsValidation(true);
};

const resetPasswordCallback = (eventObj: GigyaResponse) => {
  loading.value = false;
  if (eventObj.errorCode !== 0) {
    error.value = eventObj.errorCode;
  } else {
    successMessage.value = $t("auth.forgotten_password.success");
  }
};

const errorMessage = computed(() => {
  switch (error.value) {
    case GigyaError.Invalid:
      return $t("auth.login_form.error.invalid_login");
    case GigyaError.NotFound:
      return $t("auth.login_form.error.invalid_user");
  }
  return $t("auth.login_form.error.general");
});

const goToLogin = () => {
  const { getPath } = useRoutes();
  router.push(getPath("login"));
};
</script>

<style lang="scss">
@use "./SparForgottenPassword.scss";
</style>
