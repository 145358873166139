<template>
  <div class="checkout-payment-provider-paypal">
    <spar-checkbox
      v-model="saveAccount"
      :val="checkedVal"
      :label="checkboxLabel"
      tosca-prefix="checkout-payment-paypal"
    />
  </div>
</template>

<script setup lang="ts">
import SparCheckbox from "~/components/shared/SparCheckbox/SparCheckbox.vue";
import useI18n from "~/composables/i18n/useI18n";

const props = defineProps({
  isSaved: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
});

const { $t } = useI18n();
const checkedVal = "checked";
const emit = defineEmits(["toggleSaved"]);

const saveAccount = ref(props.isSaved ? checkedVal : "");

const checkboxLabel = computed(() => {
  let str = $t("checkout.step.payment.paypal.save_account");
  if (props.label) {
    str += ` (${props.label})`;
  }
  return str;
});

watch(
  () => saveAccount.value,
  (value) => {
    emit("toggleSaved", value === checkedVal);
  },
);
</script>

<style lang="scss">
@use "./SparPayPal.scss";
</style>
