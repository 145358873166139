<template>
  <div class="content-page">
    <template v-if="content?.[0]?.topSections">
      <render-sections :content="content[0].topSections" />
    </template>

    <div class="container">
      <spar-registration-confirmation />
    </div>

    <template v-if="content?.[0]?.bottomSections">
      <render-sections :content="content[0].bottomSections" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { RegistrationConfirmationPage } from "@shop-storefront/utils";
import SparRegistrationConfirmation from "~/components/feature/SparRegistrationConfirmation/SparRegistrationConfirmation.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { registrationConfirmationPageReferences } from "~/utils/contentstack/constants/references.constants";

const { $contentstack } = useNuxtApp();
const { getSlug } = useRoutes();

const { data: content } = await useAsyncData("registrationConfirmationPage", async () => {
  return await $contentstack.getEntries<RegistrationConfirmationPage>({
    type: ContentType.registrationConfirmationPage,
    customQuery: {
      [ContentstackPageParams.slug]: getSlug("registrationConfirmation"),
    },
    includeReference: registrationConfirmationPageReferences as string[],
  });
});
</script>
