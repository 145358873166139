import useI18n from "~/composables/i18n/useI18n";
import {
  productSchemaV1AssetsInnerTypeEnum,
  type ProductSchemaV1AssetsInner,
  type ProductSchemaV1AssetsInnerTypeEnum,
} from "~/types/mdsa.types";
import { ProductDocumentType } from "~/utils/mdsa/integration/mdsa.types";

const { $t } = useI18n();

export function usePdpProduct() {
  const pictures: Ref<ProductSchemaV1AssetsInner[]> = ref([]);

  const setPictures = (productPictures: ProductSchemaV1AssetsInner[]) => {
    pictures.value = productPictures;
  };

  const filterPictures = (type: ProductSchemaV1AssetsInnerTypeEnum[]) => {
    return pictures.value.filter((picture) => (picture.type ? type.includes(picture.type) : false));
  };

  const productDocuments = computed(() =>
    filterPictures([productSchemaV1AssetsInnerTypeEnum.Document]),
  );
  const productImages = computed(() => filterPictures([productSchemaV1AssetsInnerTypeEnum.Image]));
  const productVideos = computed(() => filterPictures([productSchemaV1AssetsInnerTypeEnum.Video]));

  // position:pdf_datasheet -> pdf_datasheet
  const extractPictureType = (document: ProductSchemaV1AssetsInner) =>
    document.position?.replaceAll("position:", "");

  const filterProductDocuments = (documentType: ProductDocumentType) => {
    return productDocuments.value.filter((document) => {
      return extractPictureType(document) === documentType;
    });
  };

  // Get label for document type, based on JSON "position" property
  // position:pdf_datasheet -> pdf_datasheet -> pdp.product.documents.pdf_datasheet
  const getDocumentLabel = (document: ProductSchemaV1AssetsInner) => {
    const type = extractPictureType(document);
    if (!type) return "";
    return $t("pdp.product.documents." + type);
  };

  return {
    filterProductDocuments,
    filterPictures,
    getDocumentLabel,
    productDocuments,
    productImages,
    productVideos,
    setPictures,
  };
}
