import type {
  SparSelectAutocompleteOptions,
  SparSelectAutocompleteProps,
} from "./SparSelectAutocomplete.types";

export const marketOptions: SparSelectAutocompleteOptions = [
  { key: 1, value: "INTERSPAR St. Veit" },
  { key: 2, value: "INTERSPAR Klagenfurt" },
  { key: 3, value: "INTERSPAR Villach" },
  { key: 4, value: "Spar Villach" },
  { key: 5, value: "INTERSPAR Völkermarkt" },
  { key: 6, value: "EUROSPAR Wien" },
  { key: 7, value: "SPAR Friesach" },
  { key: 8, value: "SPAR Wernberg" },
  { key: 9, value: "INTERSPAR St. Veit" },
  { key: 10, value: "INTERSPAR Klagenfurt" },
  { key: 11, value: "INTERSPAR Villach" },
  { key: 12, value: "Spar Villach" },
  { key: 13, value: "INTERSPAR Völkermarkt" },
  { key: 14, value: "EUROSPAR Wien" },
  { key: 15, value: "SPAR Friesach" },
  { key: 16, value: "SPAR Wernberg" },
];

export const sparSelectAutocompleteMock: SparSelectAutocompleteProps = {
  label: "This is a select with dropdown list and search function",
  name: "SelectAutocomplete",
  options: marketOptions,
  modelValue: "",
};
