<template>
  <div class="content-page">
    <template v-if="content?.[0]?.topSections">
      <render-sections :content="content[0].topSections" />
    </template>

    <div class="container">
      <spar-forgotten-password />
    </div>

    <template v-if="content?.[0]?.bottomSections">
      <render-sections :content="content[0].bottomSections" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ForgottenPasswordPage } from "@shop-storefront/utils";
import SparForgottenPassword from "~/components/feature/SparForgottenPassword/SparForgottenPassword.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { forgottenPasswordPageReferences } from "~/utils/contentstack/constants/references.constants";

const { $contentstack } = useNuxtApp();
const { getSlug } = useRoutes();

const { data: content } = await useAsyncData("forgottenPasswordPage", async () => {
  return await $contentstack.getEntries<ForgottenPasswordPage>({
    type: ContentType.forgottenPasswordPage,
    customQuery: {
      [ContentstackPageParams.slug]: getSlug("forgottenPassword"),
    },
    includeReference: forgottenPasswordPageReferences as string[],
  });
});
</script>
