<template>
  <div v-if="facetsSelected.length" class="plp-facets-selected">
    <div class="plp-facets-selected__controls">
      <h3 class="plp-facets-selected__title">{{ $t("plp.facets.selected_facets") }}:</h3>
      <spar-button :variant="ButtonVariant.link" icon="trash" @click="removeAllFilters()">{{
        $t("plp.facets.reset_all")
      }}</spar-button>
    </div>
    <ul class="plp-facets-selected__chips">
      <!-- TODO: Replace Index in Key -> https://collaboration.spar.at/jira/browse/HYBS-17742 -->
      <li v-for="(facet, i) in facetsSelected" :key="i" class="plp-facets-selected__chip">
        {{ getLabel(facet.selectedElement, false) }}

        <spar-button
          icon-only
          :variant="ButtonVariant.custom"
          icon="close"
          class="plp-facets-selected__chip-button"
          :aria-label="
            $t('plp.facets.reset_single', { filter: getLabel(facet.selectedElement, false) })
          "
          @click="removeFacet(facet)"
        ></spar-button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { useSearchStore } from "~/stores/search.store";
import type { FactFinderFacet } from "~/utils/factfinder/integration/factfinder.types";
import { useFacets } from "../useFacets";

const { searchResults } = storeToRefs(useSearchStore());
const { removeSingleFilter, removeAllFilters } = useSearchStore();
const { getLabel } = useFacets();

interface SelectedFacet {
  associatedFieldName: string;
  selectedElement: FactFinderFacet;
}

const facetsSelected = computed(() => {
  const res: SelectedFacet[] = [];

  // Get only facets with selected === "TRUE" (omit IMPLICIT selects)
  const filtered = searchResults.value.products?.facets
    ?.map((facet) => {
      const selectedElements = facet.selectedElements?.filter((elem) => elem.selected === "TRUE");
      return {
        associatedFieldName: facet.associatedFieldName,
        selectedElements,
      };
    })
    .filter((facet) => facet.selectedElements?.length);

  // Create flat array with selected facets
  filtered?.forEach((facet) => {
    facet.selectedElements?.forEach((selectedElement) => {
      res.push({
        associatedFieldName: facet.associatedFieldName,
        selectedElement,
      });
    });
  });

  return res;
});

const removeFacet = (facet: SelectedFacet) => {
  removeSingleFilter(facet.associatedFieldName, facet.selectedElement);
};
</script>

<style lang="scss">
@use "./SparPlpFacetsSelected.scss";
</style>
