export interface Validation {
  fieldId: string;
  countryId?: string;
  regex: string;
  regexError: string;
  mandatory: boolean;
  mandatoryError: string;
}
export type Validations = Record<string, Validation>;
export type ApplyRule<T extends { [key: string]: string }, V extends Validations> =
  | Partial<Record<keyof T, keyof V>>
  | Array<keyof T>
  | ((key: keyof T, value: string) => Extract<keyof V, string> | boolean);

export interface Issue {
  key: string;
  value: string;
  message: string;
}
export class ValidationError extends Error {
  constructor(issues: Issue[]) {
    super();
    this.name = this.constructor.name;
    this.issues = issues;
  }
  issues: Issue[] = [];
}

const getValidation = (rule: string, validations: Validations): Validation => {
  if (rule in validations) {
    return validations[rule];
  }
  throw new Error(`Unknown validation rule: "${rule.toString()}"`);
};
const isIssue = (issue: Issue | undefined): issue is Issue => Boolean(issue);

const applyValidationRule = <T extends { [P in keyof T]: T[P] }, V extends Validations>(
  arg: ApplyRule<T, V>,
  key: keyof T,
  value: T[keyof T],
): string | false => {
  if (typeof arg === "function") {
    const rule = arg(key, value);
    return rule === true ? key.toString() : rule;
  }
  if (Array.isArray(arg)) {
    return arg.includes(key) ? key.toString() : false;
  }
  if (key in arg) return arg[key]?.toString() ?? false;
  return false;
};

export const check = (value: string, validation: Validation) => {
  const { regex, mandatory, regexError, mandatoryError } = validation;
  if (!value && mandatory) {
    throw new Error(mandatoryError);
  }
  const match = value.match(new RegExp(regex, "u"));
  if (!match) {
    throw new Error(regexError);
  }
  return value;
};

export const validate = <T extends { [P in keyof T]: T[P] }, V extends Validations = Validations>(
  obj: T,
  validations: V,
  applyRule?: ApplyRule<T, V>,
): T => {
  const issues = Object.entries(obj)
    .map(([key, value]): Issue | undefined => {
      const rule = applyRule
        ? applyValidationRule(applyRule, key as keyof T, value as T[keyof T])
        : key;
      if (!rule) return;
      if (typeof value !== "string") {
        throw new Error(`Value must be of type "string" found "${typeof value}"`);
      }
      const validation = getValidation(rule, validations);
      try {
        check(value, validation);
      } catch (err) {
        if (err instanceof Error) {
          return { key, value, message: err.message };
        }
      }
    })
    .filter(isIssue);
  if (issues.length) {
    throw new ValidationError(issues);
  }
  return obj;
};
