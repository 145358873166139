export const CheckoutStep = {
  Cart: "Cart",
  Delivery: "Delivery",
  Payment: "Payment",
  Summary: "Summary", // = place order
} as const;
export type CheckoutStep = (typeof CheckoutStep)[keyof typeof CheckoutStep];

interface CheckoutErrorConfig {
  errors: string[];
  warnings: string[];
  step: CheckoutStep;
}

export const checkoutErrorConfig: CheckoutErrorConfig[] = [
  {
    errors: [
      "minOrderQuantityNotExceeded",
      "maxOrderQuantityExceeded",
      "maxOrderAmountForProductTypeExceeded",
      "maxOrderWeightExceeded",
      "minOrderAmountNotExceeded",
      "unavailable",
      "noStock",
    ],
    warnings: ["deliveryCountryInvalid"],
    step: CheckoutStep.Cart,
  },
  {
    errors: ["deliveryCountryInvalid"],
    warnings: [],
    step: CheckoutStep.Delivery,
  },
  // Consent errors for CheckoutStep.Summary not handled here!
];
