export const servicesEndpoints = {
  // Middleware
  availability: {
    ecom: "/availability/ecom",
    outlet: "/availability/outlet",
    outlets: "/availability/outlets",
  },
  factfinder: {
    search: "/factfinder/search",
  },
  i18n: {
    getTranslations: "/i18n/getTranslations",
  },
  ipaper: {
    getPapers: "/ipaper/getPapers",
  },
  mail: {
    send: "/mail/send",
  },
  ppc: {
    prices: "/ppc/prices",
  },
  mdsa: {
    product: "/mdsa/product",
  },

  sapcc: {
    store: "/sapcc/store",
    stores: "/sapcc/stores",
  },

  // Nuxt server api
  version: {
    version: "/api/version",
  },
};
