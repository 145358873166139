import type { ProductSchemaNutrition } from "~/utils/mdsa/integration/mdsa.types";

export interface SparNutritionTableProps {
  nutrition: ProductSchemaNutrition;
}

export const NutritionTableKey = {
  nutrientBasisQuantity: "nutrientBasisQuantity",
} as const;
export type NutritionTableKey = (typeof NutritionTableKey)[keyof typeof NutritionTableKey];
