import ContentstackLivePreview from "@contentstack/live-preview-utils";
import { camelize } from "@shop-storefront/utils";
import type { GetContentParams } from "@vsf-enterprise/contentstack-sdk";
import { type LivePreviewQuery } from "contentstack";
import type { ContentstackPlugin } from "~/plugins/contentstack/contentstack.types";

export const onEntryChange = ContentstackLivePreview.onEntryChange;
interface GenericObj {
  [key: string]: string;
}

export interface CtnsLp {
  component?: { "data-cslp"?: string };
}

function renameKeys(node: object, keysMaps: GenericObj) {
  if (!node || typeof node !== "object") return node;
  if (Array.isArray(node)) return node.map((item): unknown => renameKeys(item, keysMaps));

  return Object.entries(node).reduce((result, [key, value]): object => {
    const newKey = keysMaps[key] || key;
    return {
      ...result,
      [newKey]: renameKeys(value, keysMaps),
    };
  }, {});
}

export default defineNuxtPlugin(async ({ $config }) => {
  const { sdk } = useVsfSdk();
  ContentstackLivePreview.init({
    editButton: {
      enable: true,
      position: "top-right",
    },
  });

  const getEntries = async <T>(params: GetContentParams): Promise<T> => {
    const { query } = useRoute();
    if (!params.locale) params.locale = $config.public.cntsDefaultLocale;
    if (query.live_preview) params.livePreviewQuery = query as unknown as LivePreviewQuery;

    let entries: object = await sdk.cms.getContent(params);
    if (query.live_preview) entries = renameKeys(entries, { $: "cntsLp" });

    return camelize(entries) as T;
  };

  return {
    provide: {
      contentstack: {
        getEntries,
      } as ContentstackPlugin,
    },
  };
});
