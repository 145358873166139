import type { RouteName } from "~~/configs/routes";

// Localized routes are injected from Contentstack by plugin
// Accessing the final route paths requires accessing the router instance
export function useRoutes() {
  const router = useRouter();

  const getRouteByName = (name: RouteName) => {
    const routes = router.getRoutes();
    return routes.find((route) => route.name === name);
  };

  // Return a path for a named route, e.g. for a link (-> "/login")
  const getPath = (name: RouteName, returnRaw = false) => {
    const route = getRouteByName(name);
    if (!route) return "/";

    // Return raw path, including route param definitions ("/leaflets/:region*")
    if (returnRaw) return route.path;

    // Return path without route param definitions ("/leaflets/")
    const chunks = route.path.split("/").filter((chunk) => chunk.substring(0, 1) !== ":");
    return chunks.join("/");
  };

  // Slugs in Contentstack do not contain slashes, but route paths from the router do
  // Return the route path without slashes (-> "login")
  const getSlug = (name: RouteName) => {
    const route = getRouteByName(name);
    if (!route) return;

    const path = route.path;
    return path.replaceAll("/", "");
  };

  return {
    getPath,
    getSlug,
  };
}
