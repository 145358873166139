<template>
  <section class="cart container">
    <spar-breadcrumbs :breadcrumbs="breadcrumbs" tosca-prefix="cart" />

    <div class="cart__sections">
      <template v-if="content?.[0]?.[0]?.topSections">
        <render-sections :content="content[0][0].topSections" />
      </template>
    </div>

    <spar-validation-alerts :validation-errors-and-warnings="cartStepErrorsAndWarnings" />

    <div class="cart__content-wrapper">
      <div class="cart-content cart__content-left">
        <spar-heading
          level="1"
          headline-style="1"
          class="cart__title heading--left"
          :title="$t('cart.title')"
        />

        <spar-loader v-if="!isLoggedInChecked" class="cart__loader" />

        <div v-else-if="cartContext?.cart" class="cart__entries">
          <div v-if="entriesByCategories?.length">
            <template v-for="category in entriesByCategories" :key="category.category">
              <spar-product-row
                v-for="(entry, index) in category.entries"
                :key="`row-${index}`"
                :entry="entry"
                :cart="cartContext.cart"
                :show-category-title="index === 0"
                :category="category.category"
              ></spar-product-row>
            </template>
          </div>
          <div v-else class="cart__empty-message" data-tosca="cart-empty-message">
            {{ $t("cart.checkout.empty") }}
          </div>
        </div>

        <div v-if="cartContext?.cart?.entries" class="cart__action-area">
          <spar-button
            data-tosca="cart-delete"
            class="btn--secondary"
            icon="close"
            :icon-position="IconPosition.left"
            :variant="ButtonVariant.custom"
            @click="isActiveOverlay = true"
            >{{ $t("cart.delete.btn") }}</spar-button
          >
        </div>

        <spar-overlay
          class="cart-overview__delete-overlay"
          :is-active="isActiveOverlay"
          :title="$t('cart.delete.overlay.title')"
          role="alertdialog"
          aria-describedby="cart-overview__delete-description"
          tosca-prefix="cart-overlay"
          @close="isActiveOverlay = false"
        >
          <div id="cart-overview__delete-description">
            {{ $t("cart.delete.overlay.description") }}
          </div>
          <div class="cart-overview__delete-area">
            <spar-button tosca-prefix="cart-overlay-yes" @click="handleDeleteCart()">{{
              $t("cart.delete.overlay.submit.btn")
            }}</spar-button>
            <spar-button tosca-prefix="cart-overlay-no" @click="isActiveOverlay = false">{{
              $t("cart.delete.overlay.close.btn")
            }}</spar-button>
          </div>
        </spar-overlay>
      </div>

      <div class="cart__content-right">
        <spar-cart-overview
          v-if="cartContext?.cart && content?.[0]?.[0]?.deliveryInfo"
          :cart="cartContext.cart"
          :delivery-info="content[0][0].deliveryInfo"
          :is-valid="isValid"
          class="cart__overview"
          @go-to-checkout="goToCheckout"
        />
      </div>
    </div>

    <div class="cart__sections">
      <template v-if="content?.[0]?.[0]?.bottomSections">
        <render-sections :content="content[0][0].bottomSections" />
      </template>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { CartPage } from "@shop-storefront/utils";
import SparBreadcrumbs from "~/components/feature/SparBreadcrumbs/SparBreadcrumbs.vue";
import type {
  CartStepErrorsAndWarnings,
  EntriesByCategories,
} from "~/components/feature/SparCart/SparCart.types";
import { CartCategory } from "~/components/feature/SparCart/SparCart.types";
import SparCartOverview from "~/components/feature/SparCart/SparCartOverview/SparCartOverview.vue";
import SparProductRow from "~/components/feature/SparCart/SparProductRow/SparProductRow.vue";
import SparValidationAlerts from "~/components/feature/SparCart/SparValidationAlerts/SparValidationAlerts.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparLoader from "~/components/shared/SparLoader/SparLoader.vue";
import SparOverlay from "~/components/shared/SparOverlay/SparOverlay.vue";
import useAuth from "~/composables/auth/useAuth";
import { useAuthStore } from "~/stores/auth.store";
import { useCheckoutStore } from "~/stores/checkout.store";
import { CheckoutStep } from "~/types/checkout.types";
import { IconPosition } from "~/types/icon.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { cartPageReferences } from "~/utils/contentstack/constants/references.constants";

const { $contentstack, $indexes } = useNuxtApp();
const router = useRouter();
const { loadCart, setContext } = useCheckoutStore();
const { cartContext } = storeToRefs(useCheckoutStore());
const { isLoggedInChecked } = storeToRefs(useAuthStore());
const { executeWhenIsLoggedInChecked } = useAuth();

const isActiveOverlay = ref(false);

const {
  params: { baseSite },
} = useRoute();

executeWhenIsLoggedInChecked(async () => {
  // fetch cartContext
  setContext(baseSite as string);
  await loadCart();
});

const breadcrumbs = computed(() => {
  return $indexes.getBreadcrumbs("bltc4188012f2a10f4c"); // Dummy breadcrumbs
});

const { data: content } = await useAsyncData(ContentType.cart, async () => {
  return await $contentstack.getEntries<CartPage[]>({
    type: ContentType.cart,
    includeCount: true,
    includeReference: cartPageReferences as string[],
  });
});

const handleDeleteCart = async () => {
  if (!cartContext.value) {
    return;
  }
  await cartContext.value.clearCart();
  isActiveOverlay.value = false;
};

const entriesByCategories = computed(() => {
  if (!cartContext.value?.cart?.entries) return [];
  return cartContext.value.cart.entries.reduce((accumulator: EntriesByCategories, entry) => {
    // This is a temporary fix until category data on QA is correct for each product
    const rootCategory = entry.product?.rootCategory;
    const code =
      rootCategory?.code &&
      rootCategory?.name &&
      rootCategory.code !== "not-assigned" &&
      rootCategory.name !== "not-assigned"
        ? rootCategory.code
        : CartCategory.unassigned;

    const idx = accumulator.findIndex((e) => e.category === code);
    if (idx >= 0) {
      accumulator[idx].entries.push(entry);
    } else {
      accumulator.push({ category: code, entries: [entry] });
    }
    return accumulator;
  }, []);
});

const goToCheckout = () => {
  if (isValid.value) {
    router.push({ name: "checkoutDelivery", params: { baseSite: cartContext.value?.bs } });
  }
};

const cartStepErrorsAndWarnings = computed((): CartStepErrorsAndWarnings => {
  if (!cartContext.value) return { errors: {}, warnings: {} };
  return cartContext.value.getCartStepErrorsAndWarnings(CheckoutStep.Cart);
});

const isValid = computed(() => {
  return Object.keys(cartStepErrorsAndWarnings.value?.errors).length === 0;
});
</script>

<style lang="scss">
@use "./SparCart.scss";
</style>
