<template>
  <form
    v-if="passwordResetToken"
    class="spar-form reset-password-form"
    novalidate
    @submit.prevent="onSubmit"
  >
    <h2>{{ $t("auth.reset_password_form.subtitle") }}</h2>

    <spar-input
      v-model="password"
      :type="SparInputType.password"
      :label="$t('auth.login_form.fields.password')"
      :minlength="8"
      required
      show-strength
      tosca-prefix="password-reset-new-password"
      @interface="getChildInterface"
    />

    <div role="alert">
      <p v-if="error" class="spar-form__error" data-tosca="password-reset-new-password-error-msg">
        {{ errorMessage }}
      </p>
    </div>

    <div role="alert">
      <p v-if="isSuccess" data-tosca="password-reset-success-msg">
        {{ $t("auth.reset_password_form.success") }}
      </p>
    </div>

    <p v-if="isLoginButtonVisible">
      <spar-button
        class="btn--primary"
        tosca-prefix="password-reset-back2login"
        @click="goToLogin()"
        >{{ loginButtonText }}</spar-button
      >
    </p>

    <p v-else>
      <spar-button
        type="submit"
        :disabled="loading || !isGigyaReady"
        class="btn--primary"
        tosca-prefix="password-reset-save-new-password-btn"
        >{{ $t("auth.reset_password_form.action.save") }}</spar-button
      >
    </p>
  </form>
  <div v-else>
    <p>{{ $t("auth.reset_password_form.error.invalid_link") }}</p>
  </div>
</template>

<script setup lang="ts">
// https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/eb93d538b9ae45bfadd9a8aaa8806753.html?locale=en-US
import type { RouteLocationRaw } from "vue-router";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { SparInputType } from "~/components/shared/SparInput/SparInput.types";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import type { GigyaResponse } from "~/composables/auth/gigya.types";
import useGigya from "~/composables/auth/useGigya";
import useI18n from "~/composables/i18n/useI18n";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";

const { isGigyaReady } = useGigya();
const { $t } = useI18n();
const route = useRoute();
const router = useRouter();
const { getPath } = useRoutes();

const Error = {
  Invalid: 403025, // Invalid token
  PasswordInvalid: 400006, // Password does not meet requirements
} as const;

const children: Ref<InputInterface[]> = ref([]);
const password = ref("");
const error = ref(0);
const isSuccess = ref(false);
const loading = ref(false);
const passwordResetToken = typeof route.query.pwrt === "string" ? route.query.pwrt : "";

// Cleanup states before submit
const cleanupForm = () => {
  isSuccess.value = false;
  error.value = 0;
};

const onSubmit = async () => {
  cleanupForm();

  const res = children.value.map((c) => c.validate());
  const isValid = res.every((v) => v);
  if (!isValid) return;

  loading.value = true;

  window.gigya.accounts.resetPassword({
    newPassword: password.value,
    passwordResetToken,
    callback: resetPasswordCallback,
  });
};

const resetPasswordCallback = (eventObj: GigyaResponse) => {
  loading.value = false;

  if (eventObj.errorCode !== 0) {
    error.value = eventObj.errorCode;
  } else {
    isSuccess.value = true;
    password.value = "";
  }
};

const errorMessage = computed(() => {
  switch (error.value) {
    case Error.Invalid:
      return $t("auth.reset_password_form.error.invalid_token");
    case Error.PasswordInvalid:
      return $t("auth.reset_password_form.error.invalid_password");
  }
  return $t("auth.reset_password_form.error.general");
});

const loginButtonText = computed(() => {
  if (error.value === Error.Invalid) {
    return $t("auth.reset_password_form.action.new_token");
  }

  return $t("auth.reset_password_form.action.back_to_login");
});

const goToLogin = () => {
  const newRoute: RouteLocationRaw = { path: getPath("login") };
  if (error.value === Error.Invalid) {
    newRoute.path = getPath("forgottenPassword");
  }

  router.push(newRoute);
};

const isLoginButtonVisible = computed(() => {
  return isSuccess.value || error.value === Error.Invalid;
});

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};
</script>

<style lang="scss">
@use "./SparResetPassword.scss";
</style>
