<template>
  <div class="pagination">
    <!-- TODO: When to show/hide some of the (indicator) buttons on small page counts (4, 5, 6)? -->

    <!-- Previous Page -->
    <spar-button
      class="pagination__button pagination__button--previous"
      :variant="ButtonVariant.secondary"
      :disabled="currentPage === 1"
      icon="arrow-left"
      :icon-position="IconPosition.left"
      @click="setPage(currentPage - 1)"
      >{{ $t("search.pagination.previous_page") }}</spar-button
    >

    <!-- First Page -->
    <spar-button
      v-if="currentPage > 1"
      :aria-label="getAriaLabel(1)"
      class="pagination__button"
      :variant="ButtonVariant.clear"
      @click="setPage(1)"
      >1</spar-button
    >

    <!-- Pages in between Indicator OR page number if small page count -->
    <spar-button
      v-if="currentPage > 3 && pageCount > 4"
      aria-hidden="true"
      class="pagination__button"
      :variant="ButtonVariant.clear"
      disabled
      >...</spar-button
    >
    <spar-button
      v-else-if="currentPage > 3"
      :aria-label="getAriaLabel(currentPage - 2)"
      class="pagination__button"
      :variant="ButtonVariant.clear"
      @click="setPage(currentPage - 2)"
      >{{ currentPage - 2 }}</spar-button
    >

    <!-- Previous Page -->
    <spar-button
      v-if="currentPage > 2"
      :aria-label="getAriaLabel(currentPage - 1)"
      class="pagination__button"
      :variant="ButtonVariant.clear"
      @click="setPage(currentPage - 1)"
      >{{ currentPage - 1 }}</spar-button
    >

    <!-- Current Page -->
    <spar-button
      :aria-label="getAriaLabel(currentPage)"
      class="pagination__button pagination__button--current"
      disabled
      >{{ currentPage }}</spar-button
    >

    <!-- Next Page -->
    <spar-button
      v-if="currentPage < pageCount - 1"
      :aria-label="getAriaLabel(currentPage + 1)"
      class="pagination__button"
      :variant="ButtonVariant.clear"
      @click="setPage(currentPage + 1)"
      >{{ currentPage + 1 }}</spar-button
    >

    <!-- Pages in between Indicator OR page number if small page count -->
    <spar-button
      v-if="currentPage < pageCount - 2 && pageCount > 4"
      aria-hidden="true"
      class="pagination__button"
      :variant="ButtonVariant.clear"
      disabled
      >...</spar-button
    >
    <spar-button
      v-else-if="currentPage < pageCount - 2"
      :aria-label="getAriaLabel(currentPage + 2)"
      class="pagination__button"
      :variant="ButtonVariant.clear"
      @click="setPage(currentPage + 2)"
      >{{ currentPage + 2 }}</spar-button
    >

    <!-- Last Page -->
    <spar-button
      v-if="currentPage !== pageCount"
      :aria-label="getAriaLabel(pageCount)"
      class="pagination__button"
      :variant="ButtonVariant.clear"
      @click="setPage(pageCount)"
      >{{ pageCount }}</spar-button
    >

    <!-- Next Page -->
    <spar-button
      class="pagination__button pagination__button--next"
      :variant="ButtonVariant.secondary"
      :disabled="currentPage === pageCount"
      icon="arrow-right"
      @click="setPage(currentPage + 1)"
      >{{ $t("search.pagination.next_page") }}</spar-button
    >
  </div>
</template>

<script setup lang="ts">
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import useI18n from "~/composables/i18n/useI18n";
import { IconPosition } from "~/types/icon.types";
import type { SparPaginationProps } from "./SparPagination.types";

const emit = defineEmits(["setPage"]);

defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  pageCount: {
    type: Number,
    required: true,
  },
}) as SparPaginationProps;

const { $t } = useI18n();

const setPage = (page: number) => {
  emit("setPage", page);
};

const getAriaLabel = (page: number) => {
  return $t("search.pagination.page") + " " + page;
};
</script>

<style lang="scss">
@use "./SparPagination.scss";
</style>
