<template>
  <section class="cookie-list" :data-cslp="cntsLp?.component?.['data-cslp']">
    <spar-heading v-if="heading" v-bind="heading"></spar-heading>
    <div class="cookie-list__content">
      <div :id="name"></div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useScript } from "unhead";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import type { SparCookieListProps } from "./SparCookieList.types";

const props: SparCookieListProps = defineProps<SparCookieListProps>();

// Load script only on Client side on every load of this component
const { $script } = useScript(props.apiLink, {
  trigger: "manual",
});

onMounted(() => {
  if (!props.apiLink) return;
  $script.load();
});

onUnmounted(() => {
  $script.remove();
});
</script>

<style lang="scss">
@use "./SparCookieList.scss";
</style>
