import PPCIntegration from "~/utils/ppc/integration/ppc.integration";
import type { InternalFetch } from "../fetch";

export default defineNuxtPlugin(async ({ $internalFetch }) => {
  return {
    provide: {
      ppc: new PPCIntegration($internalFetch as InternalFetch),
    },
  };
});
