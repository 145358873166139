<template>
  <nav class="breadcrumbs" :aria-label="$t('global.navigation.breadcrumbs.label')">
    <ul
      ref="breadcrumbRef"
      class="breadcrumbs__list"
      :data-tosca="getToscaPrefix('breadcrumblist', toscaPrefix)"
    >
      <li class="breadcrumbs__breadcrumb">
        <spar-link class="breadcrumbs__label" slug="/">
          {{ $t("global.navigation.breadcrumbs.home") }}
        </spar-link>
        <spar-icon-sprite class="breadcrumbs__separator" symbol="arrow-right" />
      </li>

      <li v-if="isFolded" class="breadcrumbs__breadcrumb">
        <spar-button
          :variant="ButtonVariant.custom"
          class="breadcrumbs__label breadcrumbs__label--toggle-button"
          @click="isFolded = !isFolded"
        >
          {{ $t("global.navigation.breadcrumbs.more") }}
        </spar-button>
        <spar-icon-sprite class="breadcrumbs__separator" symbol="arrow-right" />
      </li>

      <li
        v-for="(breadcrumb, index) in _breadcrumbs"
        :key="breadcrumb.categoryId"
        class="breadcrumbs__breadcrumb"
      >
        <span
          v-if="isLastIndex(index)"
          class="breadcrumbs__label"
          :class="{ 'breadcrumbs__label--last-item': isLastIndex(index) }"
        >
          {{ breadcrumb.label }}
        </span>

        <spar-link v-else class="breadcrumbs__label" :slug="breadcrumb.link">
          {{ breadcrumb.label }}
        </spar-link>

        <spar-icon-sprite
          v-if="!isLastIndex(index)"
          class="breadcrumbs__separator"
          symbol="arrow-right"
        />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import type { NavigationNode } from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import useI18n from "~/composables/i18n/useI18n";
import { getToscaPrefix } from "~/utils/ui";

const props = defineProps({
  breadcrumbs: {
    type: Array as PropType<NavigationNode[]>,
    required: true,
    default: () => [],
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const { $t } = useI18n();
const breadcrumbRef = ref(null);

const isFolded = ref<boolean>(props.breadcrumbs?.length > 2);

const _breadcrumbs = computed(() => {
  if (props.breadcrumbs?.length > 0 && isFolded.value) {
    const sliceIndex =
      props.breadcrumbs.length < 2 ? props.breadcrumbs.length - 1 : props.breadcrumbs.length - 2;

    return props.breadcrumbs.slice(sliceIndex);
  }

  return props.breadcrumbs;
});

function isLastIndex(index: number): boolean {
  return _breadcrumbs.value?.length === index + 1;
}

onClickOutside(breadcrumbRef, () => (isFolded.value = true));
</script>

<style lang="scss">
@use "./SparBreadcrumbs.scss";
</style>
