import type { SparHeadingProps } from "./SparHeading.types";

export const sparHeadingMock: SparHeadingProps = {
  heading: {
    level: "1",
    headlineStyle: "2",
    title: "We are spar",
    description: "The short description",
  },
};
