<template>
  <section class="location-details">
    <div class="container">
      <spar-breadcrumbs :breadcrumbs="breadcrumbs" tosca-prefix="location-details" />
    </div>
    <template v-if="content?.[0]?.topSections">
      <render-sections :content="content[0].topSections" />
    </template>

    <div class="container">Placeholder for Categories</div>

    <div class="container">Placeholder for Filtering</div>

    <template v-if="content?.[0]?.middleSections">
      <render-sections :content="content[0].middleSections" />
    </template>

    <div class="container">Placeholder for Locations from SAP</div>

    <div class="container">Placeholder for Google Maps and Routeplanner</div>

    <template v-if="content?.[0]?.bottomSections">
      <render-sections :content="content[0].bottomSections" />
    </template>

    <div class="container">Placeholder for List of Local Producers</div>
  </section>
</template>

<script setup lang="ts">
import type { LocationDetailsPage } from "@shop-storefront/utils";
import SparBreadcrumbs from "~/components/feature/SparBreadcrumbs/SparBreadcrumbs.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { locationDetailsPageReferences } from "~/utils/contentstack/constants/references.constants";
import { resolvePageSeo } from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.resolvers";

const { $contentstack, $indexes } = useNuxtApp();

const {
  params: { locationId },
  fullPath,
} = useRoute();

const { data: content } = await useAsyncData("locationDetailsPage", async () => {
  return await $contentstack.getEntries<LocationDetailsPage>({
    type: ContentType.locationDetailsPage,
    customQuery: {
      [ContentstackPageParams.locationId]: locationId,
    },
    includeReference: locationDetailsPageReferences as string[],
  });
});

if (!content.value?.length) {
  const { show404Page } = useErrorPage();
  show404Page(ErrorPageType.location, fullPath);
}

const breadcrumbs = computed(() => {
  return $indexes.getBreadcrumbs("bltc4188012f2a10f4c"); // Dummy breadcrumbs
});

if (content?.value?.[0]?.seo) {
  useHead(resolvePageSeo(content.value[0].seo));
}
</script>

<style lang="scss">
@use "./SparLocationDetails.scss";
</style>
