<template>
  <form class="spar-form" novalidate @submit.prevent="onSubmit">
    <spar-select
      v-model="salutation"
      label="Salutation"
      required
      :options="[
        { key: 1, value: 'Company' },
        { key: 2, value: 'Mr' },
        { key: 3, value: 'Ms' },
      ]"
      legend="Legend"
      @interface="getChildInterface"
    />

    <spar-select
      v-model="salutation"
      label="Salutation Disabled"
      disabled
      :options="[
        { key: 1, value: 'Company' },
        { key: 2, value: 'Mr' },
        { key: 3, value: 'Ms' },
      ]"
      @interface="getChildInterface"
    />

    <spar-input type="text" label="Name" required @interface="getChildInterface" />
    <spar-input type="text" label="Name Disabled" disabled @interface="getChildInterface" />
    <spar-input
      type="email"
      label="Email"
      required
      placeholder="gustl@grindstone.eh"
      @interface="getChildInterface"
    />
    <spar-input type="tel" label="Phone" @interface="getChildInterface" />
    <spar-input
      type="date"
      :min-date="19700101"
      :max-date="getTodayForMinMax()"
      label="Birthday"
      @interface="getChildInterface"
    />
    <spar-file-upload
      label="File"
      legend="Allowed types: pdf, jpg, png, gif"
      :accepted-file-extensions="acceptedFileTypes"
      :max-size="5 * 1024 * 1024"
      @interface="getChildInterface"
    />

    <spar-input
      type="number"
      label="Number"
      legend="Between 5 and 10"
      :min="5"
      :max="10"
      @interface="getChildInterface"
    />

    <fieldset>
      <spar-input
        v-model="password1"
        type="password"
        label="Password"
        required
        legend="6-16 characters; at least 1 uppercase, 1 lowercase, 1 num, 1 special character "
        show-strength
        :validators="[
          (input) =>
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(input) ||
            'Password requirements not fulfilled',
        ]"
        @interface="getChildInterface"
      />
      <spar-input
        v-model="password2"
        type="password"
        label="Repeat password"
        required
        :validators="[(input) => password1 === input || 'Passwords do not match']"
        @interface="getChildInterface"
      />
    </fieldset>

    <spar-textarea
      label="Long text"
      :minlength="5"
      :maxlength="100"
      placeholder="Click here to type"
      legend="Min 5 characters; max 100 characters"
      @interface="getChildInterface"
    />

    <spar-textarea
      label="Disabled"
      disabled
      placeholder="This is a disabled textarea"
      @interface="getChildInterface"
    />

    <fieldset>
      <legend>Please choose your preferred delivery mode</legend>
      <spar-radio
        v-for="deliveryOption in deliveryOptions"
        :key="deliveryOption.val"
        v-model="selectedDeliveryMode"
        :name="deliveryOption.name"
        :val="deliveryOption.val"
        :label="deliveryOption.label"
        :legend="deliveryOption.legend"
        :disabled="deliveryOption.disabled"
        @interface="getChildInterface"
      />
    </fieldset>
    <spar-checkbox
      v-model="checkboxValue"
      val="accepted"
      label="This is a disabled checkbox"
      disabled
      @interface="getChildInterface"
    />
    <spar-checkbox
      v-model="checkboxValue"
      val="accepted"
      label="I accept the terms and conditions"
      required
      legend="Legend"
      @interface="getChildInterface"
    />
    <spar-button type="submit" class="btn--primary">Submit</spar-button>
    <spar-button class="btn--secondary" @click="resetAll">Reset</spar-button>
  </form>
</template>

<script lang="ts" setup>
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparCheckbox from "~/components/shared/SparCheckbox/SparCheckbox.vue";
import SparFileUpload from "~/components/shared/SparFileUpload/SparFileUpload.vue";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import SparRadio from "~/components/shared/SparRadio/SparRadio.vue";
import SparSelect from "~/components/shared/SparSelect/SparSelect.vue";
import SparTextarea from "~/components/shared/SparTextarea/SparTextarea.vue";
import { getTodayForMinMax } from "~/composables/inputs/useInputValue";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";

const children = ref([]) as Ref<InputInterface[]>;

const salutation = ref("");

const password1 = ref("");
const password2 = ref("");

const selectedDeliveryMode = ref("home");
const deliveryOptions = [
  {
    name: "delivery",
    label: "Home Delivery",
    val: "home",
    legend: "Your order will be delivered to your home address.",
    disabled: false,
  },
  {
    name: "delivery",
    label: "Store Pickup",
    val: "pickup",
    legend: "Pick up your order at a Spar store.",
    disabled: false,
  },
  {
    name: "delivery",
    label: "Disabled Option",
    val: "disabled",
    legend: "This is a disabled option",
    disabled: true,
  },
];

const checkboxValue = ref("");

const resetAll = () => {
  children.value.forEach((child) => {
    child.reset();
  });
};

const onSubmit = () => {
  const res = children.value.map((child) => child.validate());
  const isValid = res.every((isValid) => isValid);
  if (isValid && confirm("Form submitted. Clear fields?")) {
    resetAll();
  }
};

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};

const acceptedFileTypes = ["jpg", "gif", "doc", "pdf", "txt"];
</script>
