<template>
  <div class="content-page container">
    <div class="check-payment">
      <spar-loader />
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparLoader from "~/components/shared/SparLoader/SparLoader.vue";
import usePaymentRedirect from "./usePaymentRedirect";
import type { PayOneQueryParams } from "./usePaymentRedirect.types";

const route = useRoute();
const { placeOrderAfterRedirectParams, urlParams } = usePaymentRedirect();

urlParams.value = route.query as unknown as PayOneQueryParams;
placeOrderAfterRedirectParams.value = {
  paymentId: urlParams.value.paymentId,
  redirectMessageAuthenticationCode: urlParams.value.RETURNMAC,
  cartId: urlParams.value.cartId,
};
</script>

<style lang="scss">
@use "./payment.scss";
</style>
