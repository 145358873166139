<template>
  <div
    class="hero"
    :class="{ container: !fullWidth }"
    :data-cslp="cntsLp?.component?.['data-cslp']"
  >
    <div ref="glideDiv" class="hero__content glide">
      <div class="hero__media-inner">
        <spar-slider
          class="hero__slider"
          :items-count="publishedItems.length"
          :fullwidth="true"
          :dots="true"
        >
          <spar-slider-item
            v-for="(slide, index) in publishedItems"
            :key="index"
            class="hero__slider-item"
          >
            <spar-link v-bind="slide.link" :icon="undefined">
              <div class="hero__media-img-wrapper">
                <spar-adaptive-image
                  v-if="slide.img?.asset"
                  class="hero__media-img"
                  :image="slide.img"
                />
              </div>
              <div class="hero__data-container">
                <p class="hero__headline">{{ slide.headline }}</p>
                <div
                  v-if="slide.link?.label"
                  class="hero__link btn btn--primary btn--icon btn--icon-right"
                >
                  {{ slide.link.label }}
                  <spar-icon-sprite class="link__icon" symbol="arrow-right" />
                </div>
              </div>
            </spar-link>

            <spar-adaptive-image
              v-if="slide.badgeImg?.asset && Object.keys(slide.badgeImg).length > 0"
              class="hero__badge-img"
              :image="slide.badgeImg"
            />
          </spar-slider-item>
        </spar-slider>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import SparSlider from "~/components/shared/SparSlider/SparSlider.vue";
import SparSliderItem from "~/components/shared/SparSlider/SparSliderItem/SparSliderItem.vue";
import type { SparHeroProps } from "./SparHero.types";

const props: SparHeroProps = defineProps<SparHeroProps>();

const publishedItems = computed(() => {
  return props.slides?.filter((slide) => slide.inProgress === false) || [];
});

defineOptions({
  inheritAttrs: false,
});
</script>

<style lang="scss">
@use "./SparHero.scss";
</style>
