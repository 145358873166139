import type { SparBaseSite } from "~/composables/base-sites/baseSites.types";
import { useBaseSiteStore } from "~/stores/basesite.store";
import { useCartStore } from "~/stores/cart.store";

export function useBaseSites() {
  const { sapBaseSites } = storeToRefs(useBaseSiteStore());

  function getCartStores(): Record<string, ReturnType<typeof useCartStore>> {
    if (!sapBaseSites.value) return {};

    return sapBaseSites.value.reduce(
      (acc: Record<string, ReturnType<typeof useCartStore>>, cur: SparBaseSite) => {
        if (cur.resolvedUid) {
          return {
            ...acc,
            [cur.resolvedUid]: useCartStore(cur.resolvedUid),
          };
        }
        return acc;
      },
      {},
    );
  }

  function replaceBaseSiteInRoute(route = "", baseSite: string): string {
    return route.replace(":baseSite", baseSite);
  }

  return {
    getCartStores,
    replaceBaseSiteInRoute,
  };
}
