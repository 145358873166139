import type { SparSocialsListProps } from "~/components/shared/SparSocialsList/SparSocialsList.types";

export const sparSocialsListMock: SparSocialsListProps = {
  socialsTitle: "Besuchen Sie uns auf:",
  socialLinks: [
    {
      label: "Facebook",
      icon: "facebook",
      link: [
        {
          label: "Lebensmittel",
          externalLink: "www.facebook.com/profil1",
        },
        {
          label: "Weinwelt",
          externalLink: "www.facebook.com/profil2",
        },
      ],
    },
    {
      label: "Instagram",
      icon: "instagram",
      link: [
        {
          label: "Weinwelt",
          externalLink: "www.instagram.com/profil1",
        },
      ],
    },
    {
      label: "Twitter",
      icon: "twitter",
      link: [
        {
          label: "Weinwelt",
          externalLink: "www.twitter.com/profil1",
        },
        {
          label: "Lebensmittel",
          externalLink: "www.twitter.com/profil2",
        },
      ],
    },
    {
      label: "YouTube",
      icon: "youtube",
      link: [
        {
          label: "Weinwelt",
          externalLink: "www.twitter.com/profil1",
        },
        {
          label: "Lebensmittel",
          externalLink: "www.twitter.com/profil2",
        },
      ],
    },
  ],
};
