<template>
  <div class="radio" :class="{ 'radio--disabled': disabled }">
    <div class="radio__wrapper">
      <input
        :id="uniqueId"
        class="radio__input"
        type="radio"
        :name="name"
        :value="val"
        :checked="isChecked"
        :disabled="disabled"
        :data-tosca="getToscaPrefix('radio', toscaPrefix)"
        :aria-describedby="legend ? `${uniqueId}-legend` : undefined"
        @change="changeHandler"
      />
      <label
        class="radio__label"
        :for="uniqueId"
        :data-tosca="getToscaPrefix('radio-label', toscaPrefix)"
      >
        {{ label }}
        <slot />
      </label>
    </div>
    <div v-if="legend" :id="`${uniqueId}-legend`" class="radio__legend">
      {{ legend }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useInputValue } from "~/composables/inputs/useInputValue";
import { getToscaPrefix } from "~/utils/ui";
import type { SparRadioProps } from "./SparRadio.types";

const props: SparRadioProps = defineProps({
  name: {
    type: String,
    default: null,
  },
  val: {
    type: [String, Number],
    required: true,
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  legend: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["update:modelValue", "interface", "onUnmount"]);

const { uniqueId } = useInputValue(props, emit);

const isChecked = computed(() => {
  return props.val === props.modelValue;
});

const changeHandler = () => {
  emit("update:modelValue", props.val);
};
</script>
<style lang="scss">
@use "./SparRadio.scss";
</style>
