<template>
  <div
    v-if="isActive"
    class="overlay"
    :class="overlayClasses"
    :role="role"
    aria-labelledby="popup-title"
    aria-modal="true"
  >
    <div ref="popup" class="overlay__popup">
      <div class="overlay__heading">
        <div v-if="title" id="popup-title" class="overlay__title">{{ title }}</div>
        <spar-button
          class="overlay__close btn--custom"
          icon="close"
          icon-only
          :variant="ButtonVariant.custom"
          :aria-label="$t('global.close')"
          :tosca-prefix="getToscaPrefix('close', toscaPrefix)"
          @click="emit('close')"
        />
      </div>
      <div class="overlay__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import useI18n from "~/composables/i18n/useI18n";
import { getToscaPrefix } from "~/utils/ui";
import type { SparOverlayProps } from "./SparOverlay.types";

const emit = defineEmits(["close"]);

const props: SparOverlayProps = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
  hasBackground: {
    type: Boolean,
    default: true,
  },
  role: {
    type: String,
    default: "dialog",
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const { $t } = useI18n(); // Explicit Import for Storybook
const popup = ref(null);

onMounted(() => {
  watch(
    () => props.isActive,
    (isActive) => {
      if (isActive) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
  );
});

// E.g. in case of an externally triggered redirect
onUnmounted(() => {
  document.body.style.overflow = "";
});

const overlayClasses = ref({
  "overlay--background": props.hasBackground,
});

onClickOutside(popup, () => emit("close"));
</script>

<style lang="scss">
@use "./SparOverlay.scss";
</style>
