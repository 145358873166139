import type { ImageTeaser, ImageTextTeaser, TextTeaser } from "@shop-storefront/utils";

type Teasers = Partial<TextTeaser & ImageTextTeaser & ImageTeaser>;

export type SparTeaserProps = Omit<Teasers, "component" | "title">;

export const SparTeaserWidthOptions = {
  regular: "regular",
  big: "big",
} as const;
export type SparTeaserWidthOptions =
  (typeof SparTeaserWidthOptions)[keyof typeof SparTeaserWidthOptions];

export interface SparTeaserWidth {
  width: SparTeaserWidthOptions;
}
