// useId() is not working in Storybook at the moment and must be mocked
// can be removed once useId() is working in Storybook
import { getUniqueId, isStorybook } from "~/utils/ui";

export default function getId(prefix?: string) {
  if (!isStorybook()) {
    return useId();
  } else {
    return getUniqueId(prefix || "default");
  }
}
