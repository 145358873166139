export const NotificationType = {
  Error: "error",
  Success: "success",
  Warning: "warning",
} as const;
export type NotificationType = (typeof NotificationType)[keyof typeof NotificationType];

export const NotificationTimeout = {
  Disabled: 0, // do not auto-hide the notification
  Short: 5,
  Medium: 10,
  Long: 15,
} as const;
export type NotificationTimeout = (typeof NotificationTimeout)[keyof typeof NotificationTimeout];

export interface Notification {
  id: string;
  text: string;
  type: NotificationType;
  autoHideTimeout?: number;
  toscaPrefix?: string;
  dismiss: () => void;
}
