import usePaymentModes from "~/composables/payment/usePaymentModes";
import { useAuthStore } from "~/stores/auth.store";
import { useCheckoutStore } from "~/stores/checkout.store";
import {
  type PayOneQueryParams,
  type PayPalQueryParams,
  PaymentStatus,
} from "./usePaymentRedirect.types";

export default function usePaymentRedirect() {
  const { isLoggedInChecked } = storeToRefs(useAuthStore());
  const { loadCart, placeOrderAfterRedirect, setContext } = useCheckoutStore();
  const { paymentCancelOrder, paymentError } = usePaymentModes();

  const urlParams = ref<PayOneQueryParams | PayPalQueryParams>();
  const placeOrderAfterRedirectParams = ref({
    paymentId: "",
    redirectMessageAuthenticationCode: "",
    cartId: "",
  });

  const placeOrder = async () => {
    // Check if all parameters are available
    if (
      !Object.values(placeOrderAfterRedirectParams.value).every((value) => value) ||
      !urlParams.value?.baseSiteId
    ) {
      paymentError();
      return;
    }

    const baseSite = urlParams.value.baseSiteId.split("-")[1]; // at-national -> national
    // TODO: Handle error if setting the base site and, as a result, loading the cart fails
    // (if base site is missing, redirecting to checkout/payment is not possible)
    // TODO: Handle error if payment already succeeded (loading the cart will fail)
    setContext(baseSite);
    await loadCart();
    try {
      await placeOrderAfterRedirect(
        placeOrderAfterRedirectParams.value.paymentId,
        placeOrderAfterRedirectParams.value.redirectMessageAuthenticationCode,
        placeOrderAfterRedirectParams.value.cartId,
      );
    } catch (error) {
      paymentError();
    }
  };

  watch(
    () => isLoggedInChecked.value,
    (value) => {
      if (value) {
        switch (urlParams.value?.paymentStatus) {
          case PaymentStatus.Success:
            placeOrder();
            break;
          case PaymentStatus.Cancel:
            paymentCancelOrder();
            break;
          default:
            paymentError();
            break;
        }
      }
    },
  );

  return {
    placeOrderAfterRedirectParams,
    urlParams,
  };
}
