<template>
  <div class="plp-facets__multiselect">
    <spar-input
      v-if="Object.keys(options).length > searchFieldThreshold || searchTerm"
      v-model="searchTerm"
      :placeholder="searchPlaceholder"
      class="plp-facets__multiselect-search"
      @input="handleSearchInput"
    />

    <ul class="plp-facets__multiselect-list" aria-multiselectable="true" role="listbox">
      <li
        v-for="option in optionsFiltered"
        :key="option.text"
        class="plp-facets__multiselect-element"
        role="option"
        :aria-selected="selectedOptions[option.text] ? 'true' : 'false'"
      >
        <spar-checkbox
          v-model="selectedOptions[option.text]"
          :val="option.text"
          :label="getLabel(option)"
          :tosca-prefix="`plp-facet-${option.text.toLowerCase().replace(/\s/g, '')}`"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from "@vueuse/core";
import SparCheckbox from "~/components/shared/SparCheckbox/SparCheckbox.vue";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import useI18n from "~/composables/i18n/useI18n";
import { useSearchStore } from "~/stores/search.store";
import type { Filters } from "~/utils/factfinder/createQuery";
import type { FactFinderFacet } from "~/utils/factfinder/integration/factfinder.types";
import { useFacets } from "../useFacets";

const props = defineProps({
  options: {
    type: Array as PropType<FactFinderFacet[]>,
    required: true,
  },
  name: {
    type: String,
    default: "",
  },
  filterName: {
    type: String,
    default: "",
  },
});

const searchStore = useSearchStore();
const { getLabel } = useFacets();
const { $t } = useI18n();

const searchTerm = ref("");
const searchTermDebounced = ref("");
// Show search field if options count reaches threshold
const searchFieldThreshold = 10;

// Set selected items when component is initialized
const selectedObj: Record<string, string> = {};
Object.values(props.options)
  .filter((value) => value.selected === "TRUE")
  .forEach((item) => (selectedObj[item.text] = item.text));
const selectedOptions: Ref<Record<string, string>> = ref(selectedObj);

const internalValue = computed(() => {
  const values = Object.values(selectedOptions.value);
  return values.filter((value) => value);
});

const searchPlaceholder = computed(() =>
  $t("plp.facets.multiselect.search", {
    facet: props.name,
  }),
);

const handleSearchInput = useDebounceFn(() => {
  searchTermDebounced.value = searchTerm.value;
}, 200);

const optionsFiltered = computed(() => {
  if (!searchTermDebounced.value) return props.options;

  return props.options.filter((option) =>
    option.text.toLowerCase().includes(searchTermDebounced.value.toLowerCase()),
  );
});

watch(
  () => internalValue.value,
  async (value) => {
    if (value !== undefined) {
      const facet: Filters = {
        [props.filterName]: value,
      };

      let removeFilter: string | null = null;
      if (!Object.entries(facet[props.filterName]).length) {
        removeFilter = props.filterName;
      }

      searchStore.updateFilterUrl(facet, removeFilter, true);
    }
  },
);
</script>
