import { useAuthStore } from "~/stores/auth.store";
import type { PaymentDetailsList } from "~/types/occ.types";
import { handleCommerceError } from "~/utils/error";
import { SparBaseStoreTypes } from "~/utils/mdsa/integration/mdsa.types";

interface PaymentDetailsListResult {
  data: PaymentDetailsList;
}

const paymentDetailsList = ref<PaymentDetailsList>();

export default function () {
  const { sdk } = useVsfSdk();
  const { getUserId } = useAuthStore();

  const getPaymentDetailsList = async (saved = true, onlyCreditCards = false) => {
    try {
      const { data } = (await sdk.commerce.getPaymentDetailsList(
        getUserId(),
        SparBaseStoreTypes.national,
        saved,
        onlyCreditCards,
      )) as PaymentDetailsListResult;
      paymentDetailsList.value = data;
    } catch (error) {
      handleCommerceError(error as Error);
    }
  };

  const deletePaymentDetails = async (paymentId: string) => {
    const deletedFromBackend = await deletePaymentDetailsFromBackend(paymentId);
    if (deletedFromBackend) {
      deletePaymentDetailsFromFrontend(paymentId);
    }
  };

  const deletePaymentDetailsFromBackend = async (paymentId: string) => {
    try {
      await sdk.commerce.deletePaymentDetails(getUserId(), paymentId, SparBaseStoreTypes.national);
      return true;
    } catch (error) {
      handleCommerceError(error as Error);
      return false;
    }
  };

  const deletePaymentDetailsFromFrontend = (paymentId: string) => {
    const paymentDetails = paymentDetailsList.value?.payments;
    if (paymentDetails !== undefined) {
      paymentDetails.forEach((paymentDetail, index) => {
        if (paymentDetail.id === paymentId) {
          paymentDetails.splice(index, 1);
        }
      });
    }
  };

  return {
    paymentDetailsList,
    getPaymentDetailsList,
    deletePaymentDetails,
  };
}
