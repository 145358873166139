import type { CartModification, OrderEntry } from "~/types/occ.types";

export type EntriesByCategories = {
  category: string;
  entries: OrderEntry[];
}[];

export type CartValidationErrors = {
  [key: string]: CartModification[];
};

export type CartStepErrorsAndWarnings = {
  errors: CartValidationErrors;
  warnings: CartValidationErrors;
};

export type SingleCartStepErrorsAndWarnings = {
  errors: string[];
  warnings: string[];
};

interface ProductLookUpCartEntry {
  [key: number]: { entryNumber: number; quantity: number };
}

export interface ProductLookUp {
  [key: string]: {
    cartEntries?: ProductLookUpCartEntry;
    quantity: number;
    maxOrderQuantity: number;
    minOrderQuantity: number;
    sparProductType: string;
    name2: string;
    entryNumber: number | undefined;
    isBomArticle: boolean;
    deliveryMode: string | undefined;
    bomSumUpQuantity: number;
  };
}

export const CartCategory = {
  unassigned: "unassigned",
};
