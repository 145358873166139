<template>
  <section
    v-if="formConfiguration"
    class="spar-section container"
    :data-cslp="cntsLp?.component?.['data-cslp']"
  >
    <div :class="formContainer">
      <spar-heading v-bind="formConfiguration.formHeading" />

      <p v-if="formConfiguration.formText">{{ formConfiguration.formText }}</p>

      <p v-if="formConfiguration.formTextSmall" class="contact-form__text-small">
        {{ formConfiguration.formTextSmall }}
      </p>

      <div v-if="isSent">
        <spar-alert :type="SparAlertType.success" :is-closable="false">{{
          confirmation?.successMessage
        }}</spar-alert>
      </div>

      <form v-else novalidate @submit.prevent="onSubmit">
        <spar-flexible-form-section
          v-for="section in sections"
          :key="section.uid"
          :section="section"
          :tosca-prefix="formConfiguration.formId"
          @interface="getChildInterface"
        />

        <div aria-live="polite">
          <p v-if="hasError" class="contact-form__error">
            {{ $t("form.validation.form_invalid") }}
          </p>
        </div>

        <div ref="turnstileElement" data-theme="light"></div>

        <spar-button :tosca-prefix="formConfiguration.formId" type="submit" class="btn--primary">{{
          $t("form.action.submit")
        }}</spar-button>

        <div v-if="hasSendError" class="contact-form__send-error">
          <spar-alert :type="SparAlertType.error" :is-closable="false">{{
            confirmation?.errorMessage
          }}</spar-alert>
        </div>

        <div class="contact-form__field">
          <p class="contact-form__legend">{{ $t("form.validation.required_legend") }}</p>
        </div>
      </form>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { SparAlertType } from "~/components/shared/SparAlert/SparAlert.types";
import SparAlert from "~/components/shared/SparAlert/SparAlert.vue";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import { scrollToElement } from "~/utils/ui";
import type { SparFlexibleFormProps } from "./SparFlexibleForm.types";
import SparFlexibleFormSection from "./SparFlexibleFormSection/SparFlexibleFormSection.vue";
import { useFlexibleForm } from "./useFlexibleForm";

const turnstileElement = ref(null);

const props = defineProps<SparFlexibleFormProps>();

const { getChildInterface, hasError, hasSendError, initForm, isSent, onSubmit, sections } =
  useFlexibleForm();
initForm(props);

const { renderTurnstile } = useTurnstile();

const formContainer = ref("contact-form__inner");

// Scroll back to form top to show success message after submit
watch(
  () => isSent.value,
  (isSent) => {
    if (isSent) {
      const formTop = document.getElementsByClassName(formContainer.value)[0] as HTMLElement;
      nextTick(() => scrollToElement(formTop));
    }
  },
);

onMounted(() => {
  renderTurnstile(turnstileElement.value);
});
</script>

<style lang="scss">
@use "../SparContactForm/SparContactForm.scss";
@use "./SparFlexibleForm.scss";
</style>
