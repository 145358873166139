<template>
  <div class="spar-section container">
    <spar-accordion
      v-for="(component, index) in content"
      :key="index"
      :content-id="`checkout-payment-content-${index}`"
      :label-id="`checkout-payment-label-${index}`"
      :level="2"
      :label="component.content[0].headline"
      :default-opened="component.content[0]?.showAsOpen"
      tosca-prefix="accordion-section"
      :data-cslp="cntsLp?.component?.['data-cslp']"
    >
      <template #content>
        <render-sections :content="component.content" />
      </template>
    </spar-accordion>
  </div>
</template>

<script lang="ts" setup>
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import SparAccordion from "~/components/shared/SparAccordion/SparAccordion.vue";
import type { SparAccordionProps } from "./SparAccordion.types";

const props: SparAccordionProps = defineProps<SparAccordionProps>();

const content = props.accordion?.map((accordion) => {
  return {
    content: [accordion],
  };
});

defineOptions({
  inheritAttrs: false,
});
</script>
