// This is required temporarily to make translations work in Storybook
// useNuxtApp() is not working in Storybook at the moment and must be mocked
// Can be removed once Nuxt i18n and Nuxt Storybook are working with Nuxt 3
// May not work in stores -> use const { $t } = useNuxtApp(); instead
import { isStorybook } from "~/utils/ui";

export default function useI18n() {
  const $t = (key: string, values: { [key: string]: string } = {}) => {
    if (!isStorybook()) {
      const { $t } = useNuxtApp();
      return $t(key, values);
    }

    return key;
  };

  return {
    $t,
  };
}
