<template>
  <spar-checkout-steps :active-steps="['0', '1']" />
  <div class="checkout-validation">
    <spar-validation-alerts
      :validation-errors-and-warnings="cartStepErrorsAndWarnings"
      :back-to-cart-link="backLink"
    />
  </div>
  <section class="checkout-content">
    <div v-if="cartContext?.deliveryModes?.deliveryModeTypes" class="checkout-delivery__modes">
      <spar-heading
        v-if="cartContext?.deliveryModes?.deliveryModeTypes?.length > 1"
        level="2"
        headline-style="2"
        class="heading--left"
        :title="$t('checkout.step.delivery.modes.title')"
      />
      <div class="checkout-delivery__modes-actions">
        <template
          v-for="(deliveryMode, index) in cartContext?.deliveryModes.deliveryModeTypes"
          :key="index"
        >
          <spar-button
            v-if="deliveryMode.code"
            :variant="ButtonVariant.custom"
            class="checkout-delivery__mode-button"
            :class="{ 'checkout-delivery__mode-button--selected': deliveryMode.selected }"
            :aria-pressed="deliveryMode.selected"
            :tosca-prefix="`checkout-delivery-mode-${deliveryMode.code.toLowerCase()}`"
            :disabled="!deliveryMode.isSelectable"
            :icon="deliveryMode.code === 'DELIVERY' ? 'parcel-delivery' : 'in'"
            :icon-position="IconPosition.left"
            @click="handleDeliveryModeSelection(deliveryMode)"
          >
            <span class="checkout-delivery__mode-info">
              <spar-heading
                level="3"
                headline-style="3"
                class="heading--left"
                :title="
                  $t(`checkout.step.delivery.modes.${deliveryMode.code.trim().toLowerCase()}.title`)
                "
              />
              <span class="checkout-delivery__mode-info-text">
                {{
                  $t(`checkout.step.delivery.modes.${deliveryMode.code.trim().toLowerCase()}.text`)
                }}
              </span>
            </span>
            <span
              v-if="deliveryMode.selected"
              class="checkout-delivery__mode-info-selected"
              :data-tosca="`checkout-delivery-mode-${deliveryMode.code.toLowerCase()}-selected`"
            >
              {{ $t("checkout.step.delivery.modes.mode.selected") }}</span
            >
          </spar-button>
        </template>
      </div>
    </div>

    <div v-if="cartContext?.cart?.deliveryAddress" class="checkout-delivery__address-block">
      <div class="checkout-delivery__address">
        <spar-address
          :address="cartContext.cart.deliveryAddress"
          :border="false"
          :heading="$t('checkout.step.delivery.address.label')"
          tosca-prefix="checkout-delivery"
        ></spar-address>
        <button
          class="checkout-delivery__address-edit btn--clear"
          :aria-label="$t('address.editable.label')"
          data-tosca="checkout-delivery-edit"
          @click="showAddressOverlay"
        >
          <spar-icon-sprite symbol="edit"></spar-icon-sprite>
        </button>
      </div>
    </div>
    <div v-else>
      <button
        type="button"
        class="checkout-delivery__new-wrapper"
        data-tosca="checkout-add-delivery-address"
        @click="showAddressOverlay"
      >
        <spar-icon-sprite symbol="plus" class-name="checkout-delivery__new-icon"></spar-icon-sprite>
        <span>{{ $t("address.delivery.new") }}</span>
      </button>
    </div>

    <spar-overlay
      :is-active="isAddressOverlayOpen"
      :title="$t('address.delivery.editable.label')"
      tosca-prefix="checkout-delivery"
      @close="hideAddressOverlay"
    >
      <spar-address-list
        v-if="isLoggedIn"
        :country-type="CountryType.Shipping"
        tosca-prefix="select-radio"
        @select="selectAddress"
        @set-address="replaceDeliveryAddress"
      />
      <spar-address-form
        v-else
        country-type="BILLING"
        :address="cartContext?.cart?.deliveryAddress"
        tosca-prefix="checkout-delivery"
        @submit="addDeliveryAddressToCart"
      />
    </spar-overlay>

    <div
      v-if="cartContext?.cart?.deliveryOrderGroups?.length"
      class="checkout-delivery__groups-block"
      data-tosca="checkout-delivery-methods"
    >
      <spar-checkout-delivery-groups
        :delivery-order-groups="cartContext?.cart?.deliveryOrderGroups"
      />
    </div>
  </section>

  <spar-checkout-proceed>
    <div class="checkout-delivery__proceed">
      <spar-checkout-overview
        :headline-title="$t('checkout.step.delivery.overview.title')"
        :delivery-cost="cartContext?.cart?.deliveryCost"
        :total-price-with-tax="cartContext?.cart?.totalPriceWithTax"
      />
      <div class="checkout-proceed__payment-btn">
        <spar-button
          role="link"
          type="button"
          class="checkout-proceed__btn"
          data-tosca="checkout-buy-button"
          icon="arrow-right"
          full-width
          :disabled="!isValid"
          @click="checkDeliveryStep"
        >
          {{ $t("checkout.step.delivery.payment.btn") }}
        </spar-button>
      </div>
    </div>
  </spar-checkout-proceed>

  <div class="checkout-proceed__btn-mobile-wrapper">
    <spar-button
      role="link"
      type="button"
      class="checkout-proceed__btn"
      data-tosca="checkout-buy-button-mobile"
      icon="arrow-right"
      full-width
      :disabled="!isValid"
      @click="checkDeliveryStep"
      >{{ $t("checkout.step.delivery.payment.btn") }}</spar-button
    >
  </div>
</template>

<script lang="ts" setup>
import { ValidationError } from "@shop-storefront/validation-rules";
import type { CartStepErrorsAndWarnings } from "~/components/feature/SparCart/SparCart.types";
import SparValidationAlerts from "~/components/feature/SparCart/SparValidationAlerts/SparValidationAlerts.vue";
import SparCheckoutDeliveryGroups from "~/components/feature/SparCheckout/SparCheckoutDeliveryGroups/SparCheckoutDeliveryGroups.vue";
import SparCheckoutOverview from "~/components/feature/SparCheckout/SparCheckoutOverview/SparCheckoutOverview.vue";
import SparCheckoutProceed from "~/components/feature/SparCheckout/SparCheckoutProceed/SparCheckoutProceed.vue";
import SparCheckoutSteps from "~/components/feature/SparCheckout/SparCheckoutSteps/SparCheckoutSteps.vue";
import SparAddress from "~/components/shared/SparAddress/SparAddress.vue";
import SparAddressForm from "~/components/shared/SparAddressForm/SparAddressForm.vue";
import SparAddressList from "~/components/shared/SparAddressList/SparAddressList.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import useNotification, {
  NotificationTimeout,
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import SparOverlay from "~/components/shared/SparOverlay/SparOverlay.vue";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import useI18n from "~/composables/i18n/useI18n";
import { useAuthStore } from "~/stores/auth.store";
import { useCheckoutStore } from "~/stores/checkout.store";
import { CheckoutStep } from "~/types/checkout.types";
import { CountryType } from "~/types/country.types";
import { IconPosition } from "~/types/icon.types";
import type { Address, DeliveryModeType } from "~/types/occ.types";
import { useValidation } from "~/utils/validation";

const { isLoggedIn } = storeToRefs(useAuthStore());

const { cartContext } = storeToRefs(useCheckoutStore());
const { checkForNextStep, loadCart } = useCheckoutStore();

const { pushNotification } = useNotification();
const { $t } = useI18n();
const validate = useValidation();
const { replaceBaseSiteInRoute } = useBaseSites();
const { getPath } = useRoutes();

const isAddressOverlayOpen = ref(false);
const selectedAddress = ref<Address>();

const replaceDeliveryAddress = async () => {
  if (!selectedAddress.value) return;
  await cartContext.value?.setDeliveryAddress(selectedAddress.value, true);
  selectedAddress.value = undefined;
  hideAddressOverlay();
};

/**
 * Call when address is selected in address list, to store the selection
 *
 * @param {Address} address
 */
function selectAddress(address: Address): void {
  selectedAddress.value = address;
}

/**
 * Set to delivery address to the cart and close the overlay
 * If not address is given, the function will check if an address has been selected from the address list
 *
 * @param {Address} [address]
 */
async function addDeliveryAddressToCart(address?: Address): Promise<void> {
  address = address ?? selectedAddress.value;
  if (!address) {
    hideAddressOverlay();
    return;
  }
  // Not sure we should handle validation here...
  try {
    const validatedAddress = await validate(address, (key) => {
      if (key === "line1") return "line1";
      if (key === "line2") return "line2";
      if (key === "postalCode") return `postalCode.${address?.country?.isocode}`;
      return false;
    });

    await cartContext.value?.setDeliveryAddress(validatedAddress);
    selectedAddress.value = undefined;
    hideAddressOverlay();
  } catch (err) {
    // TODO: Better error handling
    if (err instanceof ValidationError) {
      pushNotification(
        $t(err.issues[0].message),
        NotificationType.Error,
        NotificationTimeout.Medium,
        "checkout-delivery",
      );
      return;
    }
    throw err;
  }
}

function showAddressOverlay(): void {
  isAddressOverlayOpen.value = true;
}

function hideAddressOverlay(): void {
  isAddressOverlayOpen.value = false;
}

const checkDeliveryStep = () => {
  if (!checkForNextStep("checkoutDelivery")) {
    pushNotification(
      $t("checkout.step.delivery.error.next_step"),
      NotificationType.Error,
      NotificationTimeout.Medium,
      "checkout-delivery",
    );
    return;
  }

  if (cartContext.value) {
    navigateTo({ name: "checkoutPayment", params: { baseSite: cartContext.value.bs } });
  }
};

const handleDeliveryModeSelection = (deliveryMode: DeliveryModeType) => {
  if (!deliveryMode.selected && deliveryMode.code) {
    cartContext.value?.setDeliveryModeType(deliveryMode.code);
  }
};

// TODO for logged in user we usually want to set the default delivery address onmounted

const cartStepErrorsAndWarnings = computed((): CartStepErrorsAndWarnings => {
  if (!cartContext.value) return { errors: {}, warnings: {} };
  return cartContext.value.getCartStepErrorsAndWarnings(CheckoutStep.Delivery);
});

const isValid = computed(() => {
  return Object.keys(cartStepErrorsAndWarnings.value?.errors).length === 0;
});

const backLink = computed(() => {
  return cartContext.value?.bs
    ? replaceBaseSiteInRoute(getPath("cart", true), cartContext.value?.bs)
    : "";
});

onMounted(async () => {
  await loadCart();
  await cartContext.value?.getDeliveryModeTypes();

  /* if (!cartContext.value?.cart?.deliveryMode && cartContext.value?.deliveryModes) {
    await cartContext.value?.setDeliveryModeType(
      cartContext.value.deliveryModes.deliveryModeTypes[1].code
    );
  } */
});
</script>

<style lang="scss">
@use "./SparCheckoutDelivery.scss";
</style>
