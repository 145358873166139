export { LogType } from "@shop-storefront/utils";

export const LogArea = {
  app: "app", // Generic Area for multiple purposes
  auth: "auth",
  availability: "availability",
  buyBox: "buyBox",
  cart: "cart",
  mdsa: "mdsa",
  contact: "contact",
  contentstack: "contentstack",
  log: "log", // The logger itself
  navigation: "navigation",
  sapcc: "sapcc",
  search: "search",
  env: "env",
} as const;
export type LogArea = (typeof LogArea)[keyof typeof LogArea];

export type LogArgs = number | boolean | string | null | undefined | object | unknown;
