<template>
  <fieldset
    class="checkbox-radio-group"
    :class="{
      'checkbox-radio-group--invalid': !isValid,
      'checkbox-radio-group--required': required,
    }"
  >
    <legend class="checkbox-radio-group__label">{{ label }}</legend>
    <spar-radio
      v-for="(radio, index) in options"
      :key="index"
      v-model="model"
      v-bind="radio"
      @interface="handleChildInterface"
      @change="validate"
    />

    <div class="checkbox-radio-group__legend">
      <span v-show="!isValid" class="checkbox-radio-group__error" aria-live="polite">
        {{ errorMessage }}
      </span>

      <template v-if="legend && !$slots['legend']">{{ legend }}</template>
      <slot name="legend"></slot>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import type { SparRadioProps } from "~/components/shared/SparRadio/SparRadio.types";
import SparRadio from "~/components/shared/SparRadio/SparRadio.vue";
import { useInputValue } from "~/composables/inputs/useInputValue";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import type { SparRadioGroupProps } from "./SparRadioGroup.types";

const props: SparRadioGroupProps = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  legend: {
    type: String,
    default: null,
  },
  options: {
    type: Array as PropType<SparRadioProps[]>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "interface", "onUnmount"]);

const handleChildInterface = (childInterface: InputInterface) => {
  emit("interface", childInterface);
};

const { errorMessage, internalValue, isValid, validate } = useInputValue(props, emit);
const model = internalValue as Ref<string>; // Cast to string
</script>

<style lang="scss">
@use "../SparCheckboxGroup/SparCheckboxRadioGroup.scss";
</style>
