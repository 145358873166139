import type { NuxtError } from "#app";

export const ErrorPageType = {
  content: "content",
  product: "product",
  jobs: "jobs",
  partyservice: "partyservice",
  location: "location",
} as const;
export type ErrorPageType = (typeof ErrorPageType)[keyof typeof ErrorPageType];

export interface RoutingError extends Partial<NuxtError> {
  url: string;
  statusCode: number;
  statusMessage: string;
  message?: string;
  description?: string;
  data: {
    errorType: string;
    client: boolean;
  };
}
