<template>
  <NuxtLayout
    ref="layout"
    :class="{
      'mobile-menu--open': mobileMenuOpen && isMobileView,
      'is-mobile-view': isMobileView,
      'is-desktop-view': !isMobileView,
      'search-overlay--active': showSearchOverlay,
      'is-mounted': isMounted,
    }"
    data-tosca="app-container"
  >
    <NuxtPage />
    <ModalsContainer />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { ModalsContainer } from "vue-final-modal";
import useGigya from "~/composables/auth/useGigya";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import { useAuthStore } from "~/stores/auth.store";
import { useNavigationStore } from "~/stores/navigation.store";
import { useSearchStore } from "~/stores/search.store";
import { setLogConfig } from "~/utils/log";

const runtimeConfig = useRuntimeConfig();
const route = useRoute();
const { showSearchOverlay } = storeToRefs(useSearchStore());
const { mobileMenuOpen } = storeToRefs(useNavigationStore());
const { checkLogin } = useAuthStore();
const { getCartStores } = useBaseSites();
const cartStores = getCartStores();
const { isMobile } = useScreenInfo();
const { logVersion } = useVersion();
const layout = ref(null);
const isMobileView = ref(false);
const isMounted = ref(false); // Playwright helper (await hydration)

useResizeObserver(layout, () => {
  isMobileView.value = isMobile.value;
});

useGigya();

if (runtimeConfig.public.adobeLaunchScriptUrl) {
  useHead({
    script: [{ src: runtimeConfig.public.adobeLaunchScriptUrl }, { async: true }],
  });
}

// Set global config for logger
setLogConfig(runtimeConfig.public.log);

// Log Frontend and Middleware version
logVersion();

onMounted(async () => {
  isMounted.value = true;

  if (!route.meta.skipCheckLogin) {
    await checkLogin();
  }

  for (const store of Object.values(cartStores)) {
    try {
      await store().loadCart();
    } catch (e: unknown) {}
  }
});
</script>
