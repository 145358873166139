<template>
  <div
    class="quantity-button"
    :class="{
      'quantity-button--cart-hidden': cartEntryUpdate && !quantity,
      'quantity-button--is-updating': quantityUpdating,
      'quantity-button--disabled': !buyable,
    }"
  >
    <spar-tooltip
      v-if="quantityUpdatedText || quantityUpdating"
      class="quantity-button__tooltip"
      :tosca-prefix="`quantity-button-${getSanitizedIdString(quantityUpdatedText)}`"
      :position="TooltipPosition.left"
      :text="quantityUpdatedText"
      :show-loader="quantityUpdating && !quantityUpdatedText"
    >
    </spar-tooltip>

    <div class="quantity-button__actions">
      <!-- Decrease Quantity Button -->
      <spar-button
        v-if="quantity"
        :data-tosca="`${toscaPrefix}-decrease-quantity`"
        class="quantity-button__control quantity-button__control--decrease"
        :aria-controls="`itemQuantity${product.productId}`"
        :variant="ButtonVariant.custom"
        :aria-label="getAriaLabel('decrease')"
        :icon="quantity === boundaries.min ? 'trash' : 'minus'"
        icon-only
        @click="updateQuantity(Math.max(quantity - boundaries.min, 0), true, true)"
      />
      <!-- Quantity Input Field -->
      <input
        v-if="quantity"
        v-model="valueWithSuffix"
        :data-tosca="`${toscaPrefix}-input-quantity`"
        type="text"
        class="quantity-button__input"
        :aria-label="valueWithSuffix"
        @focusout="updateQuantity(($event.target as HTMLInputElement).value, true)"
        @focusin="toggleSuffix(false)"
        @keypress.enter="updateQuantity(($event.target as HTMLInputElement).value, true)"
      />
      <!-- Increase Quantity Button or Add to Cart Button -->
      <spar-button
        :data-tosca="quantity ? `${toscaPrefix}-increase-quantity` : `${toscaPrefix}-add-to-cart`"
        class="quantity-button__control--increase btn btn--primary"
        :class="{ 'qantity-button__control--add-to-cart': !quantity }"
        :variant="ButtonVariant.custom"
        :aria-controls="`itemQuantity${product.productId}`"
        :aria-label="getAriaLabel('increase')"
        @click="updateQuantity(quantity + boundaries.min, true, true)"
      >
        <span v-if="!quantity">
          <span v-if="props.layout === SparQuantityButtonLayout.small"
            ><spar-icon-sprite symbol="cart"></spar-icon-sprite
          ></span>
          <span v-else>{{ $t("quantity.button.add_to_cart.label") }}</span>
        </span>
        <spar-icon-sprite symbol="plus"></spar-icon-sprite>
      </spar-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import { TooltipPosition } from "~/components/shared/SparTooltip/SparTooltip.types";
import SparTooltip from "~/components/shared/SparTooltip/SparTooltip.vue";
import { productSchemaV1SparProductTypeEnum } from "~/types/mdsa.types";
import type { SparMdsaProduct } from "~/utils/mdsa/integration/mdsa.types";
import { getSanitizedIdString } from "~/utils/ui";
import SparButton from "../SparButton/SparButton.vue";
import SparIconSprite from "../SparIconSprite/SparIconSprite.vue";
import { SparQuantityButtonLayout, type SparQuantityButtonProps } from "./SparQuantityButton.types";

const props: SparQuantityButtonProps = defineProps({
  product: {
    type: Object as PropType<SparMdsaProduct>,
    required: true,
  },
  boundaries: {
    type: Object as PropType<{ min: number; max: number }>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    default: "",
  },
  layout: {
    type: String as PropType<SparQuantityButtonLayout>,
    default: SparQuantityButtonLayout.small,
  },
  shopContext: {
    type: String,
    default: "",
  },
  cartEntryUpdate: {
    type: Boolean,
    default: false,
  },
  entryNumber: {
    type: Number as PropType<number | undefined>,
    default: undefined,
  },
  buyable: {
    type: Boolean,
    default: true,
  },
});

const { $t } = useI18n(); // Explicit Import for Storybook

const {
  quantity,
  setQuantity,
  quantityUpdating,
  quantityUpdatedText,
  addOrUpdate,
  valueWithSuffix,
  toggleSuffix,
} = useQuantitySelector(props);

const updateQuantity = async (newQuantity: number | string, showSuffix = true, clicked = false) => {
  if (quantityUpdating.value || !props.buyable) return;

  let parsedQuantity = typeof newQuantity === "string" ? parseInt(newQuantity) || 0 : newQuantity;

  const {
    product: { sparProductType },
    boundaries,
  } = props;

  if (boundaries) {
    if (sparProductType === productSchemaV1SparProductTypeEnum.Weighted && clicked) {
      parsedQuantity = adjustWeightedQuantity(parsedQuantity);
    } else if (sparProductType === productSchemaV1SparProductTypeEnum.Approximateweight) {
      parsedQuantity = adjustApproximateWeightQuantity(parsedQuantity);
    }
  }

  quantity.value = parsedQuantity;
  toggleSuffix(showSuffix);

  // TODO handle this in a better way when business decisions are made
  try {
    await addOrUpdate(quantity.value, []);
  } catch (e) {
    quantity.value = 0;
  }
};

const adjustWeightedQuantity = (newQty: number) => {
  if (newQty > quantity.value) {
    const nextStep = Math.ceil(quantity.value / props.boundaries.min) * props.boundaries.min;
    const difference = nextStep - quantity.value;
    if (difference !== 0) newQty = quantity.value + difference;
  } else {
    newQty = Math.ceil(newQty / props.boundaries.min) * props.boundaries.min;
  }
  return newQty;
};

// special case for ApproximateWeight
const adjustApproximateWeightQuantity = (newQty: number) => {
  // round up to next min-step --> f.e. min: 300 --> input 350 --> should round up to 600
  let nextStep = Math.ceil(newQty / props.boundaries.min) * props.boundaries.min;
  if (nextStep !== props.boundaries.min) newQty = nextStep;
  return newQty;
};

const getAriaLabel = (action: "increase" | "decrease") => {
  const amount =
    action === "increase"
      ? quantity.value + props.boundaries.min
      : Math.max(quantity.value - props.boundaries.min, 0);
  return $t(`quantity.button.${action}.label`, {
    productName: props.product.name1 ?? "",
    amount: amount.toString(),
  });
};

setQuantity();
</script>

<style lang="scss">
@use "./SparQuantityButton.scss";
</style>
