<template>
  <div ref="sparSlider" class="spar-slider" :class="{ 'spar-slider--full-width': fullwidth }">
    <div ref="glideDiv" class="glide">
      <div class="spar-slider__track glide__track" data-glide-el="track">
        <ul
          class="spar-slider__slides glide__slides"
          :class="{ 'spar-slider__slides--full-width': fullwidth }"
        >
          <slot ref="slotContent" />
        </ul>

        <div v-if="itemsCount > 1 && showCount" class="spar-slider__counter">
          {{ activeSlide + 1 }} / {{ itemsCount }}
        </div>
      </div>
      <div v-if="itemsCount > 1" class="spar-slider__arrows glide__arrows" data-glide-el="controls">
        <spar-button
          class="spar-slider__arrow spar-slider__arrow--left glide__arrow glide__arrow--left btn"
          icon="arrow-left"
          icon-only
          :aria-label="$t('slider.prev')"
          data-glide-dir="<"
          :class="{ 'spar-slider__arrow--left--full-width': fullwidth }"
          tosca-prefix="slider-arrow-left"
        />
        <spar-button
          class="spar-slider__arrow spar-slider__arrow--right glide__arrow glide__arrow--right btn"
          icon="arrow-right"
          icon-only
          :aria-label="$t('slider.next')"
          data-glide-dir=">"
          :class="{ 'spar-slider__arrow--right--full-width': fullwidth }"
          tosca-prefix="slider-arrow-right"
        />
      </div>
      <div
        v-if="itemsCount > 1 && (dots || dotsAsImages)"
        class="spar-slider__dots-container"
        :class="{ 'spar-slider__dots--images': dotsAsImages && assets }"
      >
        <div class="spar-slider__dots glide__bullets" data-glide-el="controls[nav]">
          <template v-if="dotsAsImages && assets">
            <spar-button
              v-for="(asset, assetIndex) in assets"
              :key="assetIndex"
              class="spar-slider__dot-image-wrapper"
              :variant="ButtonVariant.custom"
              :class="{ 'glide__bullet--active': assetIndex === activeSlide }"
              :data-glide-dir="`=${assetIndex}`"
              :data-index="assetIndex"
            >
              <spar-adaptive-image
                class="spar-slider__dot-image spar-slider__dot glide__bullet"
                :src="asset.thumbnail"
                :alt="asset.alt"
              />

              <span
                v-if="asset.type && asset.type === productSchemaV1AssetsInnerTypeEnum.Video"
                class="spar-slider__dot-play btn--play"
              >
                <spar-icon-sprite symbol="play" />
              </span>
            </spar-button>
          </template>
          <template v-else>
            <spar-button
              v-for="index in itemsCount"
              :key="index"
              class="spar-slider__dot glide__bullet"
              :class="{ 'glide__bullet--active': index === activeSlide }"
              :data-glide-dir="`=${index - 1}`"
            ></spar-button>
          </template>
        </div>
      </div>
      <div v-if="dots" class="spar-slider__dots-container">
        <div
          v-if="itemsCount > 1"
          class="spar-slider__dots glide__bullets"
          data-glide-el="controls[nav]"
        >
          <spar-button
            v-for="index in itemsCount"
            :key="index"
            class="spar-slider__dot glide__bullet"
            :class="{ 'glide__bullet--active': index === activeSlide }"
            :data-glide-dir="`=${index - 1}`"
          ></spar-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Glide, { type Options as GlideOptions } from "@glidejs/glide";
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import type { SparLightboxItem } from "~/components/shared/SparLightbox/SparLightbox.types";
import useI18n from "~/composables/i18n/useI18n";
import { productSchemaV1AssetsInnerTypeEnum } from "~/types/mdsa.types";

const props = defineProps({
  options: {
    type: Object as PropType<Partial<GlideOptions>>,
    default: () => ({}),
  },
  itemsCount: {
    type: Number,
    required: true,
  },
  fullwidth: {
    type: Boolean,
  },
  dots: {
    type: Boolean,
    default: false,
  },
  dotsAsImages: {
    type: Boolean,
    default: false,
  },
  showCount: {
    type: Boolean,
    default: false,
  },
  assets: {
    type: Array as PropType<SparLightboxItem[]>,
    default: null,
  },
});

const { $t } = useI18n(); // Explicit Import for Storybook

const glideDiv = ref<null | HTMLElement>(null);
const activeSlide = ref(0);
const sparSlider = ref<null | HTMLElement>(null);

const emit = defineEmits(["slideChanging"]);

onMounted(() => {
  const glide = new Glide(glideDiv.value as HTMLElement, {
    type: "carousel",
    ...props.options,
  });
  activeSlide.value = glide.index;

  glide.on("run", () => {
    activeSlide.value = glide.index;

    if (sparSlider.value) {
      const activeBullet: HTMLElement | null = sparSlider.value.querySelector(
        `.spar-slider__dot-image-wrapper[data-index='${glide.index}']`,
      );

      if (activeBullet) {
        activeBullet.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "end",
        });
      }
    }
  });

  glide.on("run.before", () => {
    emit("slideChanging", true);
    if (sparSlider.value) {
      sparSlider.value.querySelectorAll(".spar-slider-item").forEach((item) => {
        item.setAttribute("tabindex", "-1");
      });
    }
  });
  glide.on("run.after", () => {
    emit("slideChanging", false);
    if (sparSlider.value) {
      sparSlider.value.querySelectorAll(".glide__slide--active").forEach((item) => {
        item.setAttribute("tabindex", "0");
      });
    }
  });

  glide.mount();
});
</script>

<style lang="scss">
@use "SparSlider.scss";
</style>
