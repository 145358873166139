<template>
  <button
    class="btn"
    :class="buttonClasses"
    :type="type"
    :aria-label="label"
    :aria-disabled="disabled || loading"
    :data-tosca="getToscaPrefix('btn', toscaPrefix)"
    @click="handleClick($event)"
  >
    <span v-if="!iconOnly" class="btn__content">
      <slot v-if="$slots.default" class="btn__slot-content" />
      <span v-else class="btn__label">{{ label }}</span>
    </span>

    <spar-icon-sprite v-if="icon" class="btn__icon" :symbol="icon" />
    <spar-loader :loading="loading" class="btn__loader"></spar-loader>
  </button>
</template>

<script lang="ts" setup>
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparLoader from "~/components/shared/SparLoader/SparLoader.vue";
import { IconPosition } from "~/types/icon.types";
import { getToscaPrefix } from "~/utils/ui";
import {
  ButtonVariant,
  ButtonSize,
  SparButtonType,
  type SparButtonProps,
} from "./SparButton.types";

const props: SparButtonProps = defineProps({
  type: {
    type: String as PropType<SparButtonType>,
    default: SparButtonType.button,
    validator: (value: SparButtonType) =>
      [SparButtonType.button, SparButtonType.submit, SparButtonType.reset].includes(value),
  },
  icon: {
    type: String,
    default: null,
  },
  iconPosition: {
    type: String as PropType<IconPosition>,
    default: IconPosition.right,
  },
  variant: {
    type: String as PropType<ButtonVariant>,
    default: ButtonVariant.primary,
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: ButtonSize.medium,
  },
  label: {
    type: String,
    default: null,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  iconOnly: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["click"]);

const handleClick = (event: Event) => {
  // handle disabled buttons manually, as html's 'disabled' attribute is not a11y-friendly
  if (props.disabled || props.loading) {
    event.preventDefault();
    event.stopPropagation();
  } else {
    // Emit original event to allow usage of event modifiers in the parent component
    emit("click", event);
  }
};

const buttonClasses = computed(() => ({
  "btn--icon": props.icon,
  // Icon positions
  "btn--icon-left": props.icon && props.iconPosition === IconPosition.left,
  "btn--icon-right": props.icon && props.iconPosition === IconPosition.right,
  // Styles
  "btn--disabled": props.disabled,
  "btn--full-width": props.fullWidth,
  "btn--loading": props.loading,
  // Variants
  "btn--primary": props.variant === ButtonVariant.primary,
  "btn--secondary": props.variant === ButtonVariant.secondary,
  "btn--light": props.variant === ButtonVariant.light,
  "btn--clear": props.variant === ButtonVariant.clear,
  "btn--link": props.variant === ButtonVariant.link,
  "btn--custom": props.variant === ButtonVariant.custom,
  "btn--small": props.size === ButtonSize.small,
  "btn--medium": props.size === ButtonSize.medium,
  "btn--large": props.size === ButtonSize.large,
}));
</script>
