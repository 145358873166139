import type {
  Cart,
  CreateCartProps,
  ReplaceCartPaymentDetailsProps,
  RequiredPaymentDetailsProps,
} from "@vsf-enterprise/sapcc-types";
import { defineStore, storeToRefs } from "pinia";
import type {
  CartValidationErrors,
  CartStepErrorsAndWarnings,
  ProductLookUp,
  SingleCartStepErrorsAndWarnings,
} from "~/components/feature/SparCart/SparCart.types";
import {
  type InputStatusMessage,
  SparInputStatus,
} from "~/components/shared/SparInput/SparInput.types";
import { useBaseSites } from "~/composables/base-sites/baseSites";
import { useAuthStore } from "~/stores/auth.store";
import { useBaseSiteStore } from "~/stores/basesite.store";
import { CheckoutStep, checkoutErrorConfig } from "~/types/checkout.types";
import type {
  AddToCartPropsSPAR,
  AddressSPAR,
  CartModificationListSPAR,
  CartModificationSPAR,
  CartSPAR,
  OrderEntrySPAR,
} from "~/types/occ-custom.types";
import type { Address, DeliveryModeTypeList, PaymentDetails } from "~/types/occ.types";
import { CartError, handleCommerceError } from "~/utils/error";
import { SparBaseStoreTypes } from "~/utils/mdsa/integration/mdsa.types";

export const useCartStore = (baseSite: string) =>
  defineStore(`cart/${baseSite}`, () => {
    const { sdk } = useVsfSdk();
    const { $t } = useNuxtApp();
    const bs = baseSite;
    const cartCookie = useCookie<string | undefined>(`cart-${bs}`);
    const { isLoggedIn } = storeToRefs(useAuthStore());
    const { baseSiteConfig } = useBaseSiteStore();
    const route = useRoute();
    const { replaceBaseSiteInRoute } = useBaseSites();

    // When adding new state variables, also reset them in $reset method
    const cart = ref<CartSPAR | null>(null);
    const deliveryModes = ref<DeliveryModeTypeList | null>(null);
    const cartValidationErrors = ref<CartValidationErrors>();

    const addToCartLoading = ref(false);
    const anonymousToGuestCartLoading = ref(false);
    const clearCartLoading = ref(false);
    const createCartLoading = ref(false);
    const deleteCartLoading = ref(false);
    const deliveryModeTypesLoading = ref(false);
    const getCartLoading = ref(false);
    const getCartCheckoutLoading = ref(false);
    const mergeCartsLoading = ref(false);
    const removeFromCartLoading = ref(false);
    const setBillingAddressLoading = ref(false);
    const setDeliveryAddressLoading = ref(false);
    const deliveryModeTypeLoading = ref(false);
    const setPaymentDetailsLoading = ref(false);
    const updateCartItemLoading = ref(false);
    const updateCartItemStatus = ref<CartModificationSPAR | null>(null);
    const setCouponLoading = ref(false);
    const couponStatus = ref<InputStatusMessage>();
    const validateCartLoading = ref(false);
    const productLookUp: Ref<ProductLookUp> = ref({});
    const consents = ref(false);

    /**
     * Add item to cart and refresh the cart
     * If no cart exists, a new one will be created
     *
     * @param {string}  productId         The id of the product that should be added to the cart
     * @param {number}  quantity          Number of items to add
     * @param {string} [voucherDeliveryMode=""] Determine if product is a voucher
     * @throws {CartError}
     */
    async function addToCart(
      productId: string,
      quantity: number,
      voucherDeliveryMode = "",
      selectedServices: string[],
    ): Promise<void> {
      if (addToCartLoading.value) return;
      addToCartLoading.value = true;

      if (!cart.value) await createCart({});

      let deliveryMode = {};
      // if voucher --> set deliveryModeCode
      if (voucherDeliveryMode) {
        deliveryMode = {
          deliveryMode: {
            code: voucherDeliveryMode,
          },
        };
      }

      // TODO workaround - pos needs to be set for timeslot products
      // in the future, the pos should be set dynamically, depending on onboarding
      let deliveryPos = {};
      if (bs === SparBaseStoreTypes.timeslot) {
        deliveryPos = {
          deliveryPointOfService: {
            name: "AT8890T",
          },
        };
      }

      const serviceArticles = selectedServices.map((service) => ({
        code: service,
      }));

      try {
        cart.value = (await sdk.commerce.addEntryAndGetCart<Cart, AddToCartPropsSPAR>(
          {
            cartId: getCartId(),
            entry: {
              quantity,
              product: {
                code: productId,
              },
              serviceArticles,
              ...deliveryPos,
              ...deliveryMode,
            },
          },
          baseSiteConfig(baseSite),
        )) as CartSPAR;

        if (cart.value.entries?.length) {
          updateProductLookup(cart.value.entries);
        }
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_add_item"));
      } finally {
        addToCartLoading.value = false;
      }
    }

    /**
     * Add item to cart and refresh the cart
     * If no cart exists, a new one will be created
     *
     * @param {string}  productId         The id of the product that should be added to the cart
     * @param {number}  reduceQuantity          Number of items to reduce
     * @param {string} [voucherDeliveryMode=""] Determine if product is a voucher
     * @throws {CartError}
     */
    async function reduceCartProductQuantity(
      productId: string,
      reduceQuantity: number,
      voucherDeliveryMode = "",
    ): Promise<void> {
      if (addToCartLoading.value) return;
      addToCartLoading.value = true;

      let deliveryMode = {};
      // if voucher --> set deliveryModeCode
      if (voucherDeliveryMode) {
        deliveryMode = {
          deliveryMode: {
            code: voucherDeliveryMode,
          },
        };
      }

      try {
        const { data: cartResponse } = (await sdk.commerce.reduceProductQuantity(
          getCartId(),
          getUserId(),
          baseSite,
          {
            quantity: -Math.abs(reduceQuantity), // quantity needs to be a negative value for reduce endpoint in Hybris
            product: {
              code: productId,
            },
            ...deliveryMode,
          },
        )) as { data: CartSPAR };

        cart.value = cartResponse as CartSPAR;

        let entries: OrderEntrySPAR[] = [];
        if (cart.value.entries?.length) {
          entries = cart.value.entries;
        }
        updateProductLookup(entries);
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_add_item"));
      } finally {
        addToCartLoading.value = false;
      }
    }

    /**
     * Converts an anonymous cart to a guest cart by assigning an email address and refresh the cart
     * This actually creates a guest user and assigns this user to the current cart
     * Only required for guest checkout
     *
     * @param {string} email Email address of the guest user
     * @throws {CartError}
     */
    async function anonymousToGuestCart(email: string): Promise<void> {
      if (!cart.value || !cart.value.guid) throw new CartError($t("cart.error.could_not_find"));

      if (anonymousToGuestCartLoading.value) return;
      anonymousToGuestCartLoading.value = true;

      try {
        await sdk.commerce.addCartEmail(
          {
            cartId: cart.value.guid,
            email: email,
          },
          baseSiteConfig(baseSite),
        );
        await refreshCart();
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_assign_email"));
      } finally {
        anonymousToGuestCartLoading.value = false;
      }
    }

    /**
     * Remove all entries from the cart and refreshes the cart
     *
     * @throws {CartError}
     */
    async function clearCart(): Promise<void> {
      if (clearCartLoading.value) return;
      clearCartLoading.value = true;

      try {
        await sdk.commerce.clearCart(getCartId(), getUserId(), baseSite);
        await refreshCart();
        // reset cart validation
        cartValidationErrors.value = undefined;
        productLookUp.value = {};
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_clear"));
      } finally {
        clearCartLoading.value = false;
      }
    }

    /**
     * Create a new cart, if the cart is created for an anonymous user, a cookie will also be set which stores the guid.
     *
     * @param {CreateCartProps} options Options that can be passed to additionally configure the request
     * @throws {CartError}
     */
    async function createCart(options: CreateCartProps = {}): Promise<void> {
      if (!baseSite) {
        throw new CartError("No baseSite given - please check function");
      }

      if (createCartLoading.value) return;
      createCartLoading.value = true;

      try {
        cart.value = (await sdk.commerce.createCart(options, baseSiteConfig(baseSite))) as CartSPAR;

        if (cart.value.entries?.length) {
          updateProductLookup(cart.value.entries);
        }

        // Set cart cookie only if user is not signed in
        if (!isLoggedIn.value) {
          cartCookie.value = cart.value?.guid;
        }
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_create"));
      } finally {
        createCartLoading.value = false;
      }
    }

    /**
     * Delete a cart by a given cart ID, by the cart cookie stored in the browser, or the cart of the current user
     * The cart store will be cleared and cart cookies will be removed, if they are set.
     *
     * @param {string} [cartId] Id (guid) of a universal cart that should be deleted
     * @throws {CartError}
     */
    async function deleteCart(cartId?: string): Promise<void> {
      if (!cartId && !cartCookie.value && !isLoggedIn.value) return;

      if (deleteCartLoading.value) return;
      deleteCartLoading.value = true;

      try {
        await sdk.commerce.deleteCart(
          {
            cartId: cartId || cartCookie.value || "current",
          },
          baseSiteConfig(baseSite),
        );

        removeCartCookie();
        $reset();
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_delete"));
      } finally {
        deleteCartLoading.value = false;
      }
    }

    /**
     * Determine if the cart is an anonymous cart. The user must sign in before proceeding with the checkout
     *
     * @returns {boolean}
     */
    function isAnonymousCart(): boolean {
      return cart.value?.user?.uid === "anonymous";
    }

    /**
     * Determine if the cart is a guest cart (logged in anonymous user)
     *
     * @returns {boolean}
     */
    function isGuestCart(): boolean {
      if (!cart.value?.user?.uid) throw new Error("No User");

      // 0e292987-4faa-4847-9031-dd7664710fa2|gustl.grindstone@xyz.de
      const regexExp =
        //NOSONAR
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gi;
      return regexExp.test(cart.value?.user?.uid);
    }

    /**
     * Load a cart if it is not yet loaded
     * Please use refreshCart if you want to update an already loaded cart
     * This method can be used globally without loading to cart too often in different places.
     *
     * @param {string} [cartId] Id (guid) of a universal cart that should be loaded
     */
    async function loadCart(cartId?: string): Promise<void> {
      const { getPath } = useRoutes();
      const cartCheckout = route.path === replaceBaseSiteInRoute(getPath("cart", true), bs);

      // in /cart, loading is triggered by SparCart directly
      if (!cartCheckout) {
        if (cart.value || getCartLoading.value) return;
        await refreshCart(cartId);
      }
    }

    /**
     * Refreshes the cart including validation errors.
     *
     * There are 3 options to get a cart:
     * - If cartId is defined this will have the highest priority and get this specific cart as long as it is valid
     * - If cookie '<baseSiteId>-cart' is defined this will have the second-highest priority and will get a cart by guid
     * - If none of the other option is defined, it will use current to get the users current active cart
     *
     * @param {string} [cartId] Id (guid) of a universal cart that should be refreshed
     * @throws {CartError}
     */
    async function refreshCartCheckout(cartId?: string): Promise<void> {
      if (!cartId && !cartCookie.value && !isLoggedIn.value) return;

      if (getCartCheckoutLoading.value) return;
      getCartCheckoutLoading.value = true;

      try {
        const { data: cartResponse } = await sdk.commerce.getCartCheckout(
          cartId || cartCookie?.value || "current",
          getUserId(),
          baseSite,
        );

        if (cartResponse) {
          cart.value = cartResponse as CartSPAR;
          if (cart.value.entries?.length) {
            updateProductLookup(cart.value.entries);
          }
        }

        if (cart.value?.errors) {
          cartValidationErrors.value = getCartValidationErrors(cart.value.errors);
        } else {
          cartValidationErrors.value = undefined;
        }
      } catch (error) {
        // removeCartCookie(); // TODO should only be called if the request was actually made with the cookie. Could, by accident, delete an actual cart if there was some network error
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_be_loaded"));
      } finally {
        getCartCheckoutLoading.value = false;
      }
    }
    /**
     *  Create lookup list with all cart-entries of the current baseSite
     *  This Object will be checket by sparQuantityButton to prefill the current cart amount of the
     *  given product
     *
     * @param {OrderEntrySPAR} [cartEntries] Cartentries
     */
    function updateProductLookup(cartEntries: OrderEntrySPAR[]) {
      productLookUp.value = {};

      const productLookUpTemp: ProductLookUp = {};
      cartEntries.forEach((entry: OrderEntrySPAR) => {
        const { product, entryNumber, quantity, deliveryMode } = entry;

        if (product?.code && quantity) {
          const { code, maxOrderQuantity, minOrderQuantity, sparProductType, boms, name2 } =
            product;

          const cartEntries = productLookUpTemp[code]?.cartEntries || {};

          productLookUpTemp[code] = {
            bomSumUpQuantity: 0,
            entryNumber,
            quantity,
            name2,
            maxOrderQuantity,
            minOrderQuantity,
            sparProductType,
            isBomArticle: false,
            deliveryMode: deliveryMode?.code,
            cartEntries,
          };

          if (boms && boms.length && entryNumber) {
            productLookUpTemp[code].isBomArticle = true;
            cartEntries[entryNumber] = { entryNumber, quantity };
          }
        }
      });

      // sum up boms quantities
      Object.keys(productLookUpTemp).map((item) => {
        const cartEntries = productLookUpTemp[item].cartEntries;
        if (!cartEntries) return;

        let bomSumUpQuantity = 0;

        Object.keys(cartEntries).map((entryKey) => {
          bomSumUpQuantity += cartEntries[parseInt(entryKey)].quantity;
        });
        productLookUpTemp[item].bomSumUpQuantity = bomSumUpQuantity;
      });

      productLookUp.value = productLookUpTemp;
    }

    function getLookedProduct(code: string) {
      return productLookUp.value[code];
    }

    /**
     * Refreshes the cart, or loads it initially.
     *
     * There are 3 options to get a cart:
     * - If cartId is defined this will have the highest priority and get this specific cart as long as it is valid
     * - If cookie '<baseSiteId>-cart' is defined this will have the second-highest priority and will get a cart by guid
     * - If none of the other option is defined, it will use current to get the users current active cart
     *
     * @param {string} [cartId] Id (guid) of a universal cart that should be refreshed
     * @throws {CartError}
     */
    async function refreshCart(cartId?: string): Promise<void> {
      if (!cartId && !cartCookie.value && !isLoggedIn.value) return;

      if (getCartLoading.value) return;
      getCartLoading.value = true;

      try {
        cart.value = (await sdk.commerce.getCart(
          {
            cartId: cartId || cartCookie?.value || "current",
          },
          baseSiteConfig(baseSite),
        )) as CartSPAR;

        if (cart.value.entries?.length) {
          updateProductLookup(cart.value.entries);
        }
      } catch (error) {
        // removeCartCookie(); // TODO should only be called if the request was actually made with the cookie. Could, by accident, delete an actual cart if there was some network error
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_be_loaded"));
      } finally {
        getCartLoading.value = false;
      }
    }

    function getCartValidationErrors(errors: CartModificationSPAR[]): CartValidationErrors {
      return errors.reduce(
        (x: { [key: string]: CartModificationSPAR[] }, y: CartModificationSPAR) => {
          if (y.statusCode) {
            (x[y.statusCode] = x[y.statusCode] || []).push(y);
          }
          return x;
        },
        {},
      );
    }

    /**
     * Remove item from cart and refresh the cart
     *
     * @param {number} entryNumber Number of the entry to be removed
     * @throws {CartError}
     */
    async function removeFromCart(entryNumber: number): Promise<void> {
      if (removeFromCartLoading.value) return;
      removeFromCartLoading.value = true;

      try {
        cart.value = (await sdk.commerce.removeEntryAndGetCart(
          {
            cartId: getCartId(),
            entryNumber,
          },
          baseSiteConfig(baseSite),
        )) as CartSPAR;

        let entries: OrderEntrySPAR[] = [];
        if (cart.value.entries?.length) {
          entries = cart.value.entries;
        } else {
          // empty cart - reset productLookup
          productLookUp.value = {};
        }
        updateProductLookup(entries);
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_remove_item"));
      } finally {
        removeFromCartLoading.value = false;
      }
    }

    /**
     * Set or updates the billing address of a cart and refresh the cart
     *
     * @param {Address} address The new billing address
     * @throws {CartError}
     */
    async function setBillingAddress(address: Address): Promise<void> {
      if (setBillingAddressLoading.value) return;
      setBillingAddressLoading.value = true;

      try {
        await sdk.commerce.setBillingAddress(getCartId(), getUserId(), address, baseSite);
        await refreshCart();
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_set_billing_address"));
      } finally {
        setBillingAddressLoading.value = false;
      }
    }

    /**
     * Set or updates the delivery address of a cart and refresh the cart
     *
     * @param {Address} address The new delivery address
     * @param {Boolean} replaceAddress Flag to replace an existing address on the cart
     * @throws {CartError}
     */
    async function setDeliveryAddress(address: Address, replaceAddress = false): Promise<void> {
      if (setDeliveryAddressLoading.value) return;
      setDeliveryAddressLoading.value = true;
      const addressId = address.id ?? "";
      const cartId = getCartId();

      try {
        if (replaceAddress) {
          // for users, which already have a list of addresses, set existing delivery address as new cart address
          await sdk.commerce.replaceCartAddress(
            {
              cartId,
              addressId,
            },
            baseSiteConfig(baseSite),
          );
        } else if (addressId) {
          // address already exists, patch changes instead of creating a new one
          await sdk.commerce.updateCartAddress(cartId, getUserId(), addressId, address, baseSite);
        } else {
          // address not created yet
          await sdk.commerce.createCartAddress(
            {
              cartId,
              address: address as AddressSPAR, // TODO test ob null die addresse löschen würde und ggf mit in die signatur und type anpassen
            },
            baseSiteConfig(baseSite),
          );
        }
        await validateCart();
        await refreshCart();
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_set_delivery_address"));
      } finally {
        setDeliveryAddressLoading.value = false;
      }
    }

    /**
     * Set the payment details of a cart and refresh the cart
     *
     * @param {PaymentDetails} paymentDetails Required or optional details
     * @throws {CartError}
     */
    async function setPaymentDetails(
      paymentDetails: PaymentDetails & RequiredPaymentDetailsProps,
    ): Promise<void> {
      if (setPaymentDetailsLoading.value) return;
      setPaymentDetailsLoading.value = true;

      try {
        await sdk.commerce.createCartPaymentDetails(
          {
            cartId: getCartId(),
            paymentDetails,
          },
          baseSiteConfig(baseSite),
        );
        await refreshCart();
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_set_payment_details"));
      } finally {
        setPaymentDetailsLoading.value = false;
      }
    }

    /**
     * Update cart payment details and refresh cart.
     *
     * @param cartId the cart id
     * @param paymentDetailsId the payment details id
     */
    async function replaceCartPaymentDetails(paymentDetailsId: string): Promise<void> {
      try {
        const props = {
          cartId: getCartId(),
          paymentDetailsId: paymentDetailsId,
        } as ReplaceCartPaymentDetailsProps;
        await sdk.commerce.replaceCartPaymentDetails(props, baseSiteConfig(baseSite));
        await refreshCart();
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_replace_payment_details"));
      }
    }

    /**
     * Update an entry of a cart, e.g. the quantity and refresh the cart
     *
     * @param {number} quantity     The new quantity
     * @param {number} entryNumber  Number of the entry to be updated
     * @throws {CartError}
     */
    async function updateCartItem(quantity: number, entryNumber: number): Promise<void> {
      if (updateCartItemLoading.value) return;
      updateCartItemLoading.value = true;

      try {
        updateCartItemStatus.value = (await sdk.commerce.updateCartEntry(
          {
            cartId: getCartId(),
            entryNumber: entryNumber,
            entry: {
              quantity: quantity,
            },
          },
          baseSiteConfig(baseSite),
        )) as CartModificationSPAR;
        await refreshCartCheckout();
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_update_item"));
      } finally {
        updateCartItemLoading.value = false;
      }
    }

    function setCartItemStatus(state: CartModificationSPAR | null): void {
      updateCartItemStatus.value = state;
    }

    /**
     * Remove cart cookie, if it exists
     */
    function removeCartCookie(): void {
      if (cartCookie.value) {
        cartCookie.value = undefined;
      }
    }

    /**
     * Get the cart of the user, either guid if anonymous/guest or code if signed in
     *
     * @return {string}
     * @throws {CartError}
     */
    function getCartId(): string {
      if (!cart.value) throw new CartError($t("cart.error.not_loaded"));

      const cartId = isLoggedIn.value ? cart.value.code : cart.value.guid;
      if (!cartId) throw new CartError($t("cart.error.invalid_id"));

      return cartId;
    }

    /**
     * Get the user id, "current" for currently authenticated user, "anonymous" for anonymous user
     *
     * @return {string}
     * @throws {CartError}
     */
    function getUserId(): string {
      const userId = isLoggedIn.value ? "current" : "anonymous";
      if (!userId) throw new CartError($t("cart.error.invalid_user_id"));

      return userId;
    }

    /**
     * Merge an anonymous cart with the current user's cart.
     */
    async function mergeCarts(): Promise<void> {
      // cart merge available for logged-in users only, cartCookie for baseSite needs to be set
      if (!isLoggedIn.value || !cartCookie.value || mergeCartsLoading.value) return;

      mergeCartsLoading.value = true;

      // turn an anonymous cart into a user cart
      let options: CreateCartProps = {
        oldCartId: cartCookie.value,
      };

      const userCartGuid = await getCurrentUserCartGuid();
      if (userCartGuid && userCartGuid.length > 0) {
        // merge an anonymous cart with a user cart
        options = {
          toMergeCartGuid: userCartGuid,
          ...options,
        };
      }

      try {
        // do cart merge
        await createCart(options);
        // remove cookie, otherwise a non-existent cart is referenced
        removeCartCookie();
      } catch (e: unknown) {
        throw new CartError($t("cart.error.could_not_merge_carts"));
      } finally {
        mergeCartsLoading.value = false;
      }
    }

    /**
     * Fetch a list of customer cart guids and return the first one (which is the "current" cart).
     *
     * @returns {Promise<string>}
     */
    async function getCurrentUserCartGuid(): Promise<string> {
      try {
        const { carts } = await sdk.commerce.getUserCarts(
          { fields: "carts(guid)" },
          baseSiteConfig(baseSite),
        );
        return carts && carts[0].guid ? carts[0].guid : "";
      } catch (error) {
        handleCommerceError(error as Error);
        return "";
      }
    }

    /**
     * Fetch all available delivery mode types from the cart.
     *
     * @throws {CartError}
     */
    async function getDeliveryModeTypes(): Promise<void> {
      if (deliveryModeTypesLoading.value) return;
      deliveryModeTypesLoading.value = true;

      // load types
      try {
        const { data: deliveryModeTypes } = (await sdk.commerce.getDeliveryModeTypes(
          getCartId(),
          getUserId(),
          baseSite,
        )) as { data: DeliveryModeTypeList };

        deliveryModes.value = deliveryModeTypes;
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_get_deliverymodetypes"));
      } finally {
        deliveryModeTypesLoading.value = false;
      }
    }

    /**
     * Set delivery mode type of the cart.
     *
     * @param {string} deliveryType
     * @throws {CartError}
     */
    async function setDeliveryModeType(deliveryType: string): Promise<void> {
      if (deliveryModeTypeLoading.value) return;
      deliveryModeTypeLoading.value = true;

      // TODO this is only a temporary fix - posName should be selectable by the users
      // pos AT6451P works ONLY for national, but this is ok for the moment, as timeslot is OOS
      const posName = deliveryType === "PICKUP" ? "AT6451P" : "";

      // TODO - PLEASE ADD ERROR-HANDLING -> FE-Notification when clientApiErrors are finished from vstf-team

      // set type
      try {
        await sdk.commerce.setDeliveryModeType(
          getCartId(),
          getUserId(),
          deliveryType,
          posName,
          baseSite,
        );
        // get fresh list to select selected if call was success
        await getDeliveryModeTypes();
        // refresh cart for potentially updates
        await refreshCart();
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_set_deliverymodetype"));
      } finally {
        deliveryModeTypeLoading.value = false;
      }
    }

    async function setCouponCode(coupon: string) {
      setCouponLoading.value = true;

      try {
        await sdk.commerce.addCartVoucher(
          { cartId: getCartId(), voucherId: coupon },
          baseSiteConfig(baseSite),
        );
        couponStatus.value = {
          status: SparInputStatus.success,
          text: $t("checkout.step.payment.coupon.success"),
        };
        await refreshCart();
      } catch (error) {
        couponStatus.value = {
          status: SparInputStatus.error,
          text: $t("checkout.step.payment.coupon.error"),
        };
        handleCommerceError(error as Error);
        throw new CartError(couponStatus.value.text);
      } finally {
        setCouponLoading.value = false;
      }
    }

    /**
     * Validates all entries from the cart
     *
     * @throws {CartError}
     */
    async function validateCart(): Promise<void> {
      if (validateCartLoading.value || !cart.value) return;

      validateCartLoading.value = true;

      try {
        const { data: validationResponse } = (await sdk.commerce.validateCart(
          getCartId(),
          getUserId(),
          baseSite,
        )) as { data: CartModificationListSPAR };

        if (validationResponse?.cartModifications && validationResponse.cartModifications.length) {
          cartValidationErrors.value = getCartValidationErrors(
            validationResponse.cartModifications,
          );
        } else {
          cartValidationErrors.value = undefined;
        }
      } catch (error) {
        handleCommerceError(error as Error);
        throw new CartError($t("cart.error.could_not_validate"));
      } finally {
        validateCartLoading.value = false;
      }
    }

    /**
     * Get error and warning message object for a given checkout step
     * Check current AND previous steps by validating steps in reversed order
     *
     * @param {CheckoutStep} step
     * @returns {CartStepErrorsAndWarnings}
     */
    function getCartStepErrorsAndWarnings(step: CheckoutStep): CartStepErrorsAndWarnings {
      const errors: CartValidationErrors = {};
      const warnings: CartValidationErrors = {};
      const cartStepErrorsAndWarnings: SingleCartStepErrorsAndWarnings[] = [];

      switch (step) {
        case CheckoutStep.Summary:
          cartStepErrorsAndWarnings.push(getSingleCartStepErrorsAndWarnings(CheckoutStep.Summary));
          cartStepErrorsAndWarnings.push(getSingleCartStepErrorsAndWarnings(CheckoutStep.Delivery));
          cartStepErrorsAndWarnings.push(getSingleCartStepErrorsAndWarnings(CheckoutStep.Cart));
        case CheckoutStep.Delivery:
          cartStepErrorsAndWarnings.push(getSingleCartStepErrorsAndWarnings(CheckoutStep.Delivery));
          cartStepErrorsAndWarnings.push(getSingleCartStepErrorsAndWarnings(CheckoutStep.Cart));
        case CheckoutStep.Cart:
          cartStepErrorsAndWarnings.push(getSingleCartStepErrorsAndWarnings(CheckoutStep.Cart));
      }

      cartStepErrorsAndWarnings.forEach((errorsAndWarnings) => {
        errorsAndWarnings.errors?.forEach((error) => {
          if (!cartValidationErrors.value) return;
          errors[error] = cartValidationErrors.value[error];
        });

        errorsAndWarnings.warnings?.forEach((error) => {
          if (!cartValidationErrors.value) return;
          warnings[error] = cartValidationErrors.value[error];
        });
      });

      return {
        errors,
        warnings,
      };
    }

    /**
     * Filter error and warning message keys for a single given cart checkout step
     *
     * @param {CheckoutStep} step
     * @returns {SingleCartStepErrorsAndWarnings} Object with error and warning keys
     */
    function getSingleCartStepErrorsAndWarnings(
      step: CheckoutStep,
    ): SingleCartStepErrorsAndWarnings {
      const stepConfig = checkoutErrorConfig.find((conf) => conf.step === step);
      if (!stepConfig || !cartValidationErrors.value) return { errors: [], warnings: [] };

      const currentErrors = Object.keys(cartValidationErrors.value);
      const errors = currentErrors.filter((error) => stepConfig.errors.includes(error));
      const warnings = currentErrors.filter((error) => stepConfig.warnings.includes(error));

      return {
        errors,
        warnings,
      };
    }

    /**
     * Reset the store
     */
    function $reset(): void {
      cart.value = null;
      deliveryModes.value = null;
      cartValidationErrors.value = undefined;
      consents.value = false;
      addToCartLoading.value = false;
      anonymousToGuestCartLoading.value = false;
      clearCartLoading.value = false;
      couponStatus.value = undefined;
      createCartLoading.value = false;
      deleteCartLoading.value = false;
      deliveryModeTypesLoading.value = false;
      getCartLoading.value = false;
      getCartCheckoutLoading.value = false;
      mergeCartsLoading.value = false;
      removeFromCartLoading.value = false;
      setBillingAddressLoading.value = false;
      setCouponLoading.value = false;
      setDeliveryAddressLoading.value = false;
      deliveryModeTypeLoading.value = false;
      setPaymentDetailsLoading.value = false;
      updateCartItemLoading.value = false;
      validateCartLoading.value = false;
      productLookUp.value = {};
    }

    return {
      bs,
      addToCart,
      addToCartLoading,
      anonymousToGuestCart,
      anonymousToGuestCartLoading,
      cart,
      cartCookie,
      clearCart,
      clearCartLoading,
      createCart,
      createCartLoading,
      deleteCart,
      deleteCartLoading,
      deliveryModes,
      getCartId,
      getCartLoading,
      getCartCheckoutLoading,
      getCartStepErrorsAndWarnings,
      getDeliveryModeTypes,
      getLookedProduct,
      getUserId,
      isAnonymousCart,
      isGuestCart,
      loadCart,
      mergeCarts,
      productLookUp,
      refreshCart,
      refreshCartCheckout,
      removeCartCookie,
      removeFromCart,
      removeFromCartLoading,
      setBillingAddress,
      setBillingAddressLoading,
      setCouponCode,
      setCouponLoading,
      couponStatus,
      reduceCartProductQuantity,
      setDeliveryAddress,
      setDeliveryAddressLoading,
      setDeliveryModeType,
      setPaymentDetails,
      replaceCartPaymentDetails,
      setPaymentDetailsLoading,
      updateCartItem,
      updateCartItemLoading,
      updateCartItemStatus,
      setCartItemStatus,
      $reset,
      validateCart,
      cartValidationErrors,
    };
  });
