import { contentstackModule, type ContentstackModuleType } from "@vsf-enterprise/contentstack-sdk";
import {
  client as sapccClient,
  sapccModule,
  type SAPCCModuleType,
} from "@vsf-enterprise/sapcc-sdk";
import type { OAuthToken } from "@vsf-enterprise/sapcc-types";
import { buildModule, initSDK } from "@vsf-enterprise/sdk";
import { useBaseSiteStore } from "~/stores/basesite.store";
import { getCorrelationHeaders } from "~/utils/cookies/corrrelation-headers";
import { sapCcExtension } from "./extensions/sapcc";

const setBaseSites = () => {
  const baseSiteStore = useBaseSiteStore();
  baseSiteStore.setBaseSites();
};

let interceptorAdded = false;
export function useVsfSdk() {
  // Add Axios interceptors only once
  if (!interceptorAdded) {
    extendSapccClient();
    interceptorAdded = true;
  }

  setBaseSites();

  const { middlewareUrl, middlewareUrlSsr } = useRuntimeConfig().public;

  const sdkConfig = {
    commerce: buildModule<SAPCCModuleType, typeof sapCcExtension>(
      sapccModule,
      {
        apiUrl: `${middlewareUrl}/sapcc`,
        refreshToken: {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          onTokenRefreshed: async (refreshTokenResponse: any) => {
            Log.debug(LogArea.sapcc, "onTokenRefreshed", refreshTokenResponse);
            if (!process.server) return;

            const { token, tokenCookieOptions } = refreshTokenResponse;
            const tokenCookie = useCookie<OAuthToken>("vsf-sap-token", tokenCookieOptions);
            tokenCookie.value = token;
          },
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          onTokenRefreshError: async (error: any) => {
            Log.error(LogArea.sapcc, "onTokenRefreshError", error);
          },
        },
      },
      sapCcExtension,
    ),
    cms: buildModule<ContentstackModuleType>(contentstackModule, {
      apiUrl: `${middlewareUrl}/cnts`,
      ssrApiUrl: `${middlewareUrlSsr}/cnts`,
    }),
  };

  const sdk = initSDK<typeof sdkConfig>(sdkConfig);

  return {
    sdk,
  };
}

/**
 * Axios Client Manipulation (add Correlation Headers)
 */
function extendSapccClient() {
  sapccClient.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers, // keep existing headers
      ...getCorrelationHeaders(),
    };
    return config;
  });
}
