<template>
  <nuxt-link
    :to="linkRef"
    class="link"
    :target="linkTarget !== 'none' ? target : ''"
    :class="linkClasses"
    :aria-label="ariaLabel"
    :title="label"
    :data-tosca="getToscaPrefix('link', toscaPrefix)"
  >
    <div v-if="!iconOnly" class="link__content">
      <slot v-if="$slots.default" class="link__slot-content" />
      <div v-else class="link__label">{{ label }}</div>
    </div>

    <spar-icon-sprite v-if="isIconVariant && icon" class="link__icon" :symbol="icon" />
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { StaticProps } from "@shop-storefront/utils";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import type { SparLinkProps } from "~/components/shared/SparLink/SparLink.types";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import { IconPosition } from "~/types/icon.types";
import { getToscaPrefix } from "~/utils/ui";
import { type InternalLink, type LinkTarget, LinkVariant } from "./SparLink.types";

const linkRef: Ref<string> = ref("");

const props: SparLinkProps = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  internalLink: {
    type: Array as PropType<InternalLink[]>,
    default: undefined,
  },
  externalLink: {
    type: String,
    default: undefined,
  },
  link: {
    type: String,
    default: undefined,
  },
  target: {
    type: String as PropType<LinkTarget | null>,
    default: null,
  },
  ariaLabel: {
    type: String,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  iconOnly: {
    type: Boolean,
    default: false,
  },
  iconPosition: {
    type: String as PropType<IconPosition>,
    default: IconPosition.right,
  },
  variant: {
    type: String as PropType<LinkVariant>,
    default: LinkVariant.link,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
  slug: {
    type: String,
    default: undefined,
  },
});

const { $contentstack } = useNuxtApp();

const getEntryById = async (uid: string, type: string) => {
  return (await $contentstack.getEntries({
    type,
    customQuery: {
      [ContentstackPageParams.uid]: uid,
    },
  })) as unknown[] as InternalLink[];
};

const prefixSlashForRelativeUrls = (url: string) => {
  // Protocol agnostic regex to validate if we deal with an absolute url
  const regex = new RegExp("^(?:[a-z+]+:)?//", "i");

  if (regex.test(url)) return url;
  if (url.indexOf("/") === 0) return url;
  return `/${url}`;
};

onMounted(async () => {
  const link = await linkTo();
  linkRef.value = link;
});

// Make sure the internalLink is correctly referenced `{fieldId}.internalLink`.
// Please refer to: https://collaboration.spar.at/confluence/display/ES/2.1.24+Linking+%28Global+Field%29+in+Contentstack
const linkTo = async () => {
  if (typeof props.link === "string") return prefixSlashForRelativeUrls(props.link);
  if (props.internalLink?.length && props.internalLink?.[0]?.slug) {
    return prefixSlashForRelativeUrls(props.internalLink[0].slug ?? "");
  }
  if (props.internalLink?.length) {
    const pageData = await getEntryById(
      (props?.internalLink as StaticProps[])?.[0].uid,
      (props?.internalLink as StaticProps[])?.[0].contentTypeUid,
    );
    return prefixSlashForRelativeUrls(pageData[0]?.slug ?? "");
  }
  if (props.externalLink) return props.externalLink;
  return "";
};

const linkTarget = props.internalLink?.length ? props.target : "_blank";

const isIconVariant = computed(
  () => props.variant === LinkVariant.linkIcon || props.variant === LinkVariant.secondaryIcon,
);

const linkClasses = computed(() => {
  return {
    "link--icon": isIconVariant.value && props.icon,
    "link--icon-left": isIconVariant.value && props.iconPosition === IconPosition.left,
    "link--icon-right": isIconVariant.value && props.iconPosition === IconPosition.right,
    "link--secondary":
      props.variant === LinkVariant.secondary || props.variant === LinkVariant.secondaryIcon,
  };
});
</script>

<style lang="scss">
@use "./SparLink.scss";
</style>
