<template>
  <section class="teaserblock container spar-section" :data-cslp="cntsLp?.component?.['data-cslp']">
    <template v-if="content">
      <render-sections :content="content" />
    </template>
  </section>
</template>

<script lang="ts" setup>
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import type { SparTeaserblockProps } from "./SparTeaserblock.types";

const props = defineProps<SparTeaserblockProps>();

const content = props.teasers.map((teaser) => {
  return {
    content: [teaser],
  };
});

defineOptions({
  inheritAttrs: false,
});
</script>

<style lang="scss">
@use "./SparTeaserblock.scss";
</style>
