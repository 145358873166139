<template>
  <div>
    <spar-select
      v-if="selectOptions.length > 1"
      v-model="activeNutritionGroup"
      :options="selectOptions"
      :label="'Produkt auswählen'"
      class="spar-nutriton-table__select"
    />

    <table v-if="tableData" class="spar-nutrition-table">
      <thead>
        <tr class="spar-nutrition-table__row">
          <th class="spar-nutrition-table__row--label">
            {{ $t("pdp.product_details.nutrition.title") }}
          </th>
          <th class="spar-nutrition-table__row--value">
            {{ $t("pdp.product_details.nutrition.per") }} {{ tableData.nutrientBasisQuantity }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="activePreparationState" class="spar-nutrition-table__row">
          <td class="spar-nutrition-table__row--value" colspan="2">
            {{ activePreparationState.valueString }}
          </td>
        </tr>
        <tr v-for="(row, i) in tableData.nutritionRows" :key="i" class="spar-nutrition-table__row">
          <td class="spar-nutrition-table__row--label">{{ row.label }}</td>
          <td class="spar-nutrition-table__row--value">{{ row.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import type { SparSelectOption } from "~/components/shared/SparSelect/SparSelect.types";
import SparSelect from "~/components/shared/SparSelect/SparSelect.vue";
import useI18n from "~/composables/i18n/useI18n";
import type {
  ProductSchemaV1Nutrition,
  ProductSchemaV1NutritionServingSizeDescriptionValuesInner,
} from "~/types/mdsa.types";
import { getValueStrings } from "~/utils/mdsa/ui";
import { NutritionTableKey } from "./SparNutritionTable.types";

// TODO: This entire file needs a refactoring as soon as the final MDSA types are available

const props = defineProps({
  nutrition: {
    type: Object as PropType<ProductSchemaV1Nutrition>,
    required: true,
  },
});

const { $t } = useI18n(); // Explicit Import for Storybook

const nutritionGroups = computed(() => {
  if (!props.nutrition.nutritionTable) return [];

  const keys = Object.keys(props.nutrition.nutritionTable);

  // Validate Nutrition Table Data
  return keys.filter((key) => {
    const data = props.nutrition.nutritionTable![key];
    const entries = Object.entries(data);

    // Must contain "nutrientBasisQuantity" property
    const nutrientBasisQuantityEntry = entries.find(
      (entry) => entry[0] === NutritionTableKey.nutrientBasisQuantity,
    );

    return Boolean(nutrientBasisQuantityEntry);
  });
});

const activeNutritionGroup = ref<number | string | undefined>(nutritionGroups.value[0]);

const getAlternativeLabel = (nutritionGroups: string) => {
  if (!props.nutrition.nutritionTable) return "";
  const data = props.nutrition.nutritionTable[nutritionGroups];

  return data.nutrientBasisQuantity?.values?.[0].valueString || "";
};

const selectOptions = computed<SparSelectOption[]>(() => {
  const servingSizes = props.nutrition.servingSizeDescription?.values;

  return nutritionGroups.value.map((opt) => {
    const servingSize = servingSizes?.find((size) => size.nutritionGroup?.toString() === opt);

    return {
      key: opt,
      value: servingSize?.valueString || getAlternativeLabel(opt),
    };
  });
});

/**
 * Extract the nutrition table data for use in template
 * Remove "nutrientBasisQuantity" from the actual rows
 */
const tableData = computed(() => {
  if (!props.nutrition.nutritionTable || !activeNutritionGroup.value) return;

  const data = props.nutrition.nutritionTable[activeNutritionGroup.value];
  const entries = Object.entries(data);

  const nutrientBasisQuantityEntry = entries.find(
    (entry) => entry[0] === NutritionTableKey.nutrientBasisQuantity,
  );
  const nutrientBasisQuantity = getValueStrings(nutrientBasisQuantityEntry?.[1].values).join();

  const nutritionEntries = entries
    // Remove nutrientBasisQuantity from data
    .filter((entry) => entry[0] !== NutritionTableKey.nutrientBasisQuantity)
    // Validate schema (data from MDSA can be unstable)
    .filter((entry) => {
      const keys = Object.keys(entry[1]);
      return keys.includes("feName") && keys.includes("values");
    });

  const nutritionRows = nutritionEntries.map((entry) => {
    const data = entry[1];

    return {
      label: data.feName,
      value: getValueStrings(data.values).join(),
    };
  });

  return {
    nutrientBasisQuantity,
    nutritionRows,
  };
});

const activePreparationState = computed<
  ProductSchemaV1NutritionServingSizeDescriptionValuesInner | undefined
>(() => {
  if (!props.nutrition.preparationState?.values || !activeNutritionGroup.value) return;

  return props.nutrition.preparationState.values.find(
    (state) => state.nutritionGroup === activeNutritionGroup.value,
  );
});
</script>

<style lang="scss">
@use "./SparNutritionTable.scss";
</style>
