// Global state

interface StoreData {
  storeId: string | undefined;
  lat: number;
  lng: number;
}

const favouriteStore = ref<StoreData>();

export function useFavouriteStore() {
  const localStorageKey = "favoriteStore";

  const loadFavouriteStore = () => {
    const storedValue = localStorage.getItem(localStorageKey);
    if (!storedValue) return;

    const res: StoreData = JSON.parse(storedValue) as StoreData;

    // TODO: Validate store Id -> does it really exist?
    // If it does not exist, remove the localStorage item

    // Invalid value provided; clear local storage
    if (!Boolean(Number(res.storeId))) {
      setFavouriteStore(undefined);
      return;
    }

    favouriteStore.value = res;
  };

  const setFavouriteStore = (store: StoreData | undefined) => {
    if (!store) {
      localStorage.removeItem(localStorageKey);
    } else {
      localStorage.setItem(localStorageKey, JSON.stringify(store));
    }
    favouriteStore.value = store;
  };

  onMounted(() => {
    loadFavouriteStore();
  });

  return {
    favouriteStore,
    setFavouriteStore,
  };
}
