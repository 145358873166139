import AvailabilityIntegration from "~/utils/availability/integration/availability.integration";
import type { InternalFetch } from "../fetch";

export default defineNuxtPlugin(async ({ $internalFetch }) => {
  return {
    provide: {
      availability: new AvailabilityIntegration($internalFetch as InternalFetch),
    },
  };
});
