import type { SparAdaptiveImageProps } from "./SparAdaptiveImage.types";

export const sparAdaptiveImageMock: SparAdaptiveImageProps = {
  src: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Muenchner_Brezn.jpg/640px-Muenchner_Brezn.jpg",
  alt: "I am a alt text",
  // width: 216,
  // height: 326,
  title: "Image Title",
  objectFit: "cover",
  toscaPrefix: "lorem-ipsum",
};
