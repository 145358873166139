<template>
  <VueFinalModal
    v-model="modelValue"
    content-transition="vfm-slide-left"
    overlay-transition="vfm-slide-left"
    content-class="slideout__content-wrapper"
    hide-overlay
  >
    <div class="slideout__header">
      <h2 class="slideout__title">{{ title }}</h2>
      <spar-button
        class="slideout__close"
        icon="close"
        icon-only
        :variant="ButtonVariant.secondary"
        :aria-label="$t('global.close')"
        @click="close"
      />
    </div>

    <div class="slideout__content">
      <slot />
    </div>

    <div class="slideout__footer">
      <slot name="footer" />
    </div>
  </VueFinalModal>
</template>

<script lang="ts" setup>
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["close"]);

const modelValue = ref(false);

const close = () => {
  modelValue.value = false;
  emit("close");
};

watch(
  () => props.isVisible,
  (isVisible) => (modelValue.value = isVisible),
);
</script>

<style lang="scss">
@use "./SparSlideout.scss";
</style>
