import validate from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/ecom/shop-storefront/packages/frontend/src/middleware/auth.ts"),
  cart: () => import("/builds/ecom/shop-storefront/packages/frontend/src/middleware/cart.ts"),
  checkout: () => import("/builds/ecom/shop-storefront/packages/frontend/src/middleware/checkout.ts"),
  leaflets: () => import("/builds/ecom/shop-storefront/packages/frontend/src/middleware/leaflets.ts")
}