import { defineStore } from "pinia";
import queryString from "query-string";
import {
  SearchCategory,
  type SearchTopicType,
  type SearchHits,
} from "~/components/feature/SparSearch/SparSearch.types";
import {
  SearchMode,
  type SearchModeKey,
} from "~/components/sections/SparHeader/SparSearchField/SparSearchField.types";
import {
  resolveFactFinderProduct,
  resolveFactFinderResponse,
  resolveFactFinderTopic,
} from "~/utils/contentstack/resolvers/product/fact-finder.resolvers";
import { FactFinderError } from "~/utils/error";
import { createQuery, type FactFinderParams } from "~/utils/factfinder/createQuery";
import {
  type FactFinder,
  type FactFinderCampaign,
  FactFinderCampaignFlavour,
  type FactFinderFacet,
  type FactFinderProductResolved,
} from "~/utils/factfinder/integration/factfinder.types";
import { getUniqueId } from "~/utils/ui";

type SearchResultGroup = Partial<FactFinder>;

export const useSearchStore = defineStore("search", () => {
  const showMobileSearch = ref(false);
  const showSearchOverlay = ref(false);
  const searchResults = reactive(getEmptySearchResults());
  const topicSuggestions: Ref<SearchTopicType[]> = ref([]);
  const productSuggestions: Ref<FactFinderProductResolved[]> = ref([]);

  const searchMode: Ref<SearchMode> = ref(0);
  const searchModeSuggestions: Ref<SearchMode> = ref(0);
  const searchTerm = ref("");
  const searchTermSuggestions = ref("");

  const router = useRouter();
  const route = useRoute();
  const { getPath } = useRoutes();

  const urlQuery = ref<Record<string, string | string[]>>({});
  const isLoading = ref(true);
  const isLoadingSuggestions = ref(true);
  const redirectUrl = ref("/");

  const hitsPerPage = 30; // fixed value
  const currentPage = ref(1);

  const { $factFinder, runWithContext } = useNuxtApp();

  const FactFinderConfig = {
    products: {
      resolver: resolveFactFinderProduct,
      api: $factFinder.search,
      name: "factFinderSearch",
      category: SearchCategory.products,
      // TODO: Move to env var
      channel: "products_at-qa",
      hitsPerPage: 30,
    },
    topics: {
      resolver: resolveFactFinderTopic,
      api: $factFinder.search,
      name: "factFinderSearch",
      category: SearchCategory.topics,
      // TODO: Move to env var
      channel: "content_at",
      hitsPerPage: 9,
    },
  } as const;

  // TODO: Typing + Validation of Query object
  async function setQuery(query: object) {
    isLoading.value = true;
    urlQuery.value = {
      ...query,
    };

    if ("mode" in query && typeof query.mode === "string") {
      const modeKey = SearchMode[query.mode as SearchModeKey];
      searchMode.value = modeKey || 0;
      // Don't pass "mode" to FactFinder
      delete urlQuery.value["mode"];
    }

    if ("page" in query && typeof query.page === "number") {
      currentPage.value = query.page;
    } else {
      currentPage.value = 1;
    }

    await getAllResults();

    // If a redirect occurs, do not switch off loading state to avoid short appearance of results
    if (!handleRedirects()) {
      isLoading.value = false;
    }
  }

  // Entry function for starting a new search
  async function getAllResults(isSuggestion = false) {
    switch (isSuggestion ? searchModeSuggestions.value : searchMode.value) {
      case SearchMode.products:
        await getResultsForCategory(SearchCategory.products, isSuggestion);
        break;
      case SearchMode.topics:
        await getResultsForCategory(SearchCategory.topics, isSuggestion);
        break;
      default:
        await getResultsForCategory(SearchCategory.products, isSuggestion);
        await getResultsForCategory(SearchCategory.topics, isSuggestion);
    }
  }

  async function getResultsForCategory(
    category: keyof typeof FactFinderConfig,
    isSuggestion = false,
    loadMore = false,
  ) {
    setLoadingState(true, isSuggestion);

    try {
      const { data } = (await $factFinder.search({
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: {
          channel: FactFinderConfig[category].channel,
          // "hits" parameter cannot be changed by user
          query: createQuery({ ...urlQuery.value, hits: FactFinderConfig[category].hitsPerPage }),
        },
        key: getUniqueId(FactFinderConfig[category].channel),
      })) as { data: Ref<FactFinder> };

      if (!data.value) return;

      // resolveFactFinderResponse requires access to nuxt instance (runtime config)
      const dataTransformed = await runWithContext(() =>
        resolveFactFinderResponse(data.value, category),
      );

      const { totalHits, campaigns, sortItems, facets, resolvedTopics, resolvedProducts } =
        dataTransformed;

      const resolvedHits: (FactFinderProductResolved | SearchTopicType)[] =
        category === SearchCategory.topics ? resolvedTopics || [] : resolvedProducts || [];

      setHits({
        category,
        totalHits,
        campaigns,
        sortItems,
        facets,
        isSuggestion,
        hits: resolvedHits,
        loadMore,
      });
    } catch (error) {
      throw new FactFinderError("FactFinder error");
    }

    setLoadingState(false, isSuggestion);
  }

  function setLoadingState(loadingState: boolean, isSuggestion: boolean = false) {
    if (isSuggestion) {
      isLoadingSuggestions.value = loadingState;
    } else {
      isLoading.value = loadingState;
    }
  }

  async function getSuggestions(query: string) {
    const queryObj = queryString.parse(`search=${query}`, {
      parseNumbers: true,
      arrayFormat: "comma",
    });

    urlQuery.value = {
      ...queryObj,
      hits: "10", //fixed value
    };

    getAllResults(true);
  }

  function resetSuggestions() {
    topicSuggestions.value = [];
    productSuggestions.value = [];
  }

  // TODO: This avoids unexpected behaviour with old search results in the store
  // It works, but needs a global approach in the future
  function resetResults(category: SearchCategory) {
    searchResults[category] = getEmptySearchResultGroup();
  }

  function setHits({
    category,
    totalHits,
    campaigns,
    sortItems,
    facets,
    isSuggestion,
    hits,
    loadMore,
  }: SearchHits) {
    if (isSuggestion) {
      if (category === SearchCategory.topics) {
        topicSuggestions.value = hits as SearchTopicType[];
      } else if (category === SearchCategory.products) {
        productSuggestions.value = hits as FactFinderProductResolved[];
      }
      if (category === SearchCategory.products) {
        productSuggestions.value = hits as FactFinderProductResolved[];
      }
    } else {
      if (category === SearchCategory.products) {
        searchResults[category].resolvedProducts = hits as FactFinderProductResolved[];
      } else if (category === SearchCategory.topics) {
        searchResults[category].resolvedTopics = loadMore
          ? [...(searchResults[category].resolvedTopics || []), ...(hits as SearchTopicType[])]
          : (hits as SearchTopicType[]);
      }

      searchResults[category].totalHits = totalHits;
      searchResults[category].campaigns = campaigns;
      searchResults[category].sortItems = sortItems;
      searchResults[category].facets = facets;
    }
  }

  // There can be multiple redirects in a search result.
  // Rule: the 1st redirect in the campaigns array is the one to consider
  // TODO: What about multiple redirects in multiple search categories? (products, jobs, articles,...)
  function handleRedirects() {
    const categories = Object.keys(FactFinderConfig);
    const redirects = categories
      .map((category) => {
        const { campaigns } = searchResults[category];
        if (!campaigns) return undefined;
        return campaigns.filter(
          (campaign) => campaign.flavour === FactFinderCampaignFlavour.redirect,
        );
      })
      .filter((category) => category)
      .flat() as FactFinderCampaign[];
    if (!redirects.length) return false;

    // Called when using Browser back button to avoid infinite redirect loop
    history.pushState({ url: redirectUrl.value }, "", redirectUrl.value);
    window.location.href = redirects[0].target.destination;
    return true;
  }

  function setRedirectUrl(url: string) {
    redirectUrl.value = url;
  }

  /**
   * Update filter-url and get new list from fact-finder
   *
   * @param params Factfinder params
   * @param removeFilter Remove filter from url-query if nothing is set
   * @param resetPaging Set currentPage to 1 e.g. after facets change
   */
  function updateFilterUrl(
    params: Partial<FactFinderParams> = {},
    removeFilter: string | null = null,
    resetPaging = false,
  ) {
    const newUrlQuery: Partial<FactFinderParams> = {
      ...(urlQuery.value as FactFinderParams),
      ...params,
      ...(resetPaging && { page: 1 }),
    };

    if (removeFilter) {
      delete newUrlQuery[removeFilter];
    }

    const urlPushQuery = queryString.stringify(newUrlQuery, {
      arrayFormat: "comma",
    });
    router.push(`?${urlPushQuery}`);

    setQuery(newUrlQuery);
  }

  function removeAllFilters() {
    // Preserve the "search" parameter if set
    urlQuery.value = {
      ...(route.query.search &&
        typeof route.query.search === "string" && { search: route.query.search }),
    };
    updateFilterUrl();
  }

  function removeSingleFilter(associatedFieldName: string, selectedFacet: FactFinderFacet) {
    const facet = urlQuery.value[associatedFieldName];

    if (
      !Object.keys(selectedFacet).includes("selectedMinValue") && // Slider
      Array.isArray(facet) &&
      facet.length > 1
    ) {
      // Multiple items from this facet selected; remove only one item
      const newFacet = facet.filter((facetValue) => selectedFacet.text !== facetValue);
      const newFilter: Record<string, string[]> = {};
      newFilter[associatedFieldName] = newFacet;
      updateFilterUrl(newFilter);
    } else {
      // Only one item from this facet is selected OR Slider; remove entirely
      updateFilterUrl({}, associatedFieldName);
    }
  }

  function getConfigForCategory(category: keyof typeof FactFinderConfig) {
    return FactFinderConfig[category];
  }

  async function loadMoreTopics(page: string) {
    urlQuery.value = {
      ...urlQuery.value,
      page,
    };
    await getResultsForCategory(SearchCategory.topics, false, true);
  }

  /**
   * Trigger search (submit) from the search flyout
   * Optionally, define a scroll target if user clicks on a specific link
   */
  function triggerSearchFromFlyout(scrollTo?: SearchMode) {
    setRedirectUrl(route.fullPath);

    // Get verbose search mode type (all, products, topics etc.)
    const modeKey = getSearchModeKey(searchModeSuggestions.value);
    // Persist search term and mode in the store
    searchTerm.value = searchTermSuggestions.value;
    searchMode.value = searchModeSuggestions.value;

    router.push({
      path: getPath("search"),
      query: {
        search: searchTermSuggestions.value,
        mode: modeKey,
      },
      // Only scroll if searchMode = all AND a scroll target is set
      ...(searchMode.value === SearchMode.all &&
        scrollTo && { hash: "#" + getSearchModeKey(scrollTo) }),
    });

    showSearchOverlay.value = false;
  }

  return {
    showSearchOverlay,
    resetResults,
    searchResults,
    setQuery,
    setRedirectUrl,
    isLoading,
    isLoadingSuggestions,
    updateFilterUrl,
    currentPage,
    hitsPerPage,
    urlQuery,
    getSuggestions,
    resetSuggestions,
    topicSuggestions,
    productSuggestions,
    removeAllFilters,
    removeSingleFilter,
    searchMode,
    searchModeSuggestions,
    searchTerm,
    searchTermSuggestions,
    loadMoreTopics,
    getConfigForCategory,
    triggerSearchFromFlyout,
    showMobileSearch,
  };
});

// Helper Functions
function getEmptySearchResultGroup() {
  return {
    totalHits: 0,
    hits: [],
    campaigns: [],
  } as SearchResultGroup;
}

function getEmptySearchResults() {
  const res = {} as Record<string, SearchResultGroup>;
  const keys = Object.keys(SearchCategory);
  keys.forEach((key) => {
    res[key] = getEmptySearchResultGroup();
  });
  return res;
}

/**
 * Get verbose search mode type (all, products, topics etc.)
 */
function getSearchModeKey(mode: SearchMode) {
  return (Object.keys(SearchMode) as SearchModeKey[]).find((key) => SearchMode[key] === mode);
}
