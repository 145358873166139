<template>
  <div v-if="product" class="spar-product-details">
    <spar-product-details-info
      v-if="
        product.productClassificationStore?.consumerStorageInstructions ||
        product.productClassificationStore?.consumerUsageInstructions ||
        product.productClassificationStore?.contactName
      "
      :content-id="`content_${idProductDetails}`"
      :label-id="`label_${idProductDetails}`"
      :category-title="$t('pdp.product_details.title')"
    >
      <template #content>
        <dl
          v-if="
            product.productClassificationStore?.consumerStorageInstructions &&
            product.productClassificationStore.consumerStorageInstructions.values
          "
          class="spar-product-details spar-product-details__row"
        >
          <dt class="spar-product-details__label">
            {{ product.productClassificationStore.consumerStorageInstructions.feName }}:
          </dt>
          <dd
            v-for="(value, i) in getValueStrings(
              product.productClassificationStore.consumerStorageInstructions.values,
            )"
            :key="i"
            class="spar-product-details__col"
          >
            {{ value }}
          </dd>
        </dl>

        <dl
          v-if="
            product.productClassificationStore?.consumerUsageInstructions &&
            product.productClassificationStore.consumerUsageInstructions.values
          "
          class="spar-product-details spar-product-details__row"
        >
          <dt class="spar-product-details__label">
            {{ product.productClassificationStore.consumerUsageInstructions.feName }}:
          </dt>
          <dd
            v-for="(value, i) in getValueStrings(
              product.productClassificationStore.consumerUsageInstructions.values,
            )"
            :key="i"
            class="spar-product-details__col"
          >
            {{ value }}
          </dd>
        </dl>

        <dl
          v-if="product.productClassificationStore?.contactName"
          class="spar-product-details__row"
        >
          <dt class="spar-product-details__label">
            {{ product.productClassificationStore.contactName.feName }}:
          </dt>
          <div
            v-if="product.productClassificationStore.contactName.values"
            class="spar-product-details__col"
          >
            <dd
              v-for="(value, i) in getValueStrings(
                product.productClassificationStore.contactName.values,
              )"
              :key="i"
            >
              {{ value }}
            </dd>
            <dd v-if="product.productClassificationStore.contactAddress.values">
              <span
                v-for="(value, i) in getValueStrings(
                  product.productClassificationStore.contactAddress.values,
                )"
                :key="i"
              >
                {{ value }}
              </span>
            </dd>
          </div>
        </dl>
      </template>
    </spar-product-details-info>

    <!-- Accordion Nutrition values & allergians -->
    <spar-product-details-info
      v-if="product.allergeneInfo?.allergenes || product.nutrition?.nutritionTable"
      :content-id="`content_${idAllergeneNutrition}`"
      :label-id="`label_${idAllergeneNutrition}`"
      :category-title="$t('pdp.product_details.allergenes_nutrition')"
    >
      <template #content>
        <dl
          v-if="product.allergeneInfo?.allergenes && product.allergeneInfo.allergenes.values"
          class="spar-product-details__content"
        >
          <dd
            v-for="(value, i) in getValueStrings(product.allergeneInfo.allergenes.values)"
            :key="i"
          >
            {{ value }}
          </dd>
        </dl>
        <spar-nutrition-table v-if="product.nutrition" :nutrition="product.nutrition" />
      </template>
    </spar-product-details-info>

    <!-- TEMPORARY Dump the entire productClassificationStore  -->
    <spar-product-details-info
      v-if="product.productClassificationStore"
      :content-id="`content_${idProductClassificationStore}`"
      :label-id="`label_${idProductClassificationStore}`"
      category-title="productClassificationStore"
    >
      <template #content>
        <dl
          v-for="(attribute, key) in product.productClassificationStore"
          :key="key"
          class="spar-product-details__row"
        >
          <dt class="spar-product-details__label">{{ attribute.feName }} ({{ key }}):</dt>
          <dd v-if="attribute.values" class="spar-product-details__col">
            <p v-for="(value, i) in getValueStrings(attribute.values)" :key="i">
              {{ value }}
            </p>

            <p v-for="(value, i) in getValueBooleans(attribute.values)" :key="i">
              {{ value }}
            </p>
          </dd>
        </dl>
      </template>
    </spar-product-details-info>

    <!-- TEMPORARY Dump the entire classificationStoreAttribute  -->
    <spar-product-details-info
      v-if="product.classificationStoreAttribute"
      :content-id="`content_${idClassificationStoreAttribute}`"
      :label-id="`label_${idClassificationStoreAttribute}`"
      category-title="classificationStoreAttribute"
    >
      <template #content>
        <dl
          v-for="(attribute, key) in product.classificationStoreAttribute"
          :key="key"
          class="spar-product-details__row"
        >
          <dt class="spar-product-details__label">{{ attribute.feName }} ({{ key }}):</dt>
          <dd v-if="attribute.values" class="spar-product-details__col">
            <p v-for="(value, i) in getValueStrings(attribute.values)" :key="i">
              {{ value }}
            </p>

            <p v-for="(value, i) in getValueBooleans(attribute.values)" :key="i">
              {{ value }}
            </p>
          </dd>
        </dl>
      </template>
    </spar-product-details-info>
  </div>
</template>

<script lang="ts" setup>
import SparNutritionTable from "~/components/shared/SparNutritionTable/SparNutritionTable.vue";
import SparProductDetailsInfo from "~/components/shared/SparProductDetailsInfo/SparProductDetailsInfo.vue";
import getId from "~/composables/getId/getId";
import { getValueBooleans, getValueStrings } from "~/utils/mdsa/ui";

const { product } = storeToRefs(useMdsaStore());

const idProductDetails = getId("productDetails");
const idAllergeneNutrition = getId("allergeneNutrition");
const idProductClassificationStore = getId("productClassificationStore");
const idClassificationStoreAttribute = getId("classificationStoreAttribute");
</script>

<style lang="scss">
@use "./SparProductDetails.scss";
</style>
