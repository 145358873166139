export function useTurnstile() {
  const turnstileToken = ref("");
  const runtimeConfig = useRuntimeConfig();

  const renderTurnstile = (turnstileElement: HTMLElement | null) => {
    window.turnstile.ready(function () {
      window.turnstile.render(turnstileElement, {
        sitekey: runtimeConfig.public.turnstileSiteKey,
        callback: (token: string) => {
          turnstileToken.value = token;
        },
      });
    });
  };

  return {
    turnstileToken,
    renderTurnstile,
  };
}
