import type { SparCookieListProps } from "./SparCookieList.types";

export const sparCookieListMock: SparCookieListProps = {
  apiLink: "https://delivery.consentmanager.net/delivery/cookieinfo.php?id=19458",
  heading: {
    title: "Cookies interspar.at",
    description: "description",
    level: "2",
    headlineStyle: "2",
  },
  name: "cmpcookieinfo",
};
