import type { LeafletConfig, PaperFull } from "@shop-storefront/integration-ipaper";
import type { LeafletsConfig } from "@shop-storefront/utils";
import { defineStore } from "pinia";
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";
import { getMiddlwareUrl } from "~/utils/ui";

interface Region {
  key: string;
  value: string;
}

interface LeafletOption extends Region {
  url: string;
}

export const useIpaperStore = defineStore("ipaper", () => {
  const papers: Ref<PaperFull[]> = ref([]); // Papers within publication period
  const ipaperConfig = ref<LeafletConfig>();

  const regions: Ref<Region[]> = ref([]);
  const parentFolder = ref("");
  const genericFolders: Ref<string[]> = ref([]);

  const selectedRegion = ref<Region>();
  const selectedLeaflet = ref<LeafletOption>();
  const leafletOptions: Ref<LeafletOption[]> = ref([]);
  const hasError = ref(false);

  async function getAllPapers() {
    const uri = getMiddlwareUrl(useRuntimeConfig()) + servicesEndpoints.ipaper.getPapers;
    try {
      const res = await useFetch<PaperFull[]>(uri, {
        method: "post",
        key: "getAllPapers",
      });
      if (res.error.value || !Array.isArray(res.data.value)) throw new Error();
      setPapers(res.data.value);
    } catch (error) {
      hasError.value = true;
    }
  }

  function setPapers(newPapers: PaperFull[]) {
    papers.value = newPapers;
  }

  function setRegions(newRegions: Region[]) {
    regions.value = newRegions;
  }

  function setSelectedRegion(newRegion: string, defaultRegion: string) {
    let regionToSelect = defaultRegion;
    if (regions.value.some((region) => region.key === newRegion)) {
      regionToSelect = newRegion;
    }
    selectedRegion.value = regions.value.find((region: Region) => region.key === regionToSelect);
  }

  function setParentFolder(newParentFolder: string) {
    parentFolder.value = newParentFolder;
  }

  function setGenericFolders(newGenericFolders: string[]) {
    genericFolders.value = newGenericFolders;
  }

  function setIpaperConfig(newConfig: LeafletsConfig) {
    ipaperConfig.value = {
      parentFolder: newConfig.parentFolder,
      defaultRegion: newConfig.defaultRegion,
      genericFolders: newConfig.genericFolders?.map((folder) => folder.folder) || [],
      regionOptions: newConfig.regions.value,
    };
  }

  // Return papers for selected region
  // Sorting: 1) Region Papers 2) Generic Papers
  const papersForSelectedRegion = computed(() => {
    if (!selectedRegion.value) return [];

    // Sort generic folders as sorted in Contentstack
    const sortFolders = [selectedRegion.value.key, ...genericFolders.value];
    const res: PaperFull[] = [];

    sortFolders.forEach((folder) => {
      papers.value.forEach((paper) => {
        const region = getRegionFromUrl(paper.url);
        if (region === folder) {
          res.push(paper);
        }
      });
    });
    return res;
  });

  function setLeafletOptions() {
    leafletOptions.value = papersForSelectedRegion.value.map((leaflet) => {
      return {
        key: getLeafletKeyFromUrl(leaflet.url),
        value: leaflet.name,
        url: leaflet.url,
      };
    });
  }

  function setSelectedLeaflet(leafletId: string) {
    if (leafletOptions.value.some((option: LeafletOption) => option.key === leafletId)) {
      selectedLeaflet.value = leafletOptions.value.find(
        (option: LeafletOption) => option.key === leafletId,
      );
    } else {
      const firstOption = leafletOptions.value.find((option, index) => index === 0);
      selectedLeaflet.value = firstOption;
    }
  }

  function getRegionFromUrl(url: string) {
    const newUrl = url.replace(parentFolder.value, "");
    const parts = newUrl.split("/").filter((part) => part);
    return parts[0];
  }

  function getLeafletKeyFromUrl(url: string) {
    const newUrl = url.replace(parentFolder.value, "");
    const parts = newUrl.split("/").filter((part) => part);
    return parts[1];
  }

  function getLeafletUrl(): string {
    const leaflet = leafletOptions.value.find(
      (leaflet: LeafletOption) => leaflet.key === selectedLeaflet.value?.key,
    );
    return leaflet?.url || "";
  }

  function getSanitizedLeafletName(name: string) {
    const substring = name.match(/^([0-9]+-)+(Sonderfolder-|Folder-)?/gi);
    if (substring && substring.length) {
      return name.replace(substring[0], "").replace(".pdf", "");
    }

    return name;
  }

  function checkValidity(): boolean {
    return leafletOptions.value.some((paper) => paper.key === selectedLeaflet.value?.key);
  }

  async function init(region: string) {
    if (!ipaperConfig.value) return;

    setParentFolder(ipaperConfig.value.parentFolder);
    setGenericFolders(ipaperConfig.value.genericFolders);
    setRegions(ipaperConfig.value.regionOptions);
    setSelectedRegion(region, ipaperConfig.value.defaultRegion);

    await getAllPapers();
  }

  return {
    checkValidity,
    getLeafletKeyFromUrl,
    getLeafletUrl,
    getSanitizedLeafletName,
    init,
    ipaperConfig,
    leafletOptions,
    papers,
    papersForSelectedRegion,
    regions,
    selectedLeaflet,
    selectedRegion,
    setIpaperConfig,
    setLeafletOptions,
    setSelectedLeaflet,
    setSelectedRegion,
  };
});
