import { v4 as uuid } from "uuid";
import type { CorrelationHeaders } from "~/utils/cookies/correlation-headers.types";

/**
 * Function with useCookie should only be used in plugins, components, pages
 * for automatic ssr management.
 */
export function getCorrelationHeaders(): CorrelationHeaders {
  const correlationSessionCookie = useCookie("correlation-session");

  if (!correlationSessionCookie.value) {
    correlationSessionCookie.value = uuid();
  }

  return {
    "X-Correlation-ID": uuid(),
    "X-Correlation-Session": correlationSessionCookie.value,
  };
}
