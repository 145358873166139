import { default as indexyRhJJDcA5AMeta } from "~/pages/content/index.vue?macro=true";
import { default as component_45stubycgWkJiSFBMeta } from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubycgWkJiSFB } from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "contentpage",
    path: "/:slug*",
    component: () => import("~/pages/content/index.vue")
  },
  {
    name: component_45stubycgWkJiSFBMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubycgWkJiSFB
  }
]