const ContentType = {
  footer: "footer",
  banner: "banner",
  brandsDetailsPage: "brands_details_page",
  brandListPage: "brand_list_page",
  contentPage: "content_page",
  categoryPage: "category_page",
  header: "header",
  headerReduced: "header_reduced",
  cart: "cart_page",
  translation: "translation",
  uspSection: "usp_section",
  footerUsp: "footer_usp",
  leafletsConfig: "leaflets_config",
  leafletsDetailsPage: "leaflets_details_page",
  leafletsOverviewPage: "leaflets_overview_page",
  locationDetailsPage: "location_details_page",
  locationListPage: "location_list_page",
  loginPage: "login_page",
  logoutPage: "logout_page",
  productPage: "product_page",
  registrationPage: "registration_page",
  registrationConfirmationPage: "registration_confirmation_page",
  resetPasswordPage: "reset_password_page",
  forgottenPasswordPage: "forgotten_password_page",
  searchResultsPage: "search_results_page",
  navigationElement: "navigation_element",
  routerConfig: "router_config",
} as const;

export default ContentType;
