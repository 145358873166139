<template>
  <div class="banner" :data-cslp="cntsLp?.component?.['data-cslp']">
    <spar-link v-bind="links" :icon="undefined">
      <div v-if="bannerImage" class="banner__media-img-wrapper">
        <spar-adaptive-image
          v-if="backgroundImg"
          class="banner__media-img"
          :image="backgroundImg"
        />
      </div>
      <div
        class="banner__data-container"
        :class="positionCtaBtn && getButtonContainerClass(positionCtaBtn)"
      >
        <p v-if="!bannerImage" class="banner__headline">{{ headline }}</p>

        <div
          v-if="links?.label"
          class="banner__link-btn btn btn--primary btn--icon btn--icon-right"
          :class="{ 'btn--light': !bannerImage }"
        >
          {{ links.label }}
          <spar-icon-sprite class="link__icon" symbol="arrow-right" />
        </div>
      </div>
    </spar-link>
  </div>
</template>

<script lang="ts" setup>
import SparAdaptiveImage from "~/components/shared/SparAdaptiveImage/SparAdaptiveImage.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import { PositionCtaBtn, type SparBannerProps } from "./SparBanner.types";

const props = defineProps<SparBannerProps>();

//creating the correct css class for the btn
const getButtonContainerClass = (positionCtaBtn: PositionCtaBtn) => {
  if (!props.bannerImage) return;
  if (positionCtaBtn === PositionCtaBtn.center) return "banner__cta-btn--centered";
  if (positionCtaBtn === PositionCtaBtn.right) return "banner__cta-btn--right";
  if (positionCtaBtn === PositionCtaBtn.left) return "banner__cta-btn--left";
};

defineOptions({
  inheritAttrs: false,
});
</script>

<style lang="scss">
@use "./SparBanner.scss";
</style>
