import type { RouteRecordRaw, Router } from "#vue-router";
import type { Route, RouterConfig } from "@shop-storefront/utils";
import { routes } from "~~/configs/nuxt-router.routes";
import type { ContentstackPlugin } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";

type SingleRoute = NonNullable<Route["routes"]>[number];

interface RouteNamePath {
  name: string;
  path: string;
}

// Use localized name from external source and return the full RouteRecordRaw array
function localizeRoutes(defaultRoutes: RouteRecordRaw[], localizedPaths: RouteNamePath[]) {
  return defaultRoutes.map((route) => {
    const { name } = route;
    if (!name) return route;

    const localized = localizedPaths.find((path) => path.name === name);
    if (!localized || localized.path === route.path) return route;

    // Spread date to not modify original object
    return { ...route, alias: route.path, path: localized.path };
  });
}

// Slug values from Contentstack do not have a slash at the beginning
const normalizePath = (path: string) => {
  if (path.substring(0, 1) !== "/") {
    return "/" + path;
  }
  return path;
};

function resolveContentstackRoute(route: SingleRoute): RouteNamePath | undefined {
  if (route.stringRoute?.routeName && route.stringRoute.routePath) {
    return {
      name: route.stringRoute.routeName,
      path: normalizePath(route.stringRoute.routePath),
    };
  }
  if (route.referenceRoute?.routeName && route.referenceRoute.routePath?.[0].slug) {
    return {
      name: route.referenceRoute.routeName,
      path: normalizePath(route.referenceRoute.routePath[0].slug),
    };
  }
}

async function getContentstackRoutes(contentstack: ContentstackPlugin) {
  const res = await contentstack.getEntries<RouterConfig[]>({
    type: ContentType.routerConfig,
    includeCount: true,
    includeReference: ["route.routes.reference_route.route_path"],
  });

  const ctsRoutes = res?.[0]?.[0].route?.routes;
  if (!ctsRoutes) return [];

  return ctsRoutes
    .map((ctsRoute) => resolveContentstackRoute(ctsRoute))
    .filter((item) => item) as RouteNamePath[];
}

// https://github.com/nuxt/nuxt/discussions/9970#discussioncomment-7059098
export default defineNuxtPlugin({
  async setup(nuxtApp) {
    const router = nuxtApp.$router as Router;
    const contentstack = nuxtApp.$contentstack as ContentstackPlugin;

    const res = await getContentstackRoutes(contentstack);
    const localizedRoutes = localizeRoutes(routes, res);

    Log.debug(LogArea.app, "injecting localized routes", localizedRoutes);

    localizedRoutes.forEach((route) => {
      router.addRoute(route);
    });
  },
});
