export type SortOptions = "name1" | "salesPrice" | "creationTime" | "onSale";
export type OrderOptions = "asc" | "desc";
export type FilterParams = string | string[] | number;

export interface Filters {
  [key: string]: FilterParams;
}

export interface FactFinderParams extends Filters {
  search: string;
  sort: SortOptions;
  order: OrderOptions;
  page: number;
  hits: number;
}

// Range uses tilde: 10~30
const getRange = (value: FilterParams): string | undefined => {
  if (typeof value !== "string") return undefined;
  if (!/^\d+~\d+$/.test(value)) return undefined;
  return value.split("~").join(",");
};

export const createQuery = ({
  search,
  sort,
  order = "asc",
  page,
  hits,
  ...rest
}: Partial<FactFinderParams>) => {
  const filter = Object.entries(rest).map(([key, value]) => {
    if (!value) return;

    // Range
    const range = getRange(value);
    if (range) {
      return `filter=${key}:${encodeURI("[")}${range}${encodeURI("]")}`;
    }

    // Multiple filters in same facet
    // Filters encoded with encodeURIComponent because terms can contain "&" (e.g. "süß & fruchtig")
    if (Array.isArray(value) && value.length) {
      return `filter=${key}:${value.map((val) => encodeURIComponent(val)).join("~~~")}`;
    }
    // Regular filter
    return `filter=${key}:${encodeURIComponent(value.toString())}`;
  });

  return [
    `query=${search ? encodeURIComponent(search) : "*"}`,
    sort && `sort=${sort}:${order}`,
    page && `page=${page}`,
    hits && `hitsPerPage=${hits}`,
    ...filter,
  ]
    .filter(Boolean)
    .join("&");
};

export default createQuery;
