import type { SparSelectOption } from "~/components/shared/SparSelect/SparSelect.types";
import type { SparTopHeaderProps } from "../SparTopHeader/SparTopHeader.types";

type TopHeaderSearch = NonNullable<SparTopHeaderProps["search"]>;

export interface SparSearchFieldProps extends TopHeaderSearch {
  selectHint?: string;
  options?: SparSelectOption[];
  toscaPrefix?: string;
}

export const SearchMode = {
  all: 0,
  products: 1,
  topics: 2,
} as const;
export type SearchMode = (typeof SearchMode)[keyof typeof SearchMode];
export type SearchModeKey = keyof typeof SearchMode;
