import type { Reference, ReferenceContentSections, StaticProps } from "@shop-storefront/utils";

export interface RenderSectionsProps {
  content: Reference[];
}

export type RenderSectionsContent = ReferenceContentSections & StaticProps;

export const RegisteredComponents = {
  SparAccordion: "SparAccordion",
  SparAnchor: "SparAnchor",
  SparBanner: "SparBanner",
  SparCookieList: "SparCookieList",
  SparContactForm: "SparContactForm",
  SparContentSlider: "SparContentSlider",
  SparFlexibleForm: "SparFlexibleForm",
  SparHeading: "SparHeading",
  SparHero: "SparHero",
  SparImageTeaser: "SparImageTeaser",
  SparImageTextTeaser: "SparImageTextTeaser",
  SparInspirationWorld: "SparInspirationWorld",
  SparTeaserblock: "SparTeaserblock",
  SparTextImageVideo: "SparTextImageVideo",
  SparTextTeaser: "SparTextTeaser",
  SparUsp: "SparUsp",
} as const;
export type RegisteredComponents = (typeof RegisteredComponents)[keyof typeof RegisteredComponents];
