<template>
  <div class="content-page">
    <template v-if="content?.[0]?.topSections">
      <render-sections :content="content[0].topSections" />
    </template>

    <div class="container">
      <spar-login>
        <template #heading>
          <spar-heading
            level="1"
            headline-style="1"
            class="heading--left"
            :title="$t('auth.login_form.title')"
          />
        </template>

        <template #subheading>
          <spar-heading level="2" class="heading--left" :title="$t('auth.login_form.subtitle')" />
        </template>

        <template #additionalContent>
          <spar-login-registration />
        </template>
      </spar-login>
    </div>

    <template v-if="content?.[0]?.bottomSections">
      <render-sections :content="content[0].bottomSections" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { LoginPage } from "@shop-storefront/utils";
import SparLogin from "~/components/feature/SparLogin/SparLogin.vue";
import SparLoginRegistration from "~/components/feature/SparLoginRegistration/SparLoginRegistration.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { loginPageReferences } from "~/utils/contentstack/constants/references.constants";

const { $contentstack } = useNuxtApp();
const { getSlug } = useRoutes();

const { data: content } = await useAsyncData("loginPage", async () => {
  return await $contentstack.getEntries<LoginPage>({
    type: ContentType.loginPage,
    customQuery: {
      [ContentstackPageParams.slug]: getSlug("login"),
    },
    includeReference: loginPageReferences as string[],
  });
});
</script>
