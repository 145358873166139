import type {
  ProductSchemaV2,
  ClassificationStoreAttribute,
  ProductSchemaV1AssetsInnerTypeEnum,
  ProductSchemaV2Nutrition,
} from "~/types/mdsa.types";

export const DeliveryMode = {
  ParcelWarehouse: "Parcel-Warehouse",
  Timeslot: "Timeslot",
} as const;
export type DeliveryMode = (typeof DeliveryMode)[keyof typeof DeliveryMode];

export const DeliveryModeVoucher = {
  VoucherPhysical: "Voucher-Physical",
  VoucherDigital: "Voucher-Digital",
} as const;
export type DeliveryModeVoucher = (typeof DeliveryModeVoucher)[keyof typeof DeliveryModeVoucher];

export const SparBaseStoreTypes = {
  national: "national",
  timeslot: "timeslot",
} as const;
export type SparBaseStoreTypes = (typeof SparBaseStoreTypes)[keyof typeof SparBaseStoreTypes];

export const BomType = {
  service: "service",
} as const;
export type BomType = (typeof BomType)[keyof typeof BomType];

export const ProductDocumentType = {
  pdfDatasheet: "pdf_datasheet",
} as const;
export type ProductDocumentType = (typeof ProductDocumentType)[keyof typeof ProductDocumentType];

export interface Breadcrumb {
  name: string;
  categoryId: string;
  level: number;
  link: string;
  target: string;
}

export interface ProductData {
  data: {
    products: SparMdsaProduct[];
  };
}

export interface Asset {
  type?: ProductSchemaV1AssetsInnerTypeEnum;
  position?: string;
  assetUrl?: string;
  servletBaseUrl?: string;
  progressiveUrl?: string;
  altText: string;
  colorMode?: string;
  heightPixel?: string;
  lastModifiedDate?: string;
  preview?: string;
  UID?: string;
  widthPixel?: string;
}

// Nutrition Object uses a generic typing due to Pimcore limitations
// We use our own local Interface, which was derived from the API data
// https://collaboration.spar.at/jira/browse/HYBS-18121
export interface SparMdsaProduct extends Omit<ProductSchemaV2, "nutrition"> {
  nutrition?: ProductSchemaNutrition;
}

export interface ProductSchemaNutrition extends Pick<ProductSchemaV2Nutrition, "nutritionTable"> {
  preparationInstructions?: ClassificationStoreAttribute;
  preparationState?: ClassificationStoreAttribute;
  preparationType?: ClassificationStoreAttribute;
  servingSizeDescription?: ClassificationStoreAttribute;
}
