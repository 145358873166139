<template>
  <div
    role="region"
    :aria-labelledby="labelId"
    class="spar-product-details-info__panel"
    :class="{
      'spar-product-details-info__panel--open': isOpen,
      'spar-product-details-info__panel--closed': !isOpen && showButton,
    }"
  >
    <h2>{{ categoryTitle }}</h2>
    <div :id="idDetail" ref="detailsContent" class="spar-product-details__content">
      <slot v-if="$slots.content" name="content" />
      <span v-else>{{ content }}</span>
    </div>
  </div>

  <div v-if="showButton" class="spar-product-details-info__preview">
    <!-- button in span to be able to simulate space to the line -->
    <span class="spar-prdouct-details-info__space">
      <spar-button
        :id="labelId"
        class="spar-products-details-info__trigger spar-product-details-info__expand-icon"
        :class="{
          'spar-product-details-info__panel--open': isOpen,
        }"
        type="button"
        :variant="ButtonVariant.custom"
        :aria-expanded="isOpen"
        icon="arrow-bottom"
        size="small"
        @click="toggle"
      >
        <span>{{ buttonLabel }}</span>
      </spar-button>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import getId from "~/composables/getId/getId";
import useI18n from "~/composables/i18n/useI18n";
import type { SparProductDetailsInfoProps } from "./SparProductDetailsInfo.types";

// TODO: This entire file needs a refactoring as soon as the final MDSA types are availabla

const props: SparProductDetailsInfoProps = defineProps({
  content: {
    type: String,
    default: null,
  },
  contentId: {
    type: String,
    required: true,
  },
  categoryTitle: {
    type: String,
    default: null,
  },
  defaultOpened: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  labelId: {
    type: String,
    required: true,
  },
});

const { $t } = useI18n(); // Explicit Import for Storybook

const emit = defineEmits(["open", "close"]);

defineExpose({
  showMore,
  showLess,
  toggle,
});

const isOpen = ref(props.defaultOpened);

const idDetail = getId("productDetailsInfo");
const detailsContent = ref<HTMLElement | null>(null);

const buttonLabel = computed(() => {
  return isOpen.value ? $t("pdp.product_details.show_less") : $t("pdp.product_details.show_more");
});

const showButton = computed(() => {
  return detailsContent.value && detailsContent.value.offsetHeight > 200;
});

function showMore() {
  emit("open");
  isOpen.value = true;

  if (!showButton) {
    // if not button no closed option
    isOpen.value = true;
  }
}

function showLess() {
  emit("close");
  isOpen.value = false;
}

function toggle() {
  if (isOpen.value) {
    showLess();
  } else {
    showMore();
  }
}
</script>

<style lang="scss">
@use "./SparProductDetailsInfo.scss";
</style>
