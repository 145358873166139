<template>
  <section class="location-list">
    <template v-if="content?.[0]?.topSections">
      <render-sections :content="content[0].topSections" />
    </template>

    <div class="container">Placeholder for Locations Map</div>

    <template v-if="content?.[0]?.middleSections">
      <render-sections :content="content[0].middleSections" />
    </template>

    <div class="container">Placeholder for Locations List</div>

    <template v-if="content?.[0]?.bottomSections">
      <render-sections :content="content[0].bottomSections" />
    </template>
  </section>
</template>

<script setup lang="ts">
import type { LocationListPage } from "@shop-storefront/utils";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { locationListPageReferences } from "~/utils/contentstack/constants/references.constants";
import { resolvePageSeo } from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.resolvers";

const { $contentstack } = useNuxtApp();
const { getSlug } = useRoutes();
const { fullPath } = useRoute();

const { data: content } = await useAsyncData("locationListPage", async () => {
  return await $contentstack.getEntries<LocationListPage>({
    type: ContentType.locationListPage,
    customQuery: {
      [ContentstackPageParams.slug]: getSlug("locationList"),
    },
    includeReference: locationListPageReferences as string[],
  });
});

if (!content.value?.length) {
  const { show404Page } = useErrorPage();
  show404Page(ErrorPageType.location, fullPath);
}

if (content?.value?.[0]?.seo) {
  useHead(resolvePageSeo(content.value[0].seo));
}
</script>

<style lang="scss">
@use "./SparLocationList.scss";
</style>
