import { zxcvbn } from "@zxcvbn-ts/core";

const options = {
  minChar: 8,
};

export const Strength = {
  veryWeak: "veryWeak",
  weak: "weak",
  medium: "medium",
  strong: "strong",
  veryStrong: "veryStrong",
} as const;
export type Strength = (typeof Strength)[keyof typeof Strength];

export const checkPasswordStrength = (password: string) => {
  const score = runPassword(password);

  switch (score) {
    case 0:
      return Strength.veryWeak;
    case 1:
      return Strength.weak;
    case 2:
      return Strength.medium;
    case 3:
      return Strength.strong;
    case 4:
      return Strength.veryStrong;
    default:
      return Strength.veryStrong;
  }
};

const runPassword = (password: string) => {
  if (password.length < options.minChar) {
    return 0;
  }

  const { score } = zxcvbn(password);
  return score;
};
