import { IconPosition } from "~/types/icon.types";

export const TooltipPosition = {
  ...IconPosition,
} as const;
export type TooltipPosition = (typeof TooltipPosition)[keyof typeof TooltipPosition];

export interface SparTooltipProps {
  ariaId: string;
  position: string;
  text: string;
  showLoader: boolean;
}
