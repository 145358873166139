import { client } from "@vsf-enterprise/sapcc-sdk";
import type {
  OAuthUserAuthenticationProps,
  OAuthUserTokenResponse,
} from "@vsf-enterprise/sapcc-types";
import type { GigyaResponse } from "~/composables/auth/gigya.types";
import type { PaymentDetailsBody } from "~/composables/payment/payment.types";
import type { Address, OrderEntry } from "~/types/occ.types";
import { SparBaseStoreTypes } from "~/utils/mdsa/integration/mdsa.types";

export const sapCcExtension = {
  extend: {
    getCartCheckout: async (cartId: string, userId: string, baseSite: string) => {
      const endpoint = "getCartCheckout";
      return await client.post(endpoint, { cartId, userId, baseSite });
    },
    getCartConsentTemplates: async (cartId: string, userId: string, baseSite: string) => {
      const endpoint = "getCartConsentTemplates";
      return await client.post(endpoint, { cartId, userId, baseSite });
    },
    giveCartConsent: async (
      consentTemplateId: string,
      consentTemplateVersion: number,
      cartId: string,
      userId: string,
      baseSite: string,
    ) => {
      const endpoint = "giveCartConsent";
      return await client.post(endpoint, {
        consentTemplateId,
        consentTemplateVersion,
        cartId,
        userId,
        baseSite,
      });
    },
    reduceProductQuantity: async (
      cartId: string,
      userId: string,
      baseSite: string,
      entry: OrderEntry,
    ) => {
      const endpoint = "reduceProductQuantity";
      return await client.post(endpoint, { cartId, userId, baseSite, entry });
    },
    placeOrderAfterRedirect: async (
      cartId: string,
      userId: string,
      paymentId: string,
      redirectMessageAuthenticationCode: string,
      baseSite: string,
    ) => {
      const endpoint = "placeOrderAfterRedirect";
      return await client.post(endpoint, {
        cartId,
        userId,
        paymentId,
        redirectMessageAuthenticationCode,
        baseSite,
      });
    },
    getPaymentModes: async (cartId: string, userId: string, baseSite: string) => {
      const endpoint = "getPaymentModes";
      return await client.post(endpoint, { cartId, userId, baseSite });
    },
    clearCart: async (cartId: string, userId: string, baseSite: string) => {
      const endpoint = "clearCart";
      return await client.post(endpoint, { cartId, userId, baseSite });
    },
    setBillingAddress: async (
      cartId: string,
      userId: string,
      address: Address,
      baseSite: string,
    ) => {
      const endpoint = "setBillingAddress";
      return await client.post(endpoint, { cartId, userId, address, baseSite });
    },
    getDeliveryModeTypes: async (cartId: string, userId: string, baseSite: string) => {
      const endpoint = "getDeliveryModeTypes";
      return await client.post(endpoint, { cartId, userId, baseSite });
    },
    setDeliveryModeType: async (
      cartId: string,
      userId: string,
      deliveryType: string,
      posName: string,
      baseSite: string,
    ) => {
      const endpoint = "setDeliveryModeType";
      return await client.post(endpoint, { cartId, userId, deliveryType, posName, baseSite });
    },
    validateCart: async (cartId: string, userId: string, baseSite: string) => {
      const endpoint = "validateCart";
      return await client.post(endpoint, { cartId, userId, baseSite });
    },
    getValidations: async (baseSite: string) => {
      const endpoint = "getValidations";
      return await client.post(endpoint, { baseSite });
    },
    initPaymentProvider: async (
      cartId: string,
      userId: string,
      paymentMode: string,
      baseSite: string,
      saved: boolean,
    ) => {
      const endpoint = "initPaymentProvider";
      return await client.post(endpoint, { cartId, userId, paymentMode, baseSite, saved });
    },
    getPaymentDetails: async (userId: string, paymentMode: string, baseSite: string) => {
      const endpoint = "getPaymentDetails";
      return await client.post(endpoint, { userId, paymentMode, baseSite });
    },
    getPaymentDetailsList: async (
      userId: string,
      baseSiteId: string,
      saved: boolean,
      onlyCreditCards: boolean,
      paymentMode?: string, // empty - delivers all payment details
    ) => {
      const endpoint = "getPaymentDetailsList";
      return await client.post(endpoint, {
        baseSiteId,
        userId,
        saved,
        onlyCreditCards,
        paymentMode,
      });
    },
    deletePaymentDetails: async (userId: string, paymentId: string, baseSite: string) => {
      const endpoint = "deletePaymentDetails";
      return await client.post(endpoint, { userId, paymentId, baseSite });
    },
    setPaymentDetails: async (params: PaymentDetailsBody) => {
      const endpoint = "setPaymentDetails";
      return await client.post(endpoint, params);
    },
    updateCartAddress: async (
      cartId: string,
      userId: string,
      addressId: string,
      address: Address,
      baseSite: string,
    ) => {
      const endpoint = "updateCartAddress";
      return await client.post(endpoint, { cartId, userId, addressId, address, baseSite });
    },
    signIn,
  },
};

interface SparOAuthUserAuthenticationProps extends OAuthUserAuthenticationProps {
  sessionExpiration: number;
}

// Login works with username and password OR with a "dummy" call to getAccountInfo
// -> Both calls return an id_token which can be used for login
async function signIn(params?: SparOAuthUserAuthenticationProps): Promise<GigyaResponse> {
  let eventObj;

  if (params?.username && params.password) {
    const { username, password, sessionExpiration } = params;
    eventObj = await new Promise<GigyaResponse>((resolve) => {
      window.gigya.accounts.login({
        loginID: username,
        password,
        ignoreInterruptions: true,
        include: "id_token",
        sessionExpiration,
        callback: resolve,
      });
    });
  } else {
    eventObj = await new Promise<GigyaResponse>((resolve) => {
      window.gigya.accounts.getAccountInfo({
        callback: resolve,
      });
    });
  }

  // don't try oauth when cdc has an error
  if (eventObj.errorCode === 0) {
    await client.post<OAuthUserTokenResponse>("OAuthUserAuthorization", {
      username: JSON.stringify({ ...eventObj, baseSite: SparBaseStoreTypes.national }),
    });
  }

  return eventObj;
}
