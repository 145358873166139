<template>
  <div class="address__block" :class="{ 'address__block--no-border': !border }">
    <div class="address__wrapper" data-tosca="address-wrapper">
      <div v-if="heading" class="address__title">
        {{ heading }}
      </div>
      <address
        v-if="address.id"
        class="address__box"
        :data-tosca="getToscaPrefix('address', toscaPrefix)"
      >
        <div v-if="selectable" class="address__info">
          <spar-radio
            tosca-prefix="checkout-delivery-address"
            name="addressGroup"
            :val="address.id"
            @change="emit('select')"
          >
            <spar-address-snippet :address="address"></spar-address-snippet>
          </spar-radio>
        </div>

        <spar-address-snippet v-else :address="address"></spar-address-snippet>

        <button
          v-if="editable"
          class="address__edit btn--clear"
          data-tosca="checkout-delivery-edit-address"
          :aria-label="$t('address.editable.label')"
          @click="emit('edit')"
        >
          <spar-icon-sprite symbol="edit"></spar-icon-sprite>
        </button>
      </address>
      <div v-if="infoText" class="address__info-text">{{ infoText }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparRadio from "~/components/shared/SparRadio/SparRadio.vue";
import useI18n from "~/composables/i18n/useI18n";
import type { Address } from "~/types/occ.types";
import { getToscaPrefix } from "~/utils/ui";
import type { SparAddressProps } from "./SparAddress.types";
import SparAddressSnippet from "./SparAddressSnippet/SparAddressSnippet.vue";

defineProps({
  address: {
    type: Object as PropType<Address>,
    required: true,
  },
  editable: {
    type: Boolean,
    default: false,
  },
  selectable: {
    type: Boolean,
    default: false,
  },
  heading: {
    type: String,
    default: undefined,
  },
  infoText: {
    type: String,
    default: undefined,
  },
  border: {
    type: Boolean,
    default: true,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
}) as SparAddressProps;

const emit = defineEmits(["edit", "select"]);
const { $t } = useI18n(); // Explicit Import for Storybook
</script>

<style lang="scss">
@use "./SparAddress.scss";
</style>
