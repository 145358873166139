<template>
  <spar-select
    v-model="sorting"
    :label="$t('plp.searchParams.sorting.label')"
    :options="sortingOptions"
    tosca-prefix="plp-sorting"
    :label-position="LabelPosition.overlap"
  />
</template>

<script lang="ts" setup>
import type { SparSelectOption } from "~/components/shared/SparSelect/SparSelect.types";
import { LabelPosition } from "~/components/shared/SparSelect/SparSelect.types";
import SparSelect from "~/components/shared/SparSelect/SparSelect.vue";
import useI18n from "~/composables/i18n/useI18n";
import { useSearchStore } from "~/stores/search.store";
import type { SortOptions, OrderOptions } from "~/utils/factfinder/createQuery";
import type { SortedItem } from "~/utils/factfinder/integration/factfinder.types";

const props = defineProps({
  searchSorting: {
    type: Object as PropType<SortedItem[]>,
    required: true,
  },
});

const { $t } = useI18n();
const searchStore = useSearchStore();

const sorting = ref("");
const sortingActive = ref({});
const sortingOptions: SparSelectOption[] = props.searchSorting.map((option) => {
  const key = `${option.name}:${option.order}`;

  if (option.selected) {
    sorting.value = key;
    sortingActive.value = option;
  }

  return {
    key,
    value: option.description,
  };
});

watch(
  () => sorting.value,
  async (value) => {
    if (value !== undefined) {
      const querySort = value.split(":");
      const sort: SortOptions = querySort[0] as SortOptions;
      const order: OrderOptions = querySort[1] as OrderOptions;
      if (querySort.length) {
        searchStore.updateFilterUrl({ sort, order });
      }
    }
  },
);
</script>
