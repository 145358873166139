<template>
  <div class="search-result-page container">
    <h2>Mail Integration</h2>
    <p>Form for testing the mail integration</p>
    <form @submit.prevent="sendMail">
      <spar-input v-model="data.subject" label="Subject" />
      <spar-textarea v-model="data.content" label="Content" />
      <spar-input v-model="data.email" label="Email Address" :type="SparInputType.email" />
      <div ref="turnstileElement" data-theme="light"></div>
      <spar-button type="submit" class="btn--primary">submit</spar-button>
      <spar-alert v-if="hasError" :type="SparAlertType.error" :is-closable="false">
        failed to send Email
      </spar-alert>
      <spar-alert v-if="hasSuccess" :type="SparAlertType.success" :is-closable="false">
        Email successfully sent!
      </spar-alert>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { SparAlertType } from "~/components/shared/SparAlert/SparAlert.types";
import SparAlert from "~/components/shared/SparAlert/SparAlert.vue";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { SparInputType } from "~/components/shared/SparInput/SparInput.types";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import SparTextarea from "~/components/shared/SparTextarea/SparTextarea.vue";
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";
import { getMiddlwareUrl } from "~/utils/ui";

const data = ref({
  subject: "",
  content: "",
  email: "",
});

const turnstileElement = ref(null);
const hasError = ref(false);
const hasSuccess = ref(false);
const { renderTurnstile, turnstileToken } = useTurnstile();

onMounted(() => {
  renderTurnstile(turnstileElement.value);
});

async function sendMail() {
  const uri = getMiddlwareUrl(useRuntimeConfig()) + servicesEndpoints.mail.send;
  const payload = {
    message: {
      subject: data.value.subject,
      body: {
        contentType: "Text",
        content: data.value.content,
      },
      toRecipients: [
        {
          emailAddress: {
            address: data.value.email,
          },
        },
      ],
    },
  };
  try {
    const { error } = await useFetch(uri, {
      method: "post",
      body: { mailConfig: JSON.stringify(payload), token: turnstileToken.value },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (error.value) throw new Error();
    hasSuccess.value = true;
    hasError.value = false;
  } catch (error) {
    hasError.value = true;
    hasSuccess.value = false;
  }
}
</script>
