export const Action = {
  Login: "login",
  Pending: "pending",
} as const;
export type Action = (typeof Action)[keyof typeof Action];

export const GigyaError = {
  Pending: 206002, // Account Pending Verification
  ValidationError: 400006, // setAccountInfo validation
  Invalid: 403042,
  Disabled: 403041,
  NotFound: 403047,
  SDKError: 999999, // This is not an actual Gigya error!
} as const;
export type GigyaError = (typeof GigyaError)[keyof typeof GigyaError];
