import { defineStore } from "pinia";
import { useBaseSiteStore } from "~/stores/basesite.store";
import { useCheckoutStore } from "~/stores/checkout.store";
import type { Countries, CountryType } from "~/types/country.types";
import type { CountryList } from "~/types/occ.types";
import { CountryError, handleCommerceError } from "~/utils/error";
import { SparBaseStoreTypes } from "~/utils/mdsa/integration/mdsa.types";

export const useCountryStore = defineStore("country", () => {
  const { sdk } = useVsfSdk();
  const { $t } = useI18n();

  const { cartContext } = storeToRefs(useCheckoutStore());
  const { baseSiteConfig } = useBaseSiteStore();

  // When adding new state variables, also reset them in $reset method
  const countries = reactive<Countries>({
    BILLING: undefined,
    SHIPPING: undefined,
  });
  const getCountriesLoading = ref(false);

  /**
   * Get available shipping/billing countries
   *
   * @param {CountryType[]} countryTypes E.g. BILLING, SHIPPING, or both in an array
   */
  async function getCountries(countryTypes: CountryType[]): Promise<void> {
    if (getCountriesLoading.value) return;
    getCountriesLoading.value = true;

    try {
      for (const countryType of countryTypes) {
        if (countries[countryType]) continue;

        countries[countryType] = (await sdk.commerce.getCountries(
          { type: countryType },
          baseSiteConfig(cartContext.value?.bs || SparBaseStoreTypes.national),
        )) as CountryList | undefined;
      }
    } catch (error) {
      handleCommerceError(error as Error);
      throw new CountryError($t("country.error.could_not_load"));
    } finally {
      getCountriesLoading.value = false;
    }
  }

  /**
   * Reset the store
   */
  function $reset(): void {
    countries.BILLING = undefined;
    countries.SHIPPING = undefined;
    getCountriesLoading.value = false;
  }

  return {
    countries,
    getCountries,
    getCountriesLoading,
    $reset,
  };
});
