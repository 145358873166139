<template>
  <div class="brandlist container">
    <div class="brandlist__section brandlist__breadcrumbs">Placeholder Breadcrumbs</div>
    <div class="brandlist__columns">
      <div class="brandlist__column--left brandlist__section container">Placeholder Sidenav</div>
      <div class="brandlist__column--right">
        <section class="brandlist__section brandlist__top-section">
          <render-sections v-if="topSections.length" :content="topSections" />

          <div v-if="content?.[0]?.topSection?.pageText" class="brandlist__page-text">
            {{ content[0].topSection.pageText }}
          </div>
        </section>

        <div class="brand__navigation-container">
          <nav class="brand__navigation">
            <ul class="brand__navigation-list">
              <li v-for="letter in letters" :key="letter" class="brand__navigation-list">
                <spar-button
                  class="brand__letter"
                  :variant="ButtonVariant.custom"
                  :aria-label="`${$t('brand.list.navigation.letter.label')} ${letter}`"
                  :disabled="isLetterDisabled(letter)"
                  tosca-prefix="brand-list-nav-letter"
                  @click="scrollToBrand(letter)"
                >
                  {{ letter }}
                </spar-button>
              </li>
            </ul>
          </nav>
        </div>

        <div class="brandlist__container brandlist__section">
          <div class="brand-list">
            <div v-for="letter in enabledLetters" :key="letter">
              <h2 :id="`heading-${letter}`" class="brand__heading">
                {{ letter }}
              </h2>
              <div class="brand__item-wrapper">
                <ul class="brand__item-list">
                  <li
                    v-for="brand in getLetterBrands(letter)"
                    :key="brand.brandName"
                    class="brand__item"
                  >
                    <NuxtLink
                      v-if="brand.slug"
                      :to="getDetailsUrl(Boolean(brand.localProducer), brand.slug)"
                      class="brand__item-link"
                    >
                      <span class="brand__item-brandname">{{ brand.brandName }}</span>

                      <span v-if="brand.localProducer" class="brand__item-localproducer">
                        {{ $t("brands.local_producer.label") }}
                      </span>
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <section v-if="content?.[0]?.bottomSections?.length" class="brandlist__bottom-section">
          <render-sections :content="content[0].bottomSections" />
        </section>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BrandListPage, BrandsDetailsPage } from "@shop-storefront/utils";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { brandListPageReferences } from "~/utils/contentstack/constants/references.constants";
import { resolvePageSeo } from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.resolvers";
import { getAlphabet } from "~/utils/ui";

const { $contentstack } = useNuxtApp();
const letters = getAlphabet(true);
const { getPath, getSlug } = useRoutes();

const getDetailsUrl = (localProducer: boolean, slug: string) => {
  return localProducer
    ? `${getPath("brandsDetailLocalProducer")}/${slug}`
    : `${getPath("brandsDetail")}/${slug}`;
};

const { data: content } = await useAsyncData(ContentType.brandListPage, async () => {
  return await $contentstack.getEntries<BrandListPage>({
    type: ContentType.brandListPage,
    customQuery: {
      [ContentstackPageParams.slug]: getSlug("brands"),
    },
    includeReference: brandListPageReferences as string[],
  });
});

const { data: contentDetailpage } = await useAsyncData(ContentType.brandsDetailsPage, async () => {
  const res = await $contentstack.getEntries<BrandsDetailsPage[]>({
    type: ContentType.brandsDetailsPage,
    includeCount: true,
  });
  return res[0];
});

const topSections = computed(() => {
  if (!content.value?.[0].topSection?.topSections) return [];

  return [
    {
      content: content.value[0].topSection.topSections,
    },
  ];
});

const scrollToBrand = (letter: string) => {
  const headingElement = document.getElementById(`heading-${letter}`);
  if (headingElement) {
    headingElement.scrollIntoView({ behavior: "smooth" });
  }
};

// IN: "Ölmühle Hamlitsch" - OUT: "O"
const getFirstLetterConverted = (word: string) => {
  const startingLetter = word.charAt(0).toUpperCase();
  return startingLetter.replace("Ö", "O").replace("Ä", "A").replace("Ü", "U");
};

const enabledLetters = letters.filter((letter) => {
  return contentDetailpage.value?.some(
    (brand) => letter === getFirstLetterConverted(brand.brandName),
  );
});

const isLetterDisabled = (letter: string) => !enabledLetters.includes(letter);

const getLetterBrands = (letter: string) => {
  const res =
    contentDetailpage.value?.filter(
      (brand) => letter === getFirstLetterConverted(brand.brandName),
    ) || [];
  return res.sort((a, b) => a.brandName.localeCompare(b.brandName));
};

if (content?.value?.[0]?.seo) {
  useHead(resolvePageSeo(content.value[0].seo));
}
</script>

<style lang="scss">
@use "./SparBrandsList.scss";
</style>
