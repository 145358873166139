import useI18n from "~/composables/i18n/useI18n";
import type { FactFinderFacet } from "~/utils/factfinder/integration/factfinder.types";

const { $t } = useI18n();

export function useFacets() {
  // handle the output of label in facets
  const getLabel = (facet: FactFinderFacet, includeCount = true) => {
    const label =
      facet.text === "0"
        ? $t("plp.facets.multiselect.no")
        : facet.text === "1"
          ? $t("plp.facets.multiselect.yes")
          : facet.text;

    return includeCount && facet.totalHits ? `${label} (${facet.totalHits})` : label;
  };

  return {
    getLabel,
  };
}
