export const SparInputType = {
  text: "text",
  password: "password",
  number: "number",
  email: "email",
  date: "date",
  tel: "tel",
} as const;
export type SparInputType = (typeof SparInputType)[keyof typeof SparInputType];

export type SparInputValidator = (string: string) => string | boolean;

export const SparInputStatus = {
  success: "success",
  error: "error",
} as const;
export type SparInputStatus = (typeof SparInputStatus)[keyof typeof SparInputStatus];

export interface InputStatusMessage {
  status: SparInputStatus | undefined;
  text: string;
}

export interface SparInputProps {
  modelValue?: string | number;
  label?: string;
  legend?: string;
  statusMessage?: InputStatusMessage;
  name?: string;
  placeholder?: string;
  type: SparInputType;
  // icon?: string;
  required?: boolean;
  disabled?: boolean;
  maxlength?: number;
  minlength?: number;
  min?: number;
  max?: number;
  minDate?: number;
  maxDate?: number;
  validators?: SparInputValidator[];
  showStrength?: boolean;
  toscaPrefix?: string;
}
