<template>
  <div class="login container">
    <slot name="heading"></slot>
    <spar-alert
      v-if="successMessage"
      data-tosca="login-success-newverfication-mail"
      :type="SparAlertType.info"
      :is-closable="false"
      class="login__alert--new-verification"
    >
      {{ successMessage }}</spar-alert
    >

    <spar-alert
      v-if="action === Action.Pending"
      data-tosca="login-error-pending-verification-msg"
      :type="SparAlertType.info"
      :is-closable="false"
      class="login__alert--pending-verification"
    >
      {{ $t("auth.login_form.error.pending_verification") }}</spar-alert
    >
    <div class="login__section">
      <div class="login__container-left">
        <form class="spar-form login-form" novalidate method="post" @submit.prevent="onSubmit">
          <slot name="subheading" class="subheading"></slot>

          <div v-if="!isGigyaLoadError">
            <spar-input
              v-model="username"
              :type="SparInputType.email"
              :label="$t('auth.login_form.fields.email')"
              required
              tosca-prefix="login-email"
              @interface="getChildInterface"
            />

            <spar-input
              v-if="action === Action.Login"
              v-model="password"
              tosca-prefix="login-password"
              :type="SparInputType.password"
              :label="$t('auth.login_form.fields.password')"
              required
              @interface="getChildInterface"
              @on-unmount="onChildUnmounted"
            />

            <div v-if="action === Action.Login" class="login-passwordforgotten">
              <spar-link
                class="login-passwordforgotten__link"
                data-tosca="login-passwordforgotten"
                target="_blank"
                :link="getPath('forgottenPassword')"
                >{{ $t("auth.login_form.action.forgotten") }}</spar-link
              >
            </div>

            <spar-checkbox
              v-if="action === Action.Login"
              v-model="rememberMe"
              val="rememberMe"
              :label="$t('auth.login_form.fields.remember_me')"
              tosca-prefix="login-rememberme"
              @interface="getChildInterface"
            />

            <spar-alert
              v-if="action !== Action.Pending && error"
              :type="SparAlertType.error"
              :is-closable="false"
              class="login-form__error"
              data-tosca="login-general-error-msg"
            >
              {{ errorMessage }}</spar-alert
            >

            <div class="login__footer">
              <spar-button
                type="submit"
                :disabled="loading || !isGigyaReady"
                class="btn--primary"
                data-tosca="login-button"
                >{{ submitText }}</spar-button
              >
            </div>

            <spar-button
              v-if="action !== Action.Login"
              class="btn--secondary"
              tosca-prefix="login-back"
              @click="setAction(Action.Login)"
              >{{ $t("auth.login_form.action.back") }}</spar-button
            >
          </div>

          <spar-alert
            v-else
            :type="SparAlertType.error"
            :is-closable="false"
            data-tosca="login-gigya-error-msg"
            >{{ $t("auth.login_form.error.gigya_load_error") }}</spar-alert
          >
        </form>
      </div>
      <div v-if="$slots.additionalContent" class="login__container-right">
        <slot name="additionalContent"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SparAlertType } from "~/components/shared/SparAlert/SparAlert.types";
import SparAlert from "~/components/shared/SparAlert/SparAlert.vue";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparCheckbox from "~/components/shared/SparCheckbox/SparCheckbox.vue";
import { SparInputType } from "~/components/shared/SparInput/SparInput.types";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import SparLink from "~/components/shared/SparLink/SparLink.vue";
import type { GigyaResponse } from "~/composables/auth/gigya.types";
import useGigya from "~/composables/auth/useGigya";
import useI18n from "~/composables/i18n/useI18n";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import { useAuthStore } from "~/stores/auth.store";
import { Action, GigyaError } from "./SparLogin.types";

const props = defineProps({
  redirectUrl: {
    type: String,
    default: undefined,
  },
});

const { isGigyaLoadError, isGigyaReady } = useGigya();
const { $t } = useI18n();
const router = useRouter();
const { sdk } = useVsfSdk();
const runtimeConfig = useRuntimeConfig();
const authStore = useAuthStore();
const { getPath } = useRoutes();

const action: Ref<Action> = ref(Action.Login);
const children: Ref<InputInterface[]> = ref([]);
const password = ref("");
const username = ref("");
const rememberMe = ref("");
const error = ref(0);
const successMessage = ref("");
const loading = ref(false);
const responseBuffer: Ref<GigyaResponse | null> = ref(null);

const resetAll = () => {
  children.value.forEach((c) => {
    c.reset();
  });
};

const resendVerificationCodeCallback = (eventObj: GigyaResponse) => {
  loading.value = false;
  if (eventObj.errorCode !== 0) {
    // Error 400003	(Unique identifier exists) could occur here; but that would
    // expose the information of a valid 3rd party email to the current user
    handleError(eventObj.errorCode);
  } else {
    successMessage.value = $t("auth.login_form.success.resend");
  }
  setAction(Action.Login);
};

const handleError = (code: number) => {
  error.value = code;
  switch (code) {
    case GigyaError.Pending:
      setAction(Action.Pending);
      break;
    case GigyaError.Invalid:
    case GigyaError.NotFound:
      setFieldsValidation(false);
      break;
  }
};

// Mark fields in red color
const setFieldsValidation = (valid = true) => {
  children.value.forEach((child) => child.setForceInvalid(!valid));
};

const onSubmit = async () => {
  cleanupForm();

  const res = children.value.map((c) => c.validate());
  const isValid = res.every((v) => v);
  if (!isValid) return;

  loading.value = true;

  //  Login
  if (action.value === Action.Login) {
    let eventObj: GigyaResponse | undefined = undefined;
    try {
      // Login with Gigya & OCC Oauth
      eventObj = await sdk.commerce.signIn({
        username: username.value,
        password: password.value,
        sessionExpiration: rememberMe.value
          ? parseInt(runtimeConfig.public.gigyaTimeoutLong)
          : parseInt(runtimeConfig.public.gigyaTimeoutShort),
      });
    } catch (_) {
      // Catch SAP SDK error with generic error message
      handleError(GigyaError.SDKError);
    } finally {
      loading.value = false;
    }

    if (!eventObj) return;
    responseBuffer.value = eventObj;

    if (eventObj.errorCode !== 0) {
      handleError(eventObj.errorCode);
    } else {
      await authStore.login();
      resetAll();
      router.push(authStore.redirectUrl);
    }
    return;
  }

  // Resend Verification Code
  if (action.value === Action.Pending && responseBuffer.value?.regToken) {
    window.gigya.accounts.resendVerificationCode({
      regToken: responseBuffer.value.regToken,
      email: username.value,
      callback: resendVerificationCodeCallback,
    });
  }
};

const getChildInterface = (childInterface: InputInterface) => {
  children.value.push(childInterface);
};

const onChildUnmounted = (uniqueId: string) => {
  children.value = children.value.filter((field) => field.uniqueId !== uniqueId);
};

// Cleanup states before submit
const cleanupForm = () => {
  successMessage.value = "";
  error.value = 0;
  setFieldsValidation(true);
};

const submitText = computed(() => {
  if (action.value === Action.Pending) {
    return $t("auth.login_form.action.pending_resend");
  }
  return $t("auth.login_form.action.login");
});

const errorMessage = computed(() => {
  switch (error.value) {
    case GigyaError.Disabled:
      return $t("auth.login_form.error.account_disabled");
    case GigyaError.Invalid:
      return $t("auth.login_form.error.invalid_login");
    case GigyaError.Pending:
      return "";
    case GigyaError.NotFound:
      return $t("auth.login_form.error.invalid_user");
  }
  return $t("auth.login_form.error.general");
});

// Clear error & success messages
const setAction = (newAction: Action) => {
  if (newAction !== Action.Login) {
    successMessage.value = "";
  }
  if (newAction === Action.Login) {
    error.value = 0;
  }
  action.value = newAction;
};

onMounted(() => {
  if (props.redirectUrl) {
    authStore.setRedirectUrl(props.redirectUrl);
  }
});
</script>

<style lang="scss">
@use "./SparLogin.scss";
</style>
