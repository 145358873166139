/**
 * This file contains ALL routes with the DEFAULT paths.
 * Localized paths are injected from Contentstack in routes plugin.
 *
 * !!! DO NOT access "Route" if you need a path; always use getPath() instead !!!
 * const { getPath } = useRoutes();
 * const path = getPath("brands")
 *
 * When using route params, a 404 handling is required
 * https://nuxt.com/docs/getting-started/routing#route-validation
 */
import type { RouteRecordRaw, RouteLocationNormalized } from "#vue-router";
import CheckAuthPage from "~/pages/auth/check-auth.vue";
import ForgottenPasswordPage from "~/pages/auth/forgotten-password.vue";
import LoginPage from "~/pages/auth/login.vue";
import LogoutPage from "~/pages/auth/logout.vue";
import RegistrationConfirmationPage from "~/pages/auth/registration-confirmation.vue";
import RegistrationPage from "~/pages/auth/registration.vue";
import ReLoginPage from "~/pages/auth/relogin.vue";
import ResetPasswordPage from "~/pages/auth/reset-password.vue";
import BrandsDetails from "~/pages/brands/details/index.vue";
import Brands from "~/pages/brands/index.vue";
import CartPage from "~/pages/cart/index.vue";
import CategoryPage from "~/pages/category/index.vue";
import CheckoutCheckIn from "~/pages/checkout/check-in.vue";
import CheckoutConfirmation from "~/pages/checkout/confirmation/index.vue";
import CheckoutDelivery from "~/pages/checkout/delivery/index.vue";
import CheckoutLogin from "~/pages/checkout/login/index.vue";
import CheckoutPayment from "~/pages/checkout/payment/index.vue";
import CheckoutSummary from "~/pages/checkout/summary/index.vue";
import ComponentsPage from "~/pages/components.vue";
import LeafletsDetails from "~/pages/leaflets/details/index.vue";
import Leaflets from "~/pages/leaflets/index.vue";
import LocationDetailsPage from "~/pages/locations/details/index.vue";
import LocationListPage from "~/pages/locations/index.vue";
import MailForm from "~/pages/mail-form/index.vue";
import MyAccountChangePasswordPage from "~/pages/my-account/change-password/index.vue";
import MyAccountFavorites from "~/pages/my-account/favorites/index.vue";
import MyAccountPage from "~/pages/my-account/index.vue";
import MyAccountLoyalityPoints from "~/pages/my-account/loyality-points/index.vue";
import MyAccountNewsletter from "~/pages/my-account/newsletter/index.vue";
import MyAccountOrders from "~/pages/my-account/orders/index.vue";
import MyAccountPersonalDataPage from "~/pages/my-account/personal-data/index.vue";
import PayonePaymentPage from "~/pages/payment/payone.vue";
import PaypalPaymentPage from "~/pages/payment/paypal.vue";
import ProductPage from "~/pages/product/index.vue";
import SearchPage from "~/pages/search/index.vue";
import { validateBaseSite } from "~/utils/routing";
import { Route, type RouteName } from "./routes";

type RouteRecordSPAR = RouteRecordRaw & { name: RouteName };

const reducedLayout = "reduced";
const validateRoute = (route: RouteLocationNormalized) =>
  validateBaseSite(route.params.baseSite.toString());

// Routes with default path
export const routes: RouteRecordSPAR[] = [
  {
    name: "category",
    path: Route.category,
    component: CategoryPage,
  },
  {
    name: "search",
    path: Route.search,
    component: SearchPage,
  },
  {
    name: "cart",
    path: Route.cart,
    component: CartPage,
    meta: {
      validate: (route) => validateRoute(route),
    },
  },
  {
    name: "productDetail",
    path: Route.productDetail,
    component: ProductPage,
  },
  {
    name: "login",
    path: Route.login,
    component: LoginPage,
  },
  {
    name: "logout",
    path: Route.logout,
    component: LogoutPage,
  },
  {
    name: "registration",
    path: Route.registration,
    component: RegistrationPage,
  },
  {
    name: "registrationConfirmation",
    path: Route.registrationConfirmation,
    component: RegistrationConfirmationPage,
    meta: {
      skipCheckLogin: true,
    },
  },
  {
    name: "resetPassword",
    path: Route.resetPassword,
    component: ResetPasswordPage,
    meta: {
      denyLoginRedirect: true,
    },
  },
  {
    name: "forgottenPassword",
    path: Route.forgottenPassword,
    component: ForgottenPasswordPage,
    meta: {
      denyLoginRedirect: true,
    },
  },
  {
    name: "myAccount",
    path: Route.myAccount,
    component: MyAccountPage,
    meta: {
      middleware: ["auth"],
    },
  },
  {
    name: "myAccountChangePassword",
    path: Route.myAccountChangePassword,
    component: MyAccountChangePasswordPage,
    meta: {
      middleware: ["auth"],
    },
  },
  {
    name: "myAccountPersonalData",
    path: Route.myAccountPersonalData,
    component: MyAccountPersonalDataPage,
    meta: {
      middleware: ["auth"],
    },
  },
  {
    name: "myAccountOrders",
    path: Route.myAccountOrders,
    component: MyAccountOrders,
    meta: {
      middleware: ["auth"],
    },
  },
  {
    name: "myAccountFavorites",
    path: Route.myAccountFavorites,
    component: MyAccountFavorites,
    meta: {
      middleware: ["auth"],
    },
  },
  {
    name: "myAccountNewsletter",
    path: Route.myAccountNewsletter,
    component: MyAccountNewsletter,
    meta: {
      middleware: ["auth"],
    },
  },
  {
    name: "myAccountLoyalityPoints",
    path: Route.myAccountLoyalityPoints,
    component: MyAccountLoyalityPoints,
    meta: {
      middleware: ["auth"],
    },
  },
  {
    name: "checkAuth",
    path: Route.checkAuth,
    component: CheckAuthPage,
  },
  {
    name: "reLogin",
    path: Route.reLogin,
    component: ReLoginPage,
  },
  {
    name: "checkoutLogin",
    path: Route.checkoutLogin,
    component: CheckoutLogin,
    meta: {
      layout: reducedLayout,
      validate: (route) => validateRoute(route),
    },
  },
  {
    name: "checkIn",
    path: Route.checkIn,
    component: CheckoutCheckIn,
    meta: {
      layout: reducedLayout,
    },
  },
  {
    name: "checkoutDelivery",
    path: Route.checkoutDelivery,
    component: CheckoutDelivery,
    meta: {
      layout: reducedLayout,
      middleware: ["cart", "checkout"],
      validate: (route) => validateRoute(route),
    },
  },
  {
    name: "checkoutPayment",
    path: Route.checkoutPayment,
    component: CheckoutPayment,
    meta: {
      layout: reducedLayout,
      middleware: ["cart", "checkout"],
      validate: (route) => validateRoute(route),
    },
  },
  {
    name: "checkoutSummary",
    path: Route.checkoutSummary,
    component: CheckoutSummary,
    meta: {
      layout: "reduced",
      middleware: ["cart", "checkout"],
      validate: (route) => validateRoute(route),
    },
  },
  {
    name: "checkoutConfirmation",
    path: Route.checkoutConfirmation,
    component: CheckoutConfirmation,
    meta: {
      layout: reducedLayout,
      validate: (route) => validateRoute(route),
    },
  },
  {
    name: "leaflets",
    path: Route.leaflets,
    component: Leaflets,
    meta: {
      middleware: ["leaflets"],
    },
  },
  {
    name: "leafletsDetails",
    path: Route.leafletsDetails,
    component: LeafletsDetails,
  },
  {
    name: "brands",
    path: Route.brands,
    component: Brands,
  },
  {
    name: "brandsDetail",
    path: Route.brandsDetail,
    component: BrandsDetails,
  },
  {
    name: "brandsDetailLocalProducer",
    path: Route.brandsDetailLocalProducer,
    component: BrandsDetails,
  },
  {
    name: "locationList",
    path: Route.locationList,
    component: LocationListPage,
  },
  {
    name: "locationDetails",
    path: Route.locationDetails,
    component: LocationDetailsPage,
  },
  {
    name: "paymentPayPal",
    path: Route.paymentPayPal,
    component: PaypalPaymentPage,
    meta: {
      denyLoginRedirect: true,
      layout: reducedLayout,
    },
  },
  {
    name: "paymentPayOne",
    path: Route.paymentPayOne,
    component: PayonePaymentPage,
    meta: {
      denyLoginRedirect: true,
      layout: reducedLayout,
    },
  },
  {
    name: "components",
    path: Route.components,
    component: ComponentsPage,
  },
  {
    name: "mailForm",
    path: Route.mailForm,
    component: MailForm,
  },
];
