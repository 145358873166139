<template>
  <div
    class="spar-textarea"
    :class="{
      'spar-textarea--invalid': !isValid,
      'spar-textarea--required': required,
      'spar-textarea--disabled': disabled,
    }"
  >
    <label v-if="label" :for="uniqueId" class="spar-textarea__label">{{ label }}</label>
    <textarea
      :id="uniqueId"
      v-model="internalValue"
      class="spar-textarea__textarea"
      :data-tosca="getToscaPrefix('textarea', toscaPrefix)"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
      :minlength="minlength"
      :maxlength="maxlength"
      :name="name"
      :aria-invalid="!isValid"
      :aria-required="required"
      :aria-describedby="errorMessage || legend ? `${uniqueId}-legend` : undefined"
      @blur="onBlur"
    />
    <div :id="`${uniqueId}-legend`" class="spar-textarea__legend">
      <span v-if="legend && !$slots['legend']">{{ legend }}</span>
      <slot v-else name="legend"></slot>

      <span v-show="!isValid" class="spar-textarea__error-message" aria-live="polite">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useInputValue } from "~/composables/inputs/useInputValue";
import { getToscaPrefix } from "~/utils/ui";
import type { SparTextareaProps } from "./SparTextarea.types";

const props: SparTextareaProps = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: null,
  },
  legend: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  minlength: {
    type: Number,
    default: 1,
  },
  maxlength: {
    type: Number,
    default: 1000,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["update:modelValue", "interface", "onUnmount"]);

const { internalValue, isValid, errorMessage, uniqueId, onBlur } = useInputValue(props, emit);
</script>

<style lang="scss">
@use "./SparTextarea.scss";
</style>
