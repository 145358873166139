import type { SparMdsaProduct } from "~/utils/mdsa/integration/mdsa.types";

export interface SparQuantityButtonProps {
  cartPage?: boolean;
  product: SparMdsaProduct;
  toscaPrefix?: string;
  layout?: string;
  shopContext?: string;
  cartEntryUpdate?: boolean;
  entryNumber?: number;
  boundaries: {
    min: number;
    max: number;
  };
  buyable: boolean;
  // additionalServices?: string[];
}

export const SparQuantityButtonLayout = {
  small: "small",
  big: "big",
} as const;
export type SparQuantityButtonLayout =
  (typeof SparQuantityButtonLayout)[keyof typeof SparQuantityButtonLayout];

export const SparQuantityButtonActionType = {
  error: "error",
  update: "update",
  remove: "remove",
  add: "add",
  maximum: "maximum",
  minimum: "minimum",
} as const;
export type SparQuantityButtonActionType =
  (typeof SparQuantityButtonActionType)[keyof typeof SparQuantityButtonActionType];
