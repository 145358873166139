<template>
  <div
    class="spar-alert"
    :class="`spar-alert--${type}`"
    :role="type === SparAlertType.error ? 'alert' : 'region'"
    :aria-live="'polite'"
    :data-tosca="getToscaPrefix('alert', toscaPrefix)"
  >
    <div v-if="type" class="spar-alert__icon" :class="`spar-alert__icon--${type}`">
      <spar-icon-sprite :symbol="type" />
    </div>
    <div class="spar-alert__message">
      <slot />
    </div>
    <div v-if="isClosable" class="spar-alert__close">
      <spar-button
        :aria-label="$t('global.close')"
        :data-tosca="getToscaPrefix('close', toscaPrefix)"
        class="spar-alert__close-btn"
        icon-only
        icon="close"
        :variant="ButtonVariant.clear"
        @click="emit('close')"
      ></spar-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import useI18n from "~/composables/i18n/useI18n";
import { getToscaPrefix } from "~/utils/ui";
import { type SparAlertProps, SparAlertType } from "./SparAlert.types";

defineProps({
  type: {
    type: String as PropType<SparAlertType | undefined>,
    default: SparAlertType.info,
  },
  isClosable: {
    type: Boolean,
    default: true,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
}) as SparAlertProps;

const emit = defineEmits(["close"]);
const { $t } = useI18n(); // Explicit Import for Storybook
</script>

<style lang="scss">
@use "./SparAlert.scss";
</style>
