<template>
  <div class="leaflets-filter">
    <spar-heading v-if="label" :level="labelLevel" :headline-style="labelLevel" :title="label" />
    <div class="leaflets-filter__select-container">
      <spar-select
        v-model="selectedValue"
        class="leaflets-filter__select"
        required
        :options="options"
        :tosca-prefix="getToscaPrefix('region', toscaPrefix)"
        :data-label="selectedOption?.value"
        @change="emit('select', selectedValue)"
      />
      <div
        class="leaflets-filter__select-label"
        :class="`f-size--heading-${labelLevel}`"
        aria-hidden="true"
      >
        {{ selectedOption?.value }}
        <spar-icon-sprite symbol="arrow-bottom" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import type { SparSelectOptions } from "~/components/shared/SparSelect/SparSelect.types";
import SparSelect from "~/components/shared/SparSelect/SparSelect.vue";
import { getToscaPrefix } from "~/utils/ui";

const props = defineProps({
  selectedOption: {
    type: Object,
    default: undefined,
  },
  label: {
    type: String,
    required: true,
  },
  labelLevel: {
    type: String,
    default: "2",
  },
  options: {
    type: Array as PropType<SparSelectOptions>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const selectedValue = ref(props.selectedOption?.key);

const emit = defineEmits(["select"]);

watch(
  () => props.selectedOption,
  (selectedOption) => {
    if (selectedOption) {
      selectedValue.value = selectedOption.key;
    }
  },
);
</script>

<style lang="scss">
@use "SparLeafletsFilter.scss";
</style>
