<template>
  <div
    class="spar-select"
    :class="{
      'spar-select--invalid': !isValid,
      'spar-select--required': required,
      'spar-select--disabled': disabled,
      'spar-select--label-overlap': labelPosition === LabelPosition.overlap,
    }"
  >
    <label v-if="label" :for="uniqueId" class="spar-select__label">{{ label }}</label>
    <div class="spar-select__wrapper">
      <select
        :id="uniqueId"
        v-model="internalValue"
        :data-tosca="getToscaPrefix('select', toscaPrefix)"
        class="spar-select__select"
        :required="required"
        :disabled="disabled"
        :name="name"
        :aria-label="ariaLabel"
        :aria-invalid="!isValid"
        :aria-required="required"
        :aria-describedby="errorMessage || legend ? `${uniqueId}-legend` : undefined"
        @change="onBlur"
      >
        <option v-if="showHint" value="" :hidden="!enableHintOption">{{ hint }}</option>
        <option v-for="option in options" :key="option.key" :value="option.key">
          {{ option.value }}
        </option>
      </select>
      <spar-icon-sprite symbol="arrow-bottom" class="spar-select__select-icon" />
    </div>
    <div
      v-if="legend || $slots['legend'] || !isValid"
      :id="`${uniqueId}-legend`"
      class="spar-select__legend"
    >
      <span v-if="legend && !$slots['legend']">{{ legend }}</span>
      <slot v-else name="legend"></slot>

      <span
        v-show="!isValid"
        class="spar-select__error-message"
        :data-tosca="getToscaPrefix('error-msg', toscaPrefix)"
        aria-live="polite"
      >
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import useI18n from "~/composables/i18n/useI18n";
import { useInputValue } from "~/composables/inputs/useInputValue";
import { getToscaPrefix } from "~/utils/ui";
import { type SparSelectProps, type SparSelectOption, LabelPosition } from "./SparSelect.types";

const props: SparSelectProps = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  label: {
    type: String,
    default: null,
  },
  labelPosition: {
    type: String as PropType<LabelPosition>,
    default: LabelPosition.above,
  },
  legend: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectHint: {
    // Defaults to $t("form.select.please_choose"); see computed below
    type: String,
    default: null,
  },
  showHint: {
    type: Boolean,
    default: true,
  },
  enableHintOption: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array as PropType<SparSelectOption[]>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
  ariaLabel: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["update:modelValue", "interface", "onUnmount"]);

const { internalValue, isValid, errorMessage, uniqueId, onBlur } = useInputValue(
  props as unknown as SparSelectProps,
  emit,
);
const { $t } = useI18n();

const hint = computed(() => props.selectHint || $t("form.select.please_choose"));
</script>

<style lang="scss">
@use "./SparSelect.scss";
</style>
