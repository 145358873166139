<template>
  <spar-my-account-wrapper>
    <spar-heading class="my-account__heading" level="2" headline-style="2" title="Dashboard" />
    <p>Container for SAP Content</p>

    <spar-button
      :disabled="isLogoutPending"
      class="btn--primary"
      tosca-prefix="myaccount-logout"
      @click="logout()"
      >{{ $t("my_account.logout") }}</spar-button
    >
  </spar-my-account-wrapper>
</template>

<script lang="ts" setup>
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import { useAuthStore } from "~/stores/auth.store";
import SparMyAccountWrapper from "./SparMyAccountWrapper/SparMyAccountWrapper.vue";

const { logout } = useAuthStore();
const { isLogoutPending } = storeToRefs(useAuthStore());
</script>

<style lang="scss">
@use "./SparMyAccount.scss";
</style>
