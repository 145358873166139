import type { PageSeo } from "@shop-storefront/utils";
import {
  RobotFollow,
  RobotIndex,
} from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.types";

export function resolvePageSeo(seo: PageSeo) {
  const robotContent = `
    ${seo.robotIndex ? RobotIndex.all : RobotIndex.noindex},
    ${seo.robotFollow ? RobotFollow.follow : RobotFollow.nofollow}
  `;

  return {
    title: seo.title,
    meta: [
      {
        hid: "description",
        name: "description",
        content: seo.description,
      },
      {
        hid: "keywords",
        name: "keywords",
        content: seo.keywords?.join(","),
      },
      {
        hid: "robots",
        name: "robots",
        content: robotContent,
      },
    ],
    link: [
      {
        rel: "canonical",
        href: seo.canonicalSlug,
      },
    ],
    script: [
      {
        src: seo.structuredData,
        type: "application/ld+json",
      },
    ],
  };
}
