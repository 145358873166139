<template>
  <div>
    <div class="plp-facets__slider-wrapper">
      <Slider
        v-model="selectedRange"
        class="plp-facets__slider-slider"
        :step="step"
        :min="min"
        :max="max"
        :tooltips="false"
      />
    </div>

    <div class="plp-facets__slider-inputs">
      <div class="plp-facets__slider-input">
        <spar-input
          v-model.number="minInput"
          :type="SparInputType.number"
          :legend="$t('plp.facets.range.minimum')"
          :min="min"
          :max="max"
        />
        <span v-if="unit">{{ unit }}</span>
      </div>
      {{ $t("plp.facets.range.to") }}
      <div class="plp-facets__slider-input">
        <spar-input
          v-model.number="maxInput"
          :type="SparInputType.number"
          :legend="$t('plp.facets.range.maximum')"
          :min="min"
          :max="max"
        />
        <span v-if="unit">{{ unit }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// Range Slider: https://github.com/vueform/slider
import Slider from "@vueform/slider";
import { useDebounceFn } from "@vueuse/core";
import { SparInputType } from "~/components/shared/SparInput/SparInput.types";
import SparInput from "~/components/shared/SparInput/SparInput.vue";
import { useSearchStore } from "~/stores/search.store";
import type { Filters } from "~/utils/factfinder/createQuery";
import type { FactFinderFacet } from "~/utils/factfinder/integration/factfinder.types";

const props = defineProps({
  options: {
    type: Object as PropType<FactFinderFacet>,
    required: true,
  },
  unit: {
    type: String,
    default: "",
  },
  filterName: {
    type: String,
    default: "",
  },
});

const searchStore = useSearchStore();

const step = 1;

const min = computed(() => props.options.absoluteMinValue || 0);
const max = computed(() => props.options.absoluteMaxValue || 0);

// Inputs are decoupled from slider for validation reasons
const minInput = ref(props.options.selectedMinValue || min.value);
const maxInput = ref(props.options.selectedMaxValue || max.value);

const selectedRange = ref([minInput.value, maxInput.value]);

// Perform Search / Update Filter Url
const updateFilters = useDebounceFn(() => {
  const facet: Filters = {
    [props.filterName]: selectedRange.value[0] + "~" + selectedRange.value[1],
  };
  searchStore.updateFilterUrl(facet, null, true);
}, 300);

// Pass values from slider to inputs and trigger search
watch(
  () => selectedRange.value,
  (value) => {
    minInput.value = value[0];
    maxInput.value = value[1];
    updateFilters();
  },
);

// TODO: Copy & Paste of invalid content clears the input field, although model is set to 0

// Pass value from minInput to slider AFTER validation and trigger search
watch(
  () => minInput.value,
  (value) => {
    if (!value || value < min.value || value > max.value) {
      return (minInput.value = min.value);
    }
    selectedRange.value[0] = value;
    updateFilters();
  },
);

// Pass value from maxInput to slider AFTER validation and trigger search
watch(
  () => maxInput.value,
  (value) => {
    if (!value || value > max.value || value < min.value) {
      return (maxInput.value = max.value);
    }
    selectedRange.value[1] = value;
    updateFilters();
  },
);
</script>

<style lang="scss">
@use "./SparPlpRangeSlider.scss";
</style>
