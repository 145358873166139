import { defineStore } from "pinia";
import type {
  Navigation,
  NavigationNode,
} from "~/components/sections/SparHeader/SparMainNavigation/SparMainNavigation.types";
import { useScreenInfo } from "~/composables/utils/useScreenInfo";

export const useNavigationStore = defineStore("navigation", () => {
  const { $indexes } = useNuxtApp();
  const { isMobile } = useScreenInfo();

  const subNavigationHeight = ref(0);
  const navigation: Navigation = $indexes.navigation;
  const tmpActiveMenuId = ref("");

  const currentMenuLevel = ref(1);
  const mobileMenuOpen = ref(false);
  const clickedMobileMainNavigation = ref("");

  const resetMobileMenuActive = () => {
    mobileMenuOpen.value = false;
    clickedMobileMainNavigation.value = "";
  };

  const setCurrentMenuLevel = (level: number) => {
    currentMenuLevel.value = level;
  };

  const setMobileMenuActive = (navNode: NavigationNode | null, forceClose = false) => {
    if (forceClose || !navNode) {
      resetMobileMenuActive();
      return;
    }

    if (isMobile.value) {
      if (clickedMobileMainNavigation.value === navNode.categoryId) {
        resetMobileMenuActive();
      } else {
        clickedMobileMainNavigation.value = navNode.categoryId;
        mobileMenuOpen.value = true;
      }
    }
  };

  const setActiveMenu = (navNode: NavigationNode, initial = false) => {
    if (!navNode || !navNode.categoryId) {
      return;
    }

    tmpActiveMenuId.value = navNode.categoryId;

    if (!initial) {
      navigation.flyoutOpenable = true;
    }

    navigation.levelPath = navNode.levelPath;
    setCurrentMenuLevel(Number(navNode.level));

    // Close the menu if it's level 2 without children, or it's level 3 (no matter if it has children)
    if (
      isMobile.value &&
      ((Number(navNode.level) === 2 && !navNode.childCategories?.length) ||
        Number(navNode.level) === 3)
    ) {
      resetMobileMenuActive();
    } else if (navNode.childCategories?.length && Number(navNode.level) < 3) {
      setCurrentMenuLevel(Number(navNode.level) + 1);
    }
  };

  const closeFlyout = (navNode: NavigationNode | null) => {
    if (!navNode) {
      setCurrentMenuLevel(currentMenuLevel.value - 1);
      return;
    }

    if (isMobile.value) {
      navigation.levelPath = navNode.levelPath;
      if (Number(navNode.level) === 3) {
        resetMobileMenuActive();
      }
    } else {
      navigation.flyoutOpenable = false;
    }

    setCurrentMenuLevel(Number(navNode.level));
  };

  const checkActive = (item: NavigationNode, level = 1) => {
    return navigation.levelPath.includes(item.categoryId) && currentMenuLevel.value >= level;
  };

  return {
    checkActive,
    currentMenuLevel,
    mobileMenuOpen,
    navigation,
    setActiveMenu,
    setCurrentMenuLevel,
    setMobileMenuActive,
    subNavigationHeight,
    closeFlyout,
  };
});
