<template>
  <main class="pdp__wrapper container">
    <div class="pdp">
      <spar-breadcrumbs :breadcrumbs="breadcrumbs" tosca-prefix="pdp" />
      <div class="pdp__sections">
        <template v-if="content?.[0]?.topSections">
          <render-sections :content="content[0].topSections" />
        </template>
      </div>
      <spar-pdp-product> 7 - Placeholder Cross Selling </spar-pdp-product>
      <div id="recom1" class="pdp__turbo-1">9 - Turbo recommendations</div>
      <div class="pdp__sections">
        <template v-if="content?.[0]?.middleSections">
          <render-sections :content="content[0].middleSections" />
        </template>
      </div>
      <div id="recom2" class="pdp__turbo-2">11 - Turbo recommendations</div>
      <div class="pdp__sections">
        <template v-if="content?.[0]?.bottomSections">
          <render-sections :content="content[0].bottomSections" />
        </template>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import type { ProductPage } from "@shop-storefront/utils";
import SparBreadcrumbs from "~/components/feature/SparBreadcrumbs/SparBreadcrumbs.vue";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { productPageReferences } from "~/utils/contentstack/constants/references.constants";
import SparPdpProduct from "./SparPdpProduct/SparPdpProduct.vue";

const { $contentstack, $indexes } = useNuxtApp();
const { product } = storeToRefs(useMdsaStore());

const {
  params: { productId },
  fullPath,
} = useRoute();

const { data: content } = await useAsyncData(ContentType.productPage, async () => {
  return await $contentstack.getEntries<ProductPage>({
    type: ContentType.productPage,
    customQuery: {
      [ContentstackPageParams.product.id]: productId,
    },
    includeReference: productPageReferences as string[],
  });
});

const breadcrumbs = computed(() => {
  // const mainCategory = product.value?.categories?.[0];

  // TODO: Add product.categoryId when available
  // if (mainCategory?.categoryId) return $indexes.getBreadcrumbs(mainCategory?.categoryId);
  return $indexes.getBreadcrumbs("bltc4188012f2a10f4c"); // Dummy breadcrumbs
});

if (!product.value) {
  // show error page
  const { show404Page } = useErrorPage();
  show404Page(ErrorPageType.product, fullPath);
}
</script>

<style lang="scss">
@use "./SparPdp.scss";
</style>
