<template>
  <div class="video">
    <video
      v-if="fileUrl"
      ref="playerRef"
      playsinline
      controls
      class="video-player"
      :data-poster="poster"
    >
      <source :src="fileUrl" type="video/mp4" />
      <!-- TODO: add a src file for the captions, contentstack or DAM? -->
      <track kind="captions" />
    </video>
    <div v-else-if="youtubeUrl" ref="playerRef" class="video-player plyr__video-embed">
      <ClientOnly>
        <iframe
          class="video-player__iframe"
          :data-poster="poster"
          :src="youtubeUrl"
          :title="title"
          allowfullscreen
          allow="autoplay"
        ></iframe>
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Plyr from "plyr";
import "plyr/dist/plyr.css";

defineProps({
  youtubeUrl: {
    type: String,
    default: undefined,
  },
  fileUrl: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    default: undefined,
  },
  description: {
    type: String,
    default: undefined,
  },
  poster: {
    type: String,
    default: undefined,
  },
  alt: {
    type: String,
    default: undefined,
  },
});

const playerRef = ref<HTMLElement>();
const player = ref();

onMounted(() => {
  if (playerRef.value) player.value = new Plyr(playerRef.value);
});

onUnmounted(() => {
  player.value.destroy();
});
</script>

<style lang="scss">
@use "./SparVideo.scss";
</style>
