import { useAuthStore } from "~/stores/auth.store";

/**
 * Throw if some generic cart error occurred
 */
export class CartError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CartError";
  }
}

/**
 * Throw if some generic country error occurred
 */
export class CountryError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CountryError";
  }
}

/**
 * Throw if some generic availability error occurred
 */
export class AvailabilityError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AvailabilityError";
  }
}

/**
 * Throw if some generic factfinder error occurred
 */
export class FactFinderError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "FactFinderError";
  }
}

/**
 * Throw if some generic mdsa error occurred
 */
export class MdsaError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "MdsaError";
  }
}

/**
 * Throw if some generic ppc error occurred
 */
export class PPCError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "PPCError";
  }
}

/**
 * Throw if some generic user error occurred
 */
export class UserError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UserError";
  }
}

interface SDKError {
  name: string;
  cause: SDKErrorCause;
}

interface SDKErrorCause {
  message: string;
  name: string;
  response: SDKErrorCauseResponse;
}

interface SDKErrorCauseResponse {
  status: number;
  statusText: string;
}

// Handle SAP Commerce (Auth) Errors
// Resolve the following errors by trying a re-login with Gigya:
// 401: Unauthorized
// 403: Forbidden
// 500: Possible Refresh Token Usage Issue (e.g. timed out)
export function handleCommerceError(error: Error) {
  if (error.name !== "SDKError") return false;
  const sdkErr = error as SDKError;
  if (sdkErr.cause.name !== "AxiosError") return false;
  if (![401, 403, 500].includes(sdkErr.cause.response.status)) return false;

  const { setRedirectUrl } = useAuthStore();
  const router = useRouter();
  const route = useRoute();
  const { getPath } = useRoutes();

  // Prevent endless loops with subsequent sdk errors
  if (route.path === getPath("reLogin")) return;

  setRedirectUrl(route.path);
  router.push(getPath("reLogin"));
}
