import { type SparMdsaProduct } from "~/utils/mdsa/integration/mdsa.types";

export async function resolveMdsaProduct(product: SparMdsaProduct): Promise<SparMdsaProduct> {
  // const { $mdsa } = useNuxtApp();
  // const { sapBaseSites } = storeToRefs(useBaseSiteStore());

  // delete "default"
  // if (product.prices[PriceType.default]) {
  //   delete product.prices[PriceType.default];
  // }

  // if (
  //   product.sparProductType === SparProductType.GWK ||
  //   product.sparProductType === SparProductType.Voucher
  // ) {
  //   const priceTab: ProductPriceItem | undefined = product.prices.P;

  //   // remove all prices
  //   for (const price of Object.keys(product.prices) as PriceType[]) {
  //     delete product.prices[price];
  //   }

  //   if (priceTab?.href) {
  //     const { prices: priceItem } = await $mdsa.getMdsaProductPrice(priceTab.href);

  //     product?.mainInfo.deliveryModes?.forEach((deliveryMode) => {
  //       // check if given availabilty is present on current backend
  //       // otherwhise dont show price-tab
  //       const checkBaseSiteAvailable = sapBaseSites.value?.filter(
  //         (item) => item.resolvedUid === SparBaseStoreTypes.national,
  //       );
  //       if (checkBaseSiteAvailable?.length && deliveryMode.associatedAvailabilityType) {
  //         product.prices[deliveryMode.associatedAvailabilityType] = {
  //           priceType: deliveryMode.associatedAvailabilityType,
  //           prices: priceItem[0],
  //         };
  //       }
  //     });
  //   }
  // } else if (product.prices) {
  //   // add pricedata to priceItem
  //   for (const price of Object.keys(product.prices) as PriceType[]) {
  //     // check if given availabilty is present on current backend
  //     // otherwhise dont show price-tab
  //     const baseSite =
  //       price === AvailabilityPossibility.T
  //         ? SparBaseStoreTypes.timeslot
  //         : SparBaseStoreTypes.national;
  //     const checkBaseSiteAvailable = sapBaseSites.value?.filter(
  //       (item) => item.resolvedUid === baseSite,
  //     );

  //     if (checkBaseSiteAvailable?.length && product.prices[price] !== undefined) {
  //       let href = null;
  //       // const productPriceItem = product.prices[price] as ProductPriceItem;
  //       if (price === PriceType.T) {
  //         // TODO - add here onboarding logic --> select onboarded store
  //         href = product.prices[price]?.stores?.[0].href;
  //       } else {
  //         href = product.prices[price]?.href;
  //       }
  //       if (!href) {
  //         delete product.prices[price];
  //         continue;
  //       }

  //       const { prices: priceItem } = await $mdsa.getMdsaProductPrice(href);
  //       (product.prices[price] as ProductPriceItem).prices = priceItem[0];
  //     } else {
  //       delete product.prices[price];
  //     }
  //   }
  // }

  // // add additional service products
  // if (product.boms) {
  //   const additionalServices: SparProduct[] = [];

  //   for (const bom of product.boms) {
  //     if (bom.bomType === BomType.service) {
  //       const { products: additionalServiceProduct } = await $mdsa.getMdsaAdditionalServiceProduct(
  //         bom.bomTarget,
  //       );
  //       additionalServices.push(additionalServiceProduct[0]);
  //     }
  //   }
  //   product.additionalServices = additionalServices;
  // }

  // // sorting price https://collaboration.spar.at/jira/browse/HYBS-15917
  // // always show p- in front of t-tab or voucher digital in front of post
  // product.prices = Object.entries(product.prices)
  //   .sort(([, a], [, b]) => {
  //     return a.priceType.localeCompare(b.priceType);
  //   })
  //   .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

  // product.mainInfo.salesUnit = product.salesUnit;

  return product;
}
