import type { Banner } from "@shop-storefront/utils";

export type SparBannerProps = Omit<Banner, "component" | "title">;

export const PositionCtaBtn = {
  center: "centered",
  left: "left",
  right: "right",
} as const;
export type PositionCtaBtn = (typeof PositionCtaBtn)[keyof typeof PositionCtaBtn];
