<template>
  <fieldset
    class="checkbox-radio-group"
    :class="{
      'checkbox-radio-group--invalid': !isValid,
      'checkbox-radio-group--required': required,
    }"
  >
    <legend class="checkbox-radio-group__label">{{ label }}</legend>
    <spar-checkbox
      v-for="(option, index) in options"
      :key="index"
      v-model="model[index]"
      v-bind="option"
      :tosca-prefix="getTosca(option)"
      @interface="handleChildInterface"
      @change="validate"
    />

    <div class="checkbox-radio-group__legend">
      <span v-show="!isValid" class="checkbox-radio-group__error" aria-live="polite">
        {{ errorMessage }}
      </span>

      <template v-if="legend && !$slots['legend']">{{ legend }}</template>
      <slot name="legend"></slot>
    </div>
  </fieldset>
</template>

<script lang="ts" setup>
import type { SparCheckboxProps } from "~/components/shared/SparCheckbox/SparCheckbox.types";
import SparCheckbox from "~/components/shared/SparCheckbox/SparCheckbox.vue";
import { useInputValue } from "~/composables/inputs/useInputValue";
import type { InputInterface } from "~/composables/inputs/useInputValues.types";
import { getSanitizedIdString, getToscaPrefix } from "~/utils/ui";
import type { SparCheckboxGroupProps } from "./SparCheckboxGroup.types";

const props: SparCheckboxGroupProps = defineProps({
  modelValue: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  required: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  legend: {
    type: String,
    default: null,
  },
  options: {
    type: Array as PropType<SparCheckboxProps[]>,
    required: true,
  },
  toscaPrefix: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "interface", "onUnmount"]);

const handleChildInterface = (childInterface: InputInterface) => {
  emit("interface", childInterface);
};

const { errorMessage, internalValue, isValid, validate } = useInputValue(props, emit);
const model = internalValue as Ref<string[]>; // Cast to string[]

const getTosca = (option: SparCheckboxProps) => {
  const prefix = getSanitizedIdString(props.label + "-" + option.val);
  return getToscaPrefix(prefix, props.toscaPrefix);
};
</script>

<style lang="scss">
@use "./SparCheckboxRadioGroup.scss";
</style>
