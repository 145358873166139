<template>
  <div class="plp-facets-slideout">
    <div class="plp-facets-slideout__facets">
      <spar-plp-facets-slideout-facet v-for="facet in facets" :key="facet.name" :facet="facet" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSearchStore } from "~/stores/search.store";
import SparPlpFacetsSlideoutFacet from "./SparPlpFacetsSlideoutFacet.vue";

const { searchResults } = storeToRefs(useSearchStore());

const facets = computed(() => searchResults.value.products?.facets || []);
</script>

<style lang="scss">
@use "./SparPlpFacetsSlideout.scss";
</style>
