<template>
  <div class="content-page container">
    <spar-heading
      level="2"
      :title="$t('auth.check_auth.title')"
      :description="$t('auth.check_auth.subtitle')"
    />

    <div class="check-auth">
      <spar-loader />
    </div>
  </div>
</template>

<script setup lang="ts">
import SparHeading from "~/components/shared/SparHeading/SparHeading.vue";
import SparLoader from "~/components/shared/SparLoader/SparLoader.vue";
import { useAuthStore } from "~/stores/auth.store";

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
const { getPath } = useRoutes();

const checkAuth = async () => {
  if (authStore.isLoggedIn) {
    router.push(authStore.redirectUrl);
  } else {
    router.push(getPath("login"));
  }
};

watch(
  () => authStore.isLoggedIn,
  () => checkAuth(),
);

onMounted(() => {
  if (route.query.r && typeof route.query.r === "string") {
    authStore.setRedirectUrl(route.query.r);
  }
});
</script>

<style lang="scss">
.check-auth {
  height: 100px;
}
</style>
