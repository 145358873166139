<template>
  <div class="back-to-top__wrapper">
    <button
      class="back-to-top__button f-size--small"
      :class="showButton ? 'back-to-top__button--active' : ''"
      type="button"
      :aria-label="$t('global.back_to_top')"
      data-tosca="back-to-top-btn"
      @click="scrollTopHandler()"
    >
      <spar-icon-sprite symbol="arrow-top" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import useI18n from "~/composables/i18n/useI18n";
import { isClient, scrollTop } from "~/utils/ui";

const { $t } = useI18n(); // Explicit Import for Storybook
const showButton = ref(false);
let lastKnownScrollPosition = 0;
let ticking = false;

const scrollTopHandler = () => {
  if (isClient()) {
    scrollTop();
  }
};

if (isClient()) {
  window.document.addEventListener("scroll", () => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        ticking = false;
        if (lastKnownScrollPosition >= 100) {
          showButton.value = true;
          return;
        }

        showButton.value = false;
      });

      ticking = true;
    }
  });
}
</script>

<style lang="scss">
@use "./SparBackToTop.scss";
</style>
