import type { ExternalFetch } from "~/plugins/fetch";
import { servicesEndpoints } from "~/utils/bff/endpoints.constants";
import { getMiddlwareUrl } from "~/utils/ui";

export default class PPCIntegration {
  private readonly externalFetch: ExternalFetch;
  private readonly runtimeConfig = useRuntimeConfig();

  constructor(externalFetch: ExternalFetch) {
    this.externalFetch = externalFetch;
  }

  public async getPrices<T>(options: Record<string, unknown>) {
    return this.externalFetch<T>(
      getMiddlwareUrl(this.runtimeConfig) + servicesEndpoints.ppc.prices,
      options,
    );
  }
}
