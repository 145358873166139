import type { SapStore } from "./types";

/**
 * Prevents overlapping store markers on a map by slightly adjusting their positions.
 * Rounds coordinates based on a small `threshold` to identify stores that are very close.
 * For each group of overlapping stores:
 * - Calculates a displacement using angles with a random offset and a small `epsilon` to avoid zero offsets.
 * - Adjusts the `latitude` and `longitude` of each store slightly around the original point.
 */
export function adjustOverlappingStores(stores: SapStore[]): SapStore[] {
  const adjustedStores = [...stores];
  const threshold = 0.00001; // Threshold for proximity (approximately 1 meter)
  const radius = threshold * 10; // Increased radius for displacement

  // Map to group stores with similar coordinates
  const locationMap = new Map<string, SapStore[]>();

  // Group stores by rounded coordinates
  adjustedStores.forEach((store) => {
    const lat = store.geoPoint.latitude;
    const lng = store.geoPoint.longitude;
    // Round coordinates based on the threshold
    const latKey = Math.round(lat / threshold);
    const lngKey = Math.round(lng / threshold);
    const key = `${latKey}_${lngKey}`;

    if (!locationMap.has(key)) {
      locationMap.set(key, []);
    }
    locationMap.get(key)?.push(store);
  });

  // Adjust positions for overlapping stores
  locationMap.forEach((group) => {
    if (group.length > 1) {
      const originalLat = group[0].geoPoint.latitude;
      const originalLng = group[0].geoPoint.longitude;
      const angleIncrement = (2 * Math.PI) / group.length;
      const randomOffset = Math.random() * angleIncrement; // Random angle offset
      const epsilon = 0.0001; // Small value to avoid zero offsets

      group.forEach((store, index) => {
        const angle = angleIncrement * index + randomOffset;
        // Ensure angle is not a multiple of π/2 to avoid sin or cos being zero

        const safeAngle = angle + epsilon;

        // Calculate offset
        const offsetLat = radius * Math.cos(safeAngle);
        const offsetLng = radius * Math.sin(safeAngle);

        // Adjust coordinates
        store.geoPoint.latitude = originalLat + offsetLat;
        store.geoPoint.longitude = originalLng + offsetLng;
      });
    }
  });

  return adjustedStores;
}
