<template>
  <div
    class="spar-accordion"
    :class="{
      'spar-accordion--disabled': disabled,
      'spar-accordion--open': isOpen,
      'spar-accordion--reduced': styling,
    }"
  >
    <div class="spar-accordion__heading" role="heading" :aria-level="level">
      <spar-button
        :id="labelId"
        class="spar-accordion__trigger"
        type="button"
        :variant="ButtonVariant.custom"
        :aria-expanded="isOpen"
        :aria-controls="contentId"
        :data-tosca="getToscaPrefix(contentId, toscaPrefix)"
        @click="toggle"
      >
        <slot v-if="$slots.label" name="label" class="link__slot-content" />
        <span v-else>{{ label }}</span>
        <spar-icon-sprite
          symbol="arrow-bottom"
          class="spar-accordion__expand-icon"
        ></spar-icon-sprite>
      </spar-button>
    </div>
    <div
      :id="contentId"
      role="region"
      :aria-labelledby="labelId"
      class="spar-accordion__panel"
      :hidden="!isOpen"
    >
      <slot v-if="$slots.content" name="content" class="link__slot-content" />
      <span v-else>{{ content }}</span>
    </div>
  </div>
</template>

<script lang="ts">
const accordionGroups = shallowReactive<Record<string, string>>({});
</script>

<script lang="ts" setup>
import { ButtonVariant } from "~/components/shared/SparButton/SparButton.types";
import { getToscaPrefix } from "~/utils/ui";
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import SparButton from "~/components/shared/SparButton/SparButton.vue";
import type { SparAccordionProps } from "./SparAccordion.types";
import getId from "~/composables/getId/getId";

const props: SparAccordionProps = defineProps({
  content: {
    type: String,
    default: null,
  },
  contentId: {
    type: String,
    required: true,
  },
  styling: {
    type: String as PropType<"default" | "reduced">,
    default: "default",
  },
  defaultOpened: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  group: {
    type: String,
    required: false,
    default: undefined,
  },
  label: {
    type: String,
    default: null,
  },
  labelId: {
    type: String,
    required: true,
  },
  level: {
    type: Number,
    required: true,
    validator: (v) => Number.isInteger(v),
  },
  toscaPrefix: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["open", "close"]);

defineExpose({
  show,
  hide,
  toggle,
});

const isOpen = ref(props.defaultOpened);

const leaveGroup = ref<(() => void) | null>(null);
const uniqueId = getId("accordion");

function show() {
  if (props.disabled) return;

  if (props.group) {
    accordionGroups[props.group] = uniqueId;
  }

  emit("open");
  isOpen.value = true;
}

function hide() {
  if (props.disabled) return;

  // If update came not from model, update model
  emit("close");
  isOpen.value = false;
}

function toggle() {
  if (isOpen.value) {
    hide();
  } else {
    show();
  }
}

watch(
  () => props.group,
  (group) => {
    leaveGroup.value && leaveGroup.value();
    group && joinGroup(group);
  },
  { immediate: true },
);

function joinGroup(group: string) {
  if (isOpen.value) {
    accordionGroups[`${group}`] = uniqueId;
  }

  // Watch group state
  const stopGroupWatch = watch(
    () => accordionGroups[`${group}`],
    (newGroup, oldGroup) => {
      if (newGroup && oldGroup === uniqueId && newGroup !== uniqueId) {
        hide();
      }
    },
  );

  // Register leaveGroup function
  leaveGroup.value = () => {
    stopGroupWatch();

    if (accordionGroups[`${group}`] === uniqueId) {
      delete accordionGroups[`${group}`];
    }

    leaveGroup.value = null;
  };
}

onUnmounted(() => {
  leaveGroup.value && leaveGroup.value();
});
</script>

<style lang="scss">
@use "./SparAccordion.scss";
</style>
